import React, { useEffect, useState } from "react";
import {
  AppBar,
  Avatar,
  IconButton,
  Stack,
  Toolbar,
  Typography,
  Popover,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "@mui/system";
import "./style.css";
import { themeRed } from "../../utils/colorTheme";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButton from "../common/Button/Button";
// import HamBurgerImage from "../../assets/Nav-logo/ham-burger.png"
import NotificationsIcon from "@mui/icons-material/Notifications";
import Tickets from "./../../screens/Dashboard/Tickets";
import { exit_session } from "../../config/apiHandle/apiHandle";
import { IoIosSwitch } from "react-icons/io";
import CustomModal from "../common/CustomModal/CustomModal";
import { RxCross2 } from "react-icons/rx";
import { toggleUserType } from "../../store/slices/userTypeSlice";

export const UserTopAppBar = ({ onClickHanlde, setIsActiveView }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);

  // Get the current path
  const currentPath = location.pathname;

  // Extract the screen name from the path
  const screenName =
    currentPath.split("/").filter(Boolean).pop() || "Dashboard";

  const { topbarHide } = useSelector((state) => state.layout_controler);
  const { is_edit_trailer } = useSelector(
    (state) => state.trailersDetailsManage
  );
  const { user, role } = useSelector((state) => state.userAuth);
  // Notification Handling
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "notification-popover" : undefined;

  const isRoleMatch = role === "User" ? true : false;

  const switchMessage = !isRoleMatch
    ? "Please log out of your host profile to switch to your user account."
    : "Please log out of your user profile to switch to your host account.";

  const _handleLogout = async () => {
    exit_session();
    dispatch(toggleUserType());
    navigate("/login");
  };

  return (
    <Stack zIndex={1001}>
      <AppBar
        elevation={0}
        className={`topBarStyle ${topbarHide && "hideTopBar"}`}
        sx={{
          backgroundColor: "white",
          width: "100%",
          height: 80,
          boxShadow: "0px 3px 5px -5px rgba(0, 0, 0, 0.75)",
        }}
        position="static"
      >
        <Toolbar sx={{ px: { xl: 5, lg: 5, md: 5, sm: 2, xs: 1 } }}>
          <Stack
            className="topBarContent"
            alignItems="center"
            sx={{ width: "100%" }}
            direction="row"
            justifyContent="space-between"
          >
            {setIsActiveView && (
              <IconButton
                onClick={onClickHanlde}
                size="large"
                edge="start"
                sx={{ color: themeRed, mt: { xs: 1 } }}
                aria-label="menu"
              >
                <MenuIcon
                  sx={{
                    display: {
                      xl: "none",
                      lg: "none",
                      md: "none",
                      sm: "flex",
                      xs: "flex",
                    },
                  }}
                />
              </IconButton>
            )}

            <Container disableGutters maxWidth={false}>
              <Stack
                sx={{ marginTop: "5px" }}
                direction="row"
                justifyContent="space-between"
                spacing={5}
                alignItems="center"
              >
                <Stack>
                  <div>
                    {screenName === "settings" ? (
                      <div className="headerName">
                        Welcome{" "}
                        <span className="headerNameThemeColor">
                          {user?.first_name}!
                        </span>{" "}
                      </div>
                    ) : screenName === "Dashboard" ? (
                      <div className="headerName">
                        {" "}
                        Dash<span className="headerNameThemeColor">
                          board
                        </span>{" "}
                      </div>
                    ) : screenName === "all-trailers" ? (
                      <div className="headerName">
                        All{" "}
                        <span className="headerNameThemeColor">Trailers</span>{" "}
                      </div>
                    ) : screenName === "message" ? (
                      <span className="headerNameThemeColor">Message</span>
                    ) : screenName === "my-booking" ? (
                      <div className="headerName">
                        My{" "}
                        <span className="headerNameThemeColor">Bookings</span>{" "}
                      </div>
                    ) : screenName === "my-bank-account" ? (
                      <div className="headerName">
                        Your Bank{" "}
                        <span className="headerNameThemeColor">Accounts</span>{" "}
                      </div>
                    ) : screenName === "my-revenue" ? (
                      <div className="headerName">
                        Total{" "}
                        <span className="headerNameThemeColor">Revenue</span>{" "}
                      </div>
                    ) : screenName === "tickets" ? (
                      <div className="headerName">
                        <span className="headerNameThemeColor">Tickets</span>{" "}
                      </div>
                    ) : screenName === "trailer-details" ? (
                      <div className="headerName">
                        Trailer{" "}
                        <span className="headerNameThemeColor">Details</span>{" "}
                      </div>
                    ) : screenName === "add-account" ? (
                      <div className="headerName">
                        Add{" "}
                        <span className="headerNameThemeColor">Account</span>{" "}
                      </div>
                    ) : screenName === "view-booking-details" ? (
                      <div className="headerName">
                        Booking{" "}
                        <span className="headerNameThemeColor">Details</span>{" "}
                      </div>
                    ) : screenName === "trailer-add" ? (
                      <div className="headerName">
                        {is_edit_trailer ? "Edit My" : "Add New"}{" "}
                        <span className="headerNameThemeColor">Trailer</span>{" "}
                      </div>
                    ) : screenName === "my-booking-user" ? (
                      <div className="headerName">
                        My{" "}
                        <span className="headerNameThemeColor">Bookings</span>{" "}
                      </div>
                    ) : screenName === "history" ? (
                      <div className="headerName">History</div>
                    ) : screenName === "referral" ? (
                      <div className="headerName">
                        <span className="headerNameThemeColor">Referral</span>{" "}
                      </div>
                    ) : screenName === "kyc-verification" ? (
                      <div className="headerName">
                        kyc{" "}
                        <span className="headerNameThemeColor">
                          verification
                        </span>{" "}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Stack>
                <Stack
                  flexDirection={"row"}
                  alignItems={"center"}
                  gap={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
                >
                  <Stack>
                    {screenName === "my-bank-account" ? (
                      <CustomButton
                        onClick={() => navigate("/add-account")}
                        children={"Add New Account"}
                      />
                    ) : (
                      role !== "User" && (
                        <CustomButton
                          onClick={() => navigate("/trailer-add")}
                          children={"Add New Trailer"}
                        />
                      )
                    )}
                  </Stack>
                  <Stack>
                    <CustomButton
                      onClick={() => setOpenModal(true)}
                      children={
                        isRoleMatch ? "Switch to Host" : "Switch to User"
                      }
                    />
                  </Stack>

                  <Stack
                    display={{
                      xl: "flex",
                      lg: "flex",
                      md: "flex",
                      sm: "flex",
                      xs: "none",
                    }}
                  >
                    <IconButton onClick={handleClick}>
                      <NotificationsIcon style={{ fontSize: 30 }} />
                    </IconButton>
                  </Stack>

                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <Stack p={2} width={300}>
                      <Typography variant="h6" gutterBottom>
                        Notifications
                      </Typography>
                      <Typography variant="body2">
                        No new notifications
                      </Typography>
                      {/* Example notification items */}
                    </Stack>
                  </Popover>

                  <Stack flexDirection={"row"} alignItems={"center"} gap={0.5}>
                    <Stack>
                      {/* <Avatar
                                                alt="Remy Sharp"
                                                src={require("../../assets/background/profile-image-nav.png")}
                                                sx={{ width: 55, height: 55 }}
                                            /> */}
                      <Avatar
                        sx={{ width: 55, height: 55 }}
                        src={`${user?.avatar}`}
                      >
                        {user?.first_name?.slice(0, 1)}{" "}
                        {user?.last_name?.slice(0, 1)}
                      </Avatar>
                    </Stack>
                    <Stack
                      flexDirection={"column"}
                      alignItems={"flex-start"}
                      gap={0.2}
                      display={{
                        xl: "flex",
                        lg: "flex",
                        md: "flex",
                        sm: "none",
                        xs: "none",
                      }}
                    >
                      <Stack className="user-name-in-hadder">{`${user?.first_name}  ${user?.last_name}`}</Stack>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Container>
          </Stack>
        </Toolbar>
      </AppBar>

      <CustomModal
        isOpen={openModal}
        setIsOpen={() => setOpenModal(!openModal)}
      >
        <Stack
          sx={{
            overflowY: "auto",
            backgroundColor: "white",
            borderRadius: "12px",
            maxWidth: 500,
            width: "90%",
            mx: "auto",
            my: 2,
            p: { xs: 2, sm: 3 },
          }}
          spacing={2}
        >
          {/* Close Button */}
          <Stack direction="row" justifyContent="flex-end">
            <IconButton
              onClick={() => setOpenModal(false)}
              aria-label="Close modal"
              sx={{ color: "black" }}
            >
              <RxCross2 size={22} />
            </IconButton>
          </Stack>

          {/* Modal Content */}
          <Stack spacing={2} alignItems="center" textAlign="center">
            <Typography
              sx={{
                color: "black",
                fontSize: { xs: "16px", sm: "18px" },
                fontWeight: 600,
              }}
            >
              Switch Account
            </Typography>
            <Typography
              sx={{
                color: "gray",
                fontSize: { xs: "14px", sm: "16px" },
                lineHeight: "22px",
              }}
            >
              {switchMessage}
            </Typography>
          </Stack>

          {/* Buttons */}
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
            justifyContent="flex-end"
            alignItems="center"
            mt={2}
          >
            <CustomButton
              onClick={_handleLogout}
              children="Logout & Switch"
              // style={{
              //   width: "100%",
              //   maxWidth: "180px",
              //   borderRadius: "15px",
              //   // backgroundColor: "#ff4d4f",
              //   color: "black",
              //   fontWeight: 500,
              // }}
            />
            <CustomButton
              onClick={() => setOpenModal(false)}
              children="Cancel"
              // style={{
              //   width: "100%",
              //   maxWidth: "180px",
              //   borderRadius: "15px",
              //   // backgroundColor: "#f0f0f0",
              //   color: "black",
              //   fontWeight: 500,
              // }}
            />
          </Stack>
        </Stack>
      </CustomModal>
    </Stack>
  );
};
