import React, { Suspense, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Card,
  Button,
  Stack,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Modal,
  TextField,
  Slider,
  createTheme,
  ThemeProvider,
  Skeleton,
  Chip,
} from "@mui/material";
import Navbar from "../../component/navbar/Navbar";
import { themeRed } from "../../utils/colorTheme";
import {
  getSearchTrailersListAsync,
  getUserTrailersListAsync,
} from "../../services/trailers_details_service";
import { useDispatch, useSelector } from "react-redux";
import { IoStar } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import LocationSearch from "../../component/LocationSearch/LocationSearch";
import CustomButton from "../../component/common/Button/Button";
import MapRender from "../../component/Map/MapRender";
import { IoFilter } from "react-icons/io5";
import { asyncStatus } from "../../utils/asyncStatus";
import LocationSearchStates from "../../component/LocationSearchStates/LocationSearchStates";
import { trailerCategories } from "../../pages/HomeSections/CategorieSection/CategoriesSection";
import { HiCheckBadge } from "react-icons/hi2";
import CustomLocationSearch from "../../component/IconInput/customInput";

// const trailers = [
//   {
//     title: "Car Hauler Trailer",
//     location: "Los Angeles",
//     price: "$43/day",
//     totalPrice: "$129 est. total",
//     miles: "Unlimited miles",
//     newListing: true,
//     coordinates: { lat: 34.0522, lng: -118.2437 }, // Los Angeles
//     img: "https://res.cloudinary.com/dpfni06vw/image/upload/v1726093612/Rectangle_53_1_r9tp3a.png",
//   },
//   {
//     title: "Utility Trailer",
//     location: "Pasadena - 10.0 mi",
//     price: "$52/day",
//     totalPrice: "$157 est. total",
//     discount: "Save $30",
//     coordinates: { lat: 34.1478, lng: -118.1445 }, // Pasadena
//     img: "https://res.cloudinary.com/dpfni06vw/image/upload/v1726093612/Rectangle_53_1_r9tp3a.png",
//   },
//   {
//     title: "Utility Trailer",
//     location: "Long Beach - 24.0 mi",
//     price: "$54/day",
//     totalPrice: "$168 est. total",
//     discount: "Save $29",
//     coordinates: { lat: 33.7701, lng: -118.1937 }, // Long Beach
//     img: "https://res.cloudinary.com/dpfni06vw/image/upload/v1726093612/Rectangle_53_1_r9tp3a.png",
//   },
//   {
//     title: "Enclosed Trailer",
//     location: "Santa Monica - 14.0 mi",
//     price: "$67/day",
//     totalPrice: "$200 est. total",
//     discount: "Save $11",
//     coordinates: { lat: 34.0195, lng: -118.4912 }, // Santa Monica
//     img: "https://res.cloudinary.com/dpfni06vw/image/upload/v1726093612/Rectangle_53_1_r9tp3a.png",
//   },
//   {
//     title: "Enclosed Trailer",
//     location: "Anaheim - 26.0 mi",
//     price: "$67/day",
//     totalPrice: "$200 est. total",
//     discount: "Save $11",
//     coordinates: { lat: 33.8366, lng: -117.9143 }, // Anaheim
//     img: "https://res.cloudinary.com/dpfni06vw/image/upload/v1726093612/Rectangle_53_1_r9tp3a.png",
//   },
//   {
//     title: "Enclosed Trailer",
//     location: "Burbank - 11.0 mi",
//     price: "$67/day",
//     totalPrice: "$200 est. total",
//     discount: "Save $11",
//     coordinates: { lat: 34.1808, lng: -118.3089 }, // Burbank
//     img: "https://res.cloudinary.com/dpfni06vw/image/upload/v1726093612/Rectangle_53_1_r9tp3a.png",
//   },
//   {
//     title: "Enclosed Trailer",
//     location: "San Bernardino - 50.0 mi",
//     price: "$67/day",
//     totalPrice: "$200 est. total",
//     discount: "Save $11",
//     coordinates: { lat: 34.1083, lng: -117.2898 }, // San Bernardino
//     img: "https://res.cloudinary.com/dpfni06vw/image/upload/v1726093612/Rectangle_53_1_r9tp3a.png",
//   },
//   {
//     title: "Enclosed Trailer",
//     location: "Lancaster - 70.0 mi",
//     price: "$67/day",
//     totalPrice: "$200 est. total",
//     discount: "Save $11",
//     coordinates: { lat: 34.6868, lng: -118.1542 }, // Lancaster
//     img: "https://res.cloudinary.com/dpfni06vw/image/upload/v1726093612/Rectangle_53_1_r9tp3a.png",
//   },
// ];

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: "#C3282F",
            },
          },
          "& .MuiInputLabel-root": {
            "&.Mui-focused": {
              color: "#C3282F",
            },
          },
        },
      },
    },
  },
});

const TrailerCardSkeleton = () => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{ p: 2, borderRadius: 2, boxShadow: 2, mb: 2, mt: 1 }}
    >
      {/* Image Skeleton */}
      <Skeleton variant="rounded" width={100} height={100} sx={{ mr: 2 }} />

      {/* Text Section */}
      <Box flexGrow={1}>
        <Skeleton variant="text" width="60%" height={25} sx={{ mb: 1 }} />
        <Skeleton variant="text" width="40%" height={20} sx={{ mb: 1 }} />
        <Skeleton variant="text" width="30%" height={20} />
      </Box>

      {/* Price Skeleton */}
      <Skeleton variant="rectangular" width={60} height={25} sx={{ ml: 2 }} />
    </Stack>
  );
};

// const TrailerCard = ({ trailer, onClick }) => (
//   <Card
//     sx={{
//       display: "flex",
//       mb: 2,
//       border: "1px solid lightgray",
//       borderRadius: "15px",
//       overflow: "hidden",
//       height: "200px",
//       boxShadow: "none",
//       cursor: "pointer",
//     }}
//     onClick={onClick}
//   >
//     <div style={{ height: "100%", width: "350px" }}>
//       <img
//         src={trailer?.trailer_photos[0]}
//         alt={trailer?.title || ""}
//         style={{ width: "100%", height: "100%", objectFit: "cover" }}
//       />
//     </div>

//     <Grid container p={2}>
//       <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
//         <Stack sx={{ height: "100%" }} gap={1}>
//           <Typography
//             sx={{
//               color: "#000",
//               fontSize: {
//                 xl: "24px",
//                 lg: "24px",
//                 md: "24px",
//                 sm: "18px",
//                 xs: "18px",
//               },
//               fontWeight: "700",
//               lineHeight: "normal",
//             }}
//           >
//             {trailer?.title || ""}
//           </Typography>

//           <Stack direction={"row"} alignItems={"center"} gap={1}>
//             <Typography
//               sx={{
//                 color: "#000",
//                 fontSize: {
//                   xl: "12px",
//                   lg: "12px",
//                   md: "12px",
//                   sm: "10px",
//                   xs: "10px",
//                 },
//                 fontWeight: "400",
//                 lineHeight: "normal",
//               }}
//             >
//               {" "}
//               5.0{" "}
//             </Typography>
//             <IoStar style={{ color: themeRed, fontSize: "14px" }} />
//             <Typography
//               sx={{
//                 color: "#000",
//                 fontSize: {
//                   xl: "12px",
//                   lg: "12px",
//                   md: "12px",
//                   sm: "10px",
//                   xs: "10px",
//                 },
//                 fontWeight: "400",
//                 lineHeight: "normal",
//               }}
//             >
//               {" "}
//               (24 trips){" "}
//             </Typography>
//           </Stack>
//           <Typography
//             variant="body2"
//             sx={{
//               color: "#000",
//               fontSize: {
//                 xl: "12px",
//                 lg: "12px",
//                 md: "12px",
//                 sm: "10px",
//                 xs: "10px",
//               },
//               fontWeight: "400",
//               lineHeight: "normal",
//             }}
//           >
//             {trailer?.complete_address}
//           </Typography>
//         </Stack>
//       </Grid>
//       <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
//         <Stack
//           alignItems={"flex-end"}
//           justifyContent={"space-between"}
//           sx={{ height: "100%" }}
//           gap={1}
//         >
//           {trailer?.host_id?.kyc === "Completed" &&
//             trailer?.host_id?.status === "Approved" && (
//               <Stack
//                 direction={"row"}
//                 alignItems={"center"}
//                 justifyContent={"center"}
//                 gap={1}
//                 sx={{
//                   p: "4px 8px",
//                   borderRadius: "10px",
//                   backgroundColor: "greenyellow",
//                   width: "fit-content",
//                 }}
//               >
//                 <Typography
//                   variant="caption"
//                   sx={{
//                     color: "green",
//                     fontSize: "15px",
//                   }}
//                 >
//                   Verified
//                 </Typography>
//                 <HiCheckBadge style={{ color: "green" }} />
//               </Stack>
//             )}
//           <Stack
//             direction={"row"}
//             alignItems={"flex-end"}
//             justifyContent={"flex-end"}
//             sx={{ height: "100%" }}
//             gap={2}
//           >
//             <Typography
//               variant="h6"
//               sx={{
//                 color: "#BABABA",
//                 fontSize: {
//                   xl: "16px",
//                   lg: "16px",
//                   md: "16px",
//                   sm: "12px",
//                   xs: "12px",
//                 },
//                 fontWeight: "700",
//                 lineHeight: "normal",
//                 textDecorationLine: "line-through",
//               }}
//             >
//               {/* $65 */}
//             </Typography>
//             <Typography
//               variant="body2"
//               sx={{
//                 color: "#000",
//                 fontSize: {
//                   xl: "16px",
//                   lg: "16px",
//                   md: "16px",
//                   sm: "12px",
//                   xs: "12px",
//                 },
//                 fontWeight: "700",
//                 lineHeight: "normal",
//               }}
//             >
//               {/* ${trailer?.daily_rate?.toLocaleString() || 0}/day */}$
//               {trailer?.daily_rate?.toLocaleString() || 0}/day
//             </Typography>
//           </Stack>

//           <Typography
//             variant="body2"
//             sx={{
//               color: "#000",
//               fontSize: {
//                 xl: "12px",
//                 lg: "12px",
//                 md: "12px",
//                 sm: "10px",
//                 xs: "10px",
//               },
//               fontWeight: "400",
//               lineHeight: "normal",
//               textDecorationLine: "underline",
//             }}
//           >
//             {/* $157 est. total */}
//             {trailer?.totalPrice}
//           </Typography>
//         </Stack>
//       </Grid>
//     </Grid>
//   </Card>
// );

const TrailerCard = ({ trailer, onClick }) => (
  <Card
    sx={{
      display: "flex",
      flexDirection: { xs: "column", sm: "row" }, // Add this
      mb: 3,
      border: "1px solid #e0e0e0",
      borderRadius: "16px",
      overflow: "hidden",
      height: { xs: "auto", sm: 220 },
      boxShadow: "0 4px 20px rgba(0, 0, 0, 0.08)",
      cursor: "pointer",
      transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
      "&:hover": {
        transform: "translateY(-4px)",
        boxShadow: "0 8px 24px rgba(0, 0, 0, 0.12)",
      },
    }}
    onClick={onClick}
  >
    <Box
      sx={{
        width: { xs: "100%", sm: 280 },
        height: { xs: 180, sm: "100%" },
        flexShrink: 0,
        position: "relative",
      }}
    >
      <img
        src={trailer?.trailer_photos[0] || "/placeholder-trailer.jpg"}
        alt={trailer?.title || "Trailer image"}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          objectPosition: "center",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: 12,
          left: 12,
          display: "flex",
          alignItems: "center",
          gap: 1,
          backgroundColor: "rgba(255, 255, 255, 0.9)",
          px: 1.5,
          py: 0.5,
          borderRadius: "8px",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <IoStar style={{ color: themeRed, fontSize: "16px" }} />
        <Typography
          variant="body2"
          sx={{
            fontWeight: 600,
            color: "text.primary",
          }}
        >
          5.0 <span style={{ fontWeight: 400, color: "#666" }}>(24 trips)</span>
        </Typography>
      </Box>
    </Box>

    <Box
      sx={{
        flex: 1,
        p: 2.5,
        width: "100%", // Add this
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 700,
            mb: 1,
            fontSize: { xs: "1.1rem", sm: "1.25rem" },
            lineHeight: 1.2,
            color: "text.primary",
          }}
        >
          {trailer?.title}
        </Typography>

        <Typography
          variant="body2"
          sx={{
            mb: 1.5,
            fontSize: "0.875rem",
            color: "text.secondary",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
          }}
        >
          {trailer?.complete_address}
        </Typography>

        {trailer?.host_id?.kyc === "Completed" &&
          trailer?.host_id?.status === "Approved" && (
            <Chip
              label="Verified"
              icon={
                <HiCheckBadge style={{ color: "green", fontSize: "18px" }} />
              }
              sx={{
                backgroundColor: "greenyellow",
                color: "green",
                fontWeight: 500,
                "& .MuiChip-icon": { ml: 0.5 },
              }}
            />
          )}
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "space-between",
          mt: 2,
        }}
      >
        <Box>
          {/* <Typography variant="caption" sx={{
            display: 'block',
            color: 'text.secondary',
            fontSize: '0.75rem',
            mb: 0.5
          }}>
            Starting from
          </Typography> */}
          <Box sx={{ display: "flex", alignItems: "baseline", gap: 1 }}>
            <Typography
              variant="h6"
              sx={{
                color: "error.main",
                fontWeight: 700,
                fontSize: { xs: "1.1rem", sm: "1.25rem" },
              }}
            >
              ${trailer?.daily_rate?.toLocaleString() || 0}
              <span
                style={{
                  fontSize: "0.875rem",
                  fontWeight: 500,
                  color: "#666",
                  marginLeft: "4px",
                }}
              >
                /day
              </span>
            </Typography>
          </Box>
        </Box>

        {/* <Typography variant="caption" sx={{
          color: 'text.secondary',
          fontSize: '0.75rem',
          textAlign: 'right'
        }}>
          Est. total
          <Typography component="div" variant="body2" sx={{
            color: 'text.primary',
            fontWeight: 500,
            fontSize: '0.875rem'
          }}>
            ${trailer?.totalPrice?.toLocaleString() || '0'}
          </Typography>
        </Typography> */}
      </Box>
    </Box>
  </Card>
);

// Trailer Filter Modal Component
// const TrailerFilters = ({ applyFilters }) => {
//   const [priceRange, setPriceRange] = useState([1, 350]);
//   const [lengthRange, setLengthRange] = useState([3, 53]);
//   const [weightCapacity, setWeightCapacity] = useState([1000, 20000]);
//   const [hitchType, setHitchType] = useState("");

//   const handleApply = () => {
//     applyFilters({ priceRange, lengthRange, weightCapacity, hitchType });
//   };

//   return (
//     <ThemeProvider theme={theme}>
//       <Typography style={{ marginTop: "20px", fontWeight: 'bold' }}>Price Range (Per Day)</Typography>
//       <Slider
//         color="error"
//         value={priceRange}
//         onChange={(e, newValue) => setPriceRange(newValue)}
//         valueLabelDisplay="auto"
//         min={1}
//         max={350}
//       />
//       <Box display="flex" justifyContent="space-between">
//         <TextField value={priceRange[0]} size="small" />
//         <TextField value={priceRange[1]} size="small" />
//       </Box>

//       <Typography style={{ marginTop: "20px", fontWeight: 'bold' }}>Trailer Length</Typography>
//       <Slider
//         color="error"
//         value={lengthRange}
//         onChange={(e, newValue) => setLengthRange(newValue)}
//         valueLabelDisplay="auto"
//         min={3}
//         max={53}
//       />
//       <Box display="flex" justifyContent="space-between">
//         <TextField value={lengthRange[0]} size="small" />
//         <TextField value={lengthRange[1]} size="small" />
//       </Box>

//       <Typography style={{ marginTop: "20px", fontWeight: 'bold' }}>Weight Capacity</Typography>
//       <Slider
//         color="error"
//         value={weightCapacity}
//         onChange={(e, newValue) => setWeightCapacity(newValue)}
//         valueLabelDisplay="auto"
//         min={1000}
//         max={20000}
//       />
//       <Box display="flex" justifyContent="space-between">
//         <TextField value={weightCapacity[0]} size="small" />
//         <TextField value={weightCapacity[1]} size="small" />
//       </Box>

//       <Typography style={{ marginTop: "20px", fontWeight: 'bold', marginBottom: '5px' }}>Hitch Type</Typography>
//       <FormControl fullWidth>
//         <Select
//           value={hitchType}
//           onChange={(e) => setHitchType(e.target.value)}
//           sx={{
//             backgroundColor: 'white',
//             '& .MuiOutlinedInput-notchedOutline': {
//               border: 'none',
//               borderRadius: 2,
//               boxShadow: 1,
//             },
//             '& .MuiSelect-select': {
//               borderRadius: 2,
//               boxShadow: 1,
//             },
//             '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
//               border: 'none',
//             }
//           }}
//         >
//           <MenuItem value="">-- Hitch Type --</MenuItem>
//           <MenuItem value="Bumper Pull">Bumper Pull</MenuItem>
//           <MenuItem value="Gooseneck">Gooseneck</MenuItem>
//           <MenuItem value="Fifth Wheel">Fifth Wheel</MenuItem>
//           <MenuItem value="Pintle">Pintle</MenuItem>
//         </Select>
//       </FormControl>

//       <Stack direction="row" spacing={2} justifyContent="flex-end" mt={2}>
//         <Button variant="outlined" color="error" onClick={() => applyFilters(null)}>Reset</Button>
//         <Button variant="contained" onClick={handleApply} color="error" >Apply Filters</Button>
//       </Stack>
//     </ThemeProvider>
//   );
// };

// const TrailerFilters = ({ applyFilters, resetFilter }) => {
//   const [priceRange, setPriceRange] = useState([1, 350]);
//   const [lengthRange, setLengthRange] = useState([3, 53]);
//   const [weightCapacity, setWeightCapacity] = useState([1000, 20000]);
//   const [hitchType, setHitchType] = useState("");

//   const handleApply = () => {
//     applyFilters({
//       priceRange,
//       lengthRange,
//       weightCapacity,
//       hitchType,
//     });
//   };

//   return (
//     <>
//       <Typography style={{ marginTop: "20px", fontWeight: "bold" }}>
//         Price Range (Per Day)
//       </Typography>
//       <Slider
//         color="error"
//         value={priceRange}
//         onChange={(e, newValue) => setPriceRange(newValue)}
//         valueLabelDisplay="auto"
//         min={1}
//         max={350}
//       />
//       <Stack direction={"row"} gap={2} justifyContent="space-between">
//         <TextField value={priceRange[0]} size="small" />
//         <TextField value={priceRange[1]} size="small" />
//       </Stack>

//       <Typography style={{ marginTop: "20px", fontWeight: "bold" }}>
//         Trailer Length
//       </Typography>
//       <Slider
//         color="error"
//         value={lengthRange}
//         onChange={(e, newValue) => setLengthRange(newValue)}
//         valueLabelDisplay="auto"
//         min={3}
//         max={53}
//       />
//       <Stack direction={"row"} gap={2} justifyContent="space-between">
//         <TextField value={lengthRange[0]} size="small" />
//         <TextField value={lengthRange[1]} size="small" />
//       </Stack>

//       <Typography style={{ marginTop: "20px", fontWeight: "bold" }}>
//         Payload Capacity
//       </Typography>
//       <Slider
//         color="error"
//         value={weightCapacity}
//         onChange={(e, newValue) => setWeightCapacity(newValue)}
//         valueLabelDisplay="auto"
//         min={1000}
//         max={20000}
//       />
//       <Stack direction={"row"} gap={2} justifyContent="space-between">
//         <TextField value={weightCapacity[0]} size="small" />
//         <TextField value={weightCapacity[1]} size="small" />
//       </Stack>

//       <Typography
//         style={{ marginTop: "20px", fontWeight: "bold", marginBottom: "5px" }}
//       >
//         Hitch Type
//       </Typography>
//       <FormControl fullWidth>
//         <Select
//           value={hitchType}
//           onChange={(e) => setHitchType(e.target.value)}
//           sx={{
//             backgroundColor: "white",
//             "& .MuiOutlinedInput-notchedOutline": {
//               border: "none",
//               borderRadius: 2,
//               boxShadow: 1,
//             },
//           }}
//         >
//           <MenuItem value="">-- Hitch Type --</MenuItem>
//           <MenuItem value="Bumper Pull">Bumper Pull</MenuItem>
//           <MenuItem value="Gooseneck">Gooseneck</MenuItem>
//           <MenuItem value="Fifth Wheel">Fifth Wheel</MenuItem>
//           <MenuItem value="Pintle">Pintle</MenuItem>
//         </Select>
//       </FormControl>

//       <Stack direction="row" spacing={2} justifyContent="flex-end" mt={2}>
//         <Button variant="outlined" color="error" onClick={resetFilter}>
//           Reset
//         </Button>
//         <Button variant="contained" onClick={handleApply} color="error">
//           Apply Filters
//         </Button>
//       </Stack>
//     </>
//   );
// };
const TrailerFilters = ({ applyFilters, resetFilter }) => {
  const [priceRange, setPriceRange] = useState([1, 350]);
  const [lengthRange, setLengthRange] = useState([3, 53]);
  const [weightCapacity, setWeightCapacity] = useState([1000, 20000]);
  const [hitchType, setHitchType] = useState("");

  // Unified reset handler
  const handleReset = () => {
    // Reset local state
    setPriceRange([1, 350]);
    setLengthRange([3, 53]);
    setWeightCapacity([1000, 20000]);
    setHitchType("");

    // Propagate reset to parent component
    resetFilter();
  };

  const handleApply = () => {
    applyFilters({
      priceRange,
      lengthRange,
      weightCapacity,
      hitchType,
    });
  };

  // Enhanced Range Input Component
  const RangeInputGroup = ({ value, min, max, unit, onChange }) => (
    <Stack direction="row" gap={2} alignItems="center" mt={1}>
      <TextField
        value={value[0]}
        size="small"
        onChange={(e) => {
          const newVal = Math.max(
            min,
            Math.min(value[1], Number(e.target.value))
          );
          onChange([newVal, value[1]]);
        }}
        sx={{ flex: 1 }}
        inputProps={{ type: "number" }}
      />
      <Typography variant="body2" color="text.secondary">
        to
      </Typography>
      <TextField
        value={value[1]}
        size="small"
        onChange={(e) => {
          const newVal = Math.min(
            max,
            Math.max(value[0], Number(e.target.value))
          );
          onChange([value[0], newVal]);
        }}
        sx={{ flex: 1 }}
        inputProps={{ type: "number" }}
      />
      {unit && (
        <Typography variant="body2" color="text.secondary">
          {unit}
        </Typography>
      )}
    </Stack>
  );

  return (
    <Box sx={{ "& > * + *": { mt: 3 } }}>
      {/* Price Range Section */}
      <div>
        <Typography variant="subtitle1" fontWeight={600}>
          Price Range (Per Day)
        </Typography>
        <Slider
          value={priceRange}
          onChange={(_, newValue) => setPriceRange(newValue)}
          valueLabelDisplay="auto"
          valueLabelFormat={(v) => `$${v}`}
          min={1}
          max={350}
          color="error"
        />
        <RangeInputGroup
          value={priceRange}
          min={1}
          max={350}
          unit="$"
          onChange={setPriceRange}
        />
      </div>

      {/* Trailer Length Section */}
      <div>
        <Typography variant="subtitle1" fontWeight={600}>
          Trailer Length (ft)
        </Typography>
        <Slider
          value={lengthRange}
          onChange={(_, newValue) => setLengthRange(newValue)}
          valueLabelDisplay="auto"
          valueLabelFormat={(v) => `${v}ft`}
          min={3}
          max={53}
          color="error"
        />
        <RangeInputGroup
          value={lengthRange}
          min={3}
          max={53}
          unit="ft"
          onChange={setLengthRange}
        />
      </div>

      {/* Payload Capacity Section */}
      <div>
        <Typography variant="subtitle1" fontWeight={600}>
          Payload Capacity (lbs)
        </Typography>
        <Slider
          value={weightCapacity}
          onChange={(_, newValue) => setWeightCapacity(newValue)}
          valueLabelDisplay="auto"
          valueLabelFormat={(v) => `${Math.round(v / 1000)}k`}
          min={1000}
          max={20000}
          color="error"
        />
        <RangeInputGroup
          value={weightCapacity}
          min={1000}
          max={20000}
          unit="lbs"
          onChange={setWeightCapacity}
        />
      </div>

      {/* Hitch Type Section */}
      <div>
        <Typography variant="subtitle1" fontWeight={600}>
          Hitch Type
        </Typography>
        <FormControl fullWidth>
          <Select
            value={hitchType}
            onChange={(e) => setHitchType(e.target.value)}
            displayEmpty
            inputProps={{ "aria-label": "Select hitch type" }}
            sx={{ mt: 1 }}
          >
            <MenuItem value="">
              <em>All Hitch Types</em>
            </MenuItem>
            <MenuItem value="Bumper Pull">Bumper Pull</MenuItem>
            <MenuItem value="Gooseneck">Gooseneck</MenuItem>
            <MenuItem value="Fifth Wheel">Fifth Wheel</MenuItem>
            <MenuItem value="Pintle">Pintle</MenuItem>
          </Select>
        </FormControl>
      </div>

      {/* Action Buttons */}
      <Stack direction="row" spacing={2} justifyContent="flex-end" mt={4}>
        <Button
          variant="outlined"
          color="error"
          onClick={handleReset}
          sx={{ px: 4, borderRadius: 2 }}
        >
          Reset All
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={handleApply}
          sx={{ px: 4, borderRadius: 2 }}
        >
          Apply Filters
        </Button>
      </Stack>
    </Box>
  );
};

const TrailerList = ({
  name,
  trailers,
  numberOfTrailors,
  get_search_trailers_list_status,
  selectedLocation,
  selectedState,
  coordinates,
}) => {
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState(name || "");
  const [location, setLocation] = useState(selectedLocation || "");
  const [state, setState] = useState(selectedState || ""); // Add state state
  const [selectedCoordinates, setSelectedCoordinates] = useState(
    coordinates || {}
  ); // Add state state
  const [open, setOpen] = useState(false);

  const [filters, setFilters] = useState({
    minPrice: "",
    maxPrice: "",
    minLength: "",
    maxLength: "",
    minWeight: "",
    maxWeight: "",
    hitchType: "",
  });
  // const [location, setLocation] = useState('');

  const dispatch = useDispatch();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // const applyFilters = (newFilters) => {
  //   handleClose();
  // };

  useEffect(() => {
    // Update location and state if props change
    if (selectedLocation) setLocation(selectedLocation);
    if (selectedState) setState(selectedState);
    if (coordinates) setSelectedCoordinates(coordinates);
  }, [selectedLocation, selectedState]);

  // Clean parameters utility
  const cleanParams = (params) => {
    const cleaned = {};
    Object.entries(params).forEach(([key, value]) => {
      if (value !== undefined && value !== null && value !== "") {
        cleaned[key] = value;
      }
    });
    return cleaned;
  };

  const handleLocationSelect = (selectedLocation) => {
    setLocation(selectedLocation);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleSearch = () => {
    dispatch(
      getSearchTrailersListAsync(
        cleanParams({
          category: selectedCategory,
          ...(location && { address: location }),
          ...(state && { state: state }),
          ...(selectedCoordinates && { lat: selectedCoordinates.lat }),
          ...(selectedCoordinates && { lng: selectedCoordinates.lng }),
        })
      )
    );
  };

  const resetFilter = () => {
    setLocation(selectedLocation || "");
    setState(selectedState || "");
    dispatch(
      getSearchTrailersListAsync(
        cleanParams({
          category: selectedCategory,
          ...(selectedLocation && { address: selectedLocation }),
          ...(selectedState && { state: selectedState }),
          ...(coordinates && { lat: coordinates.lat }),
          ...(coordinates && { lng: coordinates.lng }),
        })
      )
    );
    handleClose();
  };

  const applyFilters = (newFilters) => {
    if (newFilters) {
      // Set the filters with the values from `newFilters`
      setFilters({
        minPrice: newFilters.priceRange[0],
        maxPrice: newFilters.priceRange[1],
        minLength: newFilters.lengthRange[0],
        maxLength: newFilters.lengthRange[1],
        minWeight: newFilters.weightCapacity[0],
        maxWeight: newFilters.weightCapacity[1],
        hitchType: newFilters.hitchType,
      });

      // Dispatch with filters
      dispatch(
        getSearchTrailersListAsync(
          cleanParams({
            category: selectedCategory,
            ...(location && { address: location }),
            ...(state && { state: state }),
            ...(selectedCoordinates && { lat: selectedCoordinates.lat }),
            ...(selectedCoordinates && { lng: selectedCoordinates.lng }),
            minPrice: newFilters.priceRange[0],
            maxPrice: newFilters.priceRange[1],
            minLength: newFilters.lengthRange[0],
            maxLength: newFilters.lengthRange[1],
            minWeight: newFilters.weightCapacity[0],
            maxWeight: newFilters.weightCapacity[1],
            hitchType: newFilters.hitchType,
          })
        )
      );
    } else {
    }

    handleClose(); // Close the modal after applying filters
  };

  const _handleSelectedLocation = (value) => {
    setLocation(value?.placeDetails?.label);
    console.log("value.coordinates:", value.coordinates);
    setSelectedCoordinates({
      lat: value.coordinates.lat,
      lng: value.coordinates.lng,
    });
  };

  return (
    <Stack
      sx={{ maxHeight: "100%" }}
      className="scroll-handling-class"
      style={{ overflowY: "visible" }}
    >
      <Stack
        sx={{
          position: "sticky",
          top: 0,
          backgroundColor: "white",
          zIndex: 1000,
          paddingBottom: "10px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Stack
          px={2}
          mt={2}
          mb={2}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
            <IoFilter color={themeRed} size={30} />
            <Typography style={{ fontSize: "18px", color: "black" }}>
              Search Filters
            </Typography>
          </Stack>
          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            style={{ cursor: "pointer" }}
            onClick={handleOpen}
          >
            <IoFilter color={themeRed} size={20} />
            <Typography
              style={{
                fontSize: "15px",
                color: themeRed,
                textDecoration: "underline",
              }}
            >
              Advanced Filters
            </Typography>
          </Stack>
        </Stack>
        <Grid container mb={2} spacing={3} alignItems={"center"} px={2}>
          <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
            {/* <LocationSearch padding={0} isSearch={false} width={"100%"} listWidth={"40%"} placeholder={"Location"} onLocationSelect={handleLocationSelect}/> */}
            {/* <LocationSearchStates
              placeholder={"Select State"}
              selectedState={location}
              onLocationSelect={handleLocationSelect}
              width={"100%"}
            /> */}
            <Stack
              sx={{
                flex: 1,
                borderRadius: "8px",
                boxShadow:
                  "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
              }}
            >
              <CustomLocationSearch
                textCondition={false}
                setSelectedLocationProps={_handleSelectedLocation}
                defaultValue={location}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
            <FormControl fullWidth>
              <Select
                value={selectedCategory}
                onChange={handleCategoryChange}
                displayEmpty
                sx={{
                  backgroundColor: "white",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                    borderRadius: 2,
                    boxShadow: 2,
                  },
                  "& .MuiSelect-select": {
                    borderRadius: 2,
                    boxShadow: 2,
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
              >
                <MenuItem value="">-- Select Category --</MenuItem>
                {trailerCategories.map((category) => (
                  <MenuItem key={category.id} value={category.name}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
            <CustomButton
              children={"Search"}
              style={{ width: "100%" }}
              onClick={handleSearch}
            />
          </Grid>
        </Grid>
      </Stack>

      {/* Trailer List */}
      {get_search_trailers_list_status === asyncStatus.LOADING ? (
        Array.from(new Array(3)).map((_, index) => (
          <Stack px={2}>
            <TrailerCardSkeleton key={index} />
          </Stack>
        ))
      ) : (
        <Box
          sx={{
            maxHeight: "calc(100vh - 100px)",
            overflowY: "auto",
            paddingRight: 2,
            paddingLeft: 2,
          }}
        >
          <Stack>
            {Array.isArray(trailers) && trailers.length > 0 && (
              <>
                <Typography
                  gutterBottom
                  sx={{
                    color: "#000",
                    lineHeight: "normal",
                    fontWeight: "700",
                    fontSize: "24px",
                    mt: 4,
                  }}
                >
                  {numberOfTrailors ?? ""}+ Trailers available
                </Typography>
                <Typography
                  gutterBottom
                  sx={{
                    color: "#000",
                    lineHeight: "normal",
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                >
                  These trailers can be picked up
                </Typography>
              </>
            )}

            {/* Check if trailers is a valid array */}
            {Array.isArray(trailers) && trailers.length > 0 ? (
              trailers?.map((trailer, index) => (
                <TrailerCard
                  key={index}
                  trailer={trailer}
                  onClick={() =>
                    navigate(`/trailer-book-details/${trailer._id}`)
                  }
                />
              ))
            ) : (
              <Stack
                alignItems={"center"}
                justifyContent={"center"}
                sx={{ height: "40vh", width: "100%" }}
              >
                <Typography
                  sx={{
                    color: "grey",
                    fontSize: "20px",
                    mt: 4,
                    textAlign: "center",
                  }}
                >
                  No trailers are available
                </Typography>
              </Stack>
            )}
          </Stack>
        </Box>
      )}
      {/* Advanced Filters Modal */}

      <Modal
        open={open}
        onClose={handleClose}
        BackdropProps={{ style: { backdropFilter: "blur(2px)" } }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", sm: "70%", md: "40%", lg: "30%" },
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 3,
            borderRadius: 4,
            maxHeight: "90vh",
            overflowY: "auto",
          }}
        >
          <Typography
            variant="h5"
            component="h2"
            fontWeight={700}
            sx={{
              mb: 3,
              color: "error.main",
              borderBottom: "2px solid",
              borderColor: "error.main",
              pb: 1,
            }}
          >
            Advanced Filters
          </Typography>

          <TrailerFilters
            applyFilters={applyFilters}
            resetFilter={resetFilter}
          />
        </Box>
      </Modal>
    </Stack>
  );
};

const TrailerMapLayout = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    location: selectedLocation,
    location_state: selectedState,
    name: categoryName,
    coordinates,
  } = location.state || {};

  const { get_search_trailers_list_status, get_search_trailers_list_data } =
    useSelector((state) => state.trailersDetailsManage);

  useEffect(() => {
    dispatch(
      getSearchTrailersListAsync({
        ...(selectedLocation && { address: selectedLocation }),
        ...(selectedState && { state: selectedState }),
        ...(categoryName && { category: categoryName }),
        ...(coordinates && { lat: coordinates.lat, lng: coordinates.lng }),
      })
    );
  }, [dispatch, selectedLocation, selectedState, categoryName]);
  const trailers = get_search_trailers_list_data?.data || [];
  const numberOfTrailers = get_search_trailers_list_data?.data?.length || [];

  return (
    <>
      <Navbar />
      <Grid container sx={{ height: "calc(100vh - 64px)" }}>
        {/* Left side: Scrollable trailer list */}
        <Grid
          item
          xl={7}
          lg={7}
          md={7}
          sm={12}
          xs={12}
          order={{ xs: 2, sm: 1 }}
          sx={{
            height: { xs: "100%", sm: "100%" } /* Split screen on mobile */,
            overflow: "auto",
          }}
        >
          <TrailerList
            name={categoryName}
            trailers={trailers}
            numberOfTrailors={numberOfTrailers}
            get_search_trailers_list_status={get_search_trailers_list_status}
            selectedLocation={selectedLocation}
            selectedState={selectedState}
            coordinates={coordinates}
          />
        </Grid>

        {/* Right side: Map with trailers */}
        <Grid
          item
          xl={5}
          lg={5}
          md={5}
          sm={12}
          xs={12}
          order={{ xs: 1, sm: 2 }}
          sx={{
            // height: "90vh",
            // display: {
            //   xl: "block",
            //   lg: "block",
            //   md: "block",
            //   sm: "none",
            //   xs: "none",
            // },
            height: { xs: "30vh", sm: "100%" } /* Split screen on mobile */,
            // display: "block" /* Remove responsive hiding */,
          }}
        >
          <Stack sx={{ overflow: "hidden", height: "100%" }}>
            <MapRender trailers={trailers} />
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default TrailerMapLayout;
