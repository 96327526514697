import axios from "axios";
import {
  session_expired,
} from "../../store/constants";
import { save_tokens_constant } from "../../utils/asyncStatus";

export const exit_session = () => {
  localStorage.setItem(session_expired, true);
  window.location.reload();
  localStorage.removeItem("auth");
  sessionStorage.clear()
};
export const baseURL = `https://trailer-backend-5c9a7f112e54.herokuapp.com/`;
// export const baseURL = `http://192.168.100.14:5002/`;

export const apiHandle = axios.create({
  baseURL: `${baseURL}`,
});

axios.defaults.timeout = 15000;

apiHandle.interceptors.request.use(async (req) => {
  const authTokens = localStorage.getItem(save_tokens_constant.AUTH)
    ? localStorage.getItem(save_tokens_constant.AUTH)
    : null;
  if (authTokens) {
    req.headers.Authorization = `Bearer ${authTokens}`;
  }

  return req;
});

// import axios from "axios";
// import { save_tokens_constant, session_expired } from "./../../store/constants";

// export const exit_session = () => {
//   localStorage.setItem(session_expired, true);
//   localStorage.removeItem("auth");
//   window.location.reload();
// };

// export const baseURL = `https://trailer-backend-5c9a7f112e54.herokuapp.com/`;
// // export const baseURL = `http://192.168.0.135:5001`;

// export const apiHandle = axios.create({
//   baseURL: `${baseURL}`,
//   headers: {
//     "Content-Type": "application/json",
//   },
//   timeout: 15000,
// });

// // Request Interceptor
// apiHandle.interceptors.request.use(
//   async (req) => {
//     const authTokens = localStorage.getItem(save_tokens_constant.AUTH);
//     if (authTokens) {
//       req.headers.Authorization = `Bearer ${authTokens}`;
//     }
//     return req;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// // Response Interceptor
// apiHandle.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     if (error.response) {
//       if (error.response.status === 401 || error.response.status === 498) {
//         // Unauthorized, session expired
//         exit_session();
//       } else if (error.response.status === 403) {
//         console.error(
//           "Access Forbidden: You don't have permission to access this resource."
//         );
//       } else if (error.response.status === 500) {
//         console.error("Server Error: Please try again later.");
//       }
//     } else if (error.request) {
//       console.error("No response received from the server.");
//     } else {
//       console.error("Error setting up request: ", error.message);
//     }
//     return Promise.reject(error);
//   }
// );
