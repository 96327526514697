import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Avatar,
  Chip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { themeRed } from "../../utils/colorTheme";
import BookingTabsManagement from "../BookingTabsManagement/BookingTabsManagement";
import { FaLocationDot } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { HiCheckBadge } from "react-icons/hi2";

// const GoogleMap = ({ latitude, longitude }) => {
//   const mapRef = useRef(null);

//   useEffect(() => {
//     if (latitude && longitude) {
//       const map = new window.google.maps.Map(mapRef.current, {
//         center: { lat: latitude, lng: longitude },
//         zoom: 12,
//         disableDefaultUI: true,
//       });

//       new window.google.maps.Marker({
//         position: { lat: latitude, lng: longitude },
//         map,
//       });
//     }
//   }, [latitude, longitude]);

//   const handleMapClick = () => {
//     const googleMapsURL = `https://www.google.com/maps?q=${latitude},${longitude}`;
//     window.open(googleMapsURL, "_blank"); // Opens in a new tab
//   };

//   return (
//     <div
//       ref={mapRef}
//       style={{ width: "100%", height: "200px", cursor: "pointer" }}
//       onClick={handleMapClick}
//       title="Click to view larger map"
//     />
//   );
// };

// const GoogleMap = ({ latitude, longitude }) => {
//   const mapRef = useRef(null);
//   const circleRef = useRef(null);

//   useEffect(() => {
//     if (latitude && longitude) {
//       const map = new window.google.maps.Map(mapRef.current, {
//         center: { lat: latitude, lng: longitude },
//         zoom: 12,
//         disableDefaultUI: true,
//       });

//       // Add marker
//       new window.google.maps.Marker({
//         position: { lat: latitude, lng: longitude },
//         map,
//       });

//       // Add circle
//       circleRef.current = new window.google.maps.Circle({
//         strokeColor: themeRed,
//         strokeOpacity: 0.8,
//         strokeWeight: 2,
//         fillColor: themeRed,
//         fillOpacity: 0.2,
//         map,
//         center: { lat: latitude, lng: longitude },
//         radius: 5000, // 5km radius in meters
//       });
//     }
//   }, [latitude, longitude]);

//   const handleMapClick = () => {
//     const googleMapsURL = `https://www.google.com/maps?q=${latitude},${longitude}`;
//     window.open(googleMapsURL, "_blank");
//   };

//   return (
//     <div
//       ref={mapRef}
//       style={{ width: "100%", height: "200px", cursor: "pointer" }}
//       onClick={handleMapClick}
//       title="Click to view larger map"
//     />
//   );
// };

const GoogleMap = ({ latitude, longitude }) => {
  const mapRef = useRef(null);
  const circleRef = useRef(null);
  const mapInstanceRef = useRef(null);

  useEffect(() => {
    if (latitude && longitude) {
      const map = new window.google.maps.Map(mapRef.current, {
        center: { lat: latitude, lng: longitude },
        zoom: 12,
        disableDefaultUI: true,
      });
      mapInstanceRef.current = map;

      // Add marker
      new window.google.maps.Marker({
        position: { lat: latitude, lng: longitude },
        map,
      });

      // Calculate initial radius based on zoom level
      const calculateRadius = (zoom) => {
        // Base meters per pixel at zoom level 0 at equator
        const metersPerPixel =
          (156543.03392 * Math.cos((latitude * Math.PI) / 180)) /
          Math.pow(2, zoom);
        // Return radius for ~100px circle diameter
        return 50 * metersPerPixel; // Adjust this value to change circle size
      };

      // Initial circle setup
      const initialZoom = map.getZoom();
      circleRef.current = new window.google.maps.Circle({
        strokeColor: themeRed,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: themeRed,
        fillOpacity: 0.2,
        map,
        center: { lat: latitude, lng: longitude },
        radius: calculateRadius(initialZoom),
      });

      // Update circle on zoom change
      const zoomListener = map.addListener("zoom_changed", () => {
        const newZoom = map.getZoom();
        const newRadius = calculateRadius(newZoom);
        circleRef.current.setRadius(newRadius);
      });

      // Cleanup
      return () => {
        if (zoomListener) zoomListener.remove();
      };
    }
  }, [latitude, longitude]);

  const handleMapClick = () => {
    const googleMapsURL = `https://www.google.com/maps?q=${latitude},${longitude}`;
    window.open(googleMapsURL, "_blank");
  };

  return (
    <div
      ref={mapRef}
      style={{ width: "100%", height: "200px", cursor: "pointer" }}
      onClick={handleMapClick}
      title="Click to view larger map"
    />
  );
};

// const calculateDays = (dateRange) => {
//   // Split the dateRange to get start and end dates
//   const [startDate, endDate] = dateRange.split(" - ");

//   // Convert to Date objects
//   const start = new Date(startDate);
//   const end = new Date(endDate);

//   // Calculate the difference in milliseconds
//   const diffInMs = end - start;

//   // Convert milliseconds to days
//   const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24)) + 1; // +1 to include the end day

//   return diffInDays;
// };
// const calculateDays = (dateRange, pickupTime, dropoffTime) => {
//   console.log("Formatted Dates:", dateRange);

//   if (!dateRange || !pickupTime || !dropoffTime) return 0;

//   const [startDateStr, endDateStr] = dateRange.split(" - ");

//   // Parse the datetime strings
//   const start = new Date(`${startDateStr} ${pickupTime}`);
//   const end = new Date(`${endDateStr} ${dropoffTime}`);

//   // Handle edge case: end is before start (invalid)
//   if (end <= start) return 1;

//   // Get difference in hours
//   const diffInMs = end - start;
//   const diffInHours = diffInMs / (1000 * 60 * 60);

//   // console.log("Difference in hours:", diffInHours);

//   // Count every 24 hours as one day
//   const totalDays = Math.ceil(diffInHours / 24);

//   return totalDays;
// };

// const calculateDays = (dateRangeArray, pickupTime, dropoffTime) => {
//   console.log("Formatted Dates:", dateRangeArray);
//   console.log("pickupTime", pickupTime);
//   console.log("dropoffTime", dropoffTime);

//   // Sort date strings to ensure correct order
//   const sortedDates = [...dateRangeArray].sort();

//   const startDateStr = sortedDates[0];
//   const endDateStr = sortedDates[sortedDates.length - 1];

//   // Combine date and time into Date objects
//   const start = new Date(`${startDateStr} ${pickupTime}`);
//   const end = new Date(`${endDateStr} ${dropoffTime}`);

//   // Handle edge case: end is before start
//   if (end <= start) return 1;

//   // Get difference in hours
//   const diffInMs = end - start;
//   const diffInHours = diffInMs / (1000 * 60 * 60);

//   // Convert total hours into number of full days (rounding up partials)
//   const totalDays = Math.ceil(diffInHours / 24);
//   console.log("totalDays:", totalDays);

//   return totalDays;
// };

// const calculateDays = (dateRangeArray, pickupTime, dropoffTime) => {
//   if (
//     !Array.isArray(dateRangeArray) ||
//     dateRangeArray.length === 0 ||
//     !pickupTime ||
//     !dropoffTime
//   ) {
//     return 0;
//   }

//   let totalHours = 0;

//   dateRangeArray.forEach((dateStr) => {
//     const start = new Date(`${dateStr} ${pickupTime}`);
//     const end = new Date(`${dateStr} ${dropoffTime}`);

//     // If dropoff is before pickup on same day, assume it's next day
//     if (end <= start) {
//       end.setDate(end.getDate() + 1);
//     }

//     const diffMs = end - start;
//     const hours = diffMs / (1000 * 60 * 60);
//     totalHours += hours;
//   });

//   const totalDays = Math.ceil(totalHours / 24);
//   console.log("Total Selected Dates:", dateRangeArray);
//   console.log("Total Hours from Selected Dates:", totalHours);
//   console.log("Total Days (rounded):", totalDays);

//   return totalDays;
// };

// const calculateDays = (dateRangeArray, pickupTime, dropoffTime) => {
//   if (
//     !Array.isArray(dateRangeArray) ||
//     dateRangeArray.length === 0 ||
//     !pickupTime ||
//     !dropoffTime
//   ) {
//     return 0;
//   }

//   // Step 1: Sort dates for accurate range
//   const sortedDates = [...dateRangeArray].sort();

//   // Step 2: Apply pickup time to first date
//   const startDateStr = sortedDates[0];
//   const start = new Date(`${startDateStr} ${pickupTime}`);

//   // Step 3: Apply dropoff time to last date
//   const endDateStr = sortedDates[sortedDates.length - 1];
//   let end = new Date(`${endDateStr} ${dropoffTime}`);

//   // Step 4: Handle case where dropoffTime is earlier than pickupTime
//   if (end <= start) {
//     end.setDate(end.getDate() + 1); // Treat as next day
//   }

//   // Step 5: Calculate difference in milliseconds and convert to hours
//   const diffMs = end - start;
//   const diffHours = diffMs / (1000 * 60 * 60);

//   // Step 6: Round up to full 24-hour billing days
//   const totalDays = Math.ceil(diffHours / 24);

//   console.log("Selected Dates:", dateRangeArray);
//   console.log("Start:", start.toString());
//   console.log("End:", end.toString());
//   console.log("Total Hours:", diffHours);
//   console.log("Total Billing Days:", totalDays);

//   return totalDays;
// };

// const calculateDays = (selectedDates, pickupTime, dropoffTime) => {
//   if (
//     !Array.isArray(selectedDates) ||
//     selectedDates.length === 0 ||
//     !pickupTime ||
//     !dropoffTime
//   ) {
//     return 0;
//   }

//   let totalHours = 0;

//   selectedDates.forEach((dateStr) => {
//     const start = new Date(`${dateStr} ${pickupTime}`);
//     let end = new Date(`${dateStr} ${dropoffTime}`);

//     // If dropoff is before or equal to pickup, treat dropoff as next day
//     if (end <= start) {
//       end.setDate(end.getDate() + 1);
//     }

//     const diffInMs = end - start;
//     const hours = diffInMs / (1000 * 60 * 60);

//     totalHours += hours;
//   });

//   const totalDays = Math.ceil(totalHours / 24);

//   console.log("Selected Dates:", selectedDates);
//   console.log("Pickup Time:", pickupTime);
//   console.log("Dropoff Time:", dropoffTime);
//   console.log("Total Accumulated Hours:", totalHours);
//   console.log("Total Billing Days (24hr chunks):", totalDays);

//   return totalDays;
// };

// function convertTo24Hour(timeStr) {
//   const [time, modifier] = timeStr.split(" ");
//   let [hours, minutes] = time.split(":");

//   hours = parseInt(hours, 10);
//   if (modifier.toLowerCase() === "pm" && hours !== 12) {
//     hours += 12;
//   }
//   if (modifier.toLowerCase() === "am" && hours === 12) {
//     hours = 0;
//   }

//   return `${String(hours).padStart(2, "0")}:${minutes}:00`;
// }

// const calculateDays = (selectedDates, pickupTime, dropoffTime) => {
//   console.log("selectedDates:", selectedDates);
//   console.log("pickupTime:", pickupTime);
//   console.log("dropoffTime:", dropoffTime);

//   if (
//     !Array.isArray(selectedDates) ||
//     selectedDates.length === 0 ||
//     !pickupTime ||
//     !dropoffTime
//   ) {
//     return 0;
//   }

//   // Helper function to convert date string and time string to a Date object
//   const toDateTime = (dateStr, timeStr) => {
//     const [month, day, year] = dateStr.split("/");
//     const formattedDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
//       2,
//       "0"
//     )}`;
//     const time24 = convertTo24Hour(timeStr.trim());
//     return new Date(`${formattedDate}T${time24}`);
//   };

//   // Sort selected dates in ascending order
//   const sortedDates = [...selectedDates].sort(
//     (a, b) => new Date(a) - new Date(b)
//   );

//   // If there's only one date selected
//   if (sortedDates.length === 1) {
//     const start = toDateTime(sortedDates[0], pickupTime);
//     const end = toDateTime(sortedDates[0], dropoffTime);

//     // If end time is earlier than start time, assume it's the next day
//     if (end <= start) {
//       // Add a day to end time
//       end.setDate(end.getDate() + 1);
//     }

//     const diffHours = (end - start) / (1000 * 60 * 60);
//     console.log("Hours difference for single date:", diffHours);

//     // If less than 24 hours, count as 1 day
//     return 1;
//   }

//   // If multiple dates are selected, calculate total time span
//   let totalDays = 0;

//   // For first date: count from pickup time to end of day (midnight)
//   const firstStart = toDateTime(sortedDates[0], pickupTime);
//   const firstDayEnd = new Date(firstStart);
//   firstDayEnd.setHours(23, 59, 59, 999);
//   const firstDayHours = (firstDayEnd - firstStart) / (1000 * 60 * 60);

//   // For last date: count from start of day (midnight) to dropoff time
//   const lastDayStart = toDateTime(
//     sortedDates[sortedDates.length - 1],
//     "12:00 am"
//   );
//   const lastEnd = toDateTime(sortedDates[sortedDates.length - 1], dropoffTime);
//   const lastDayHours = (lastEnd - lastDayStart) / (1000 * 60 * 60);

//   // Calculate total hours from first pickup to last dropoff
//   const totalStart = toDateTime(sortedDates[0], pickupTime);
//   const totalEnd = toDateTime(sortedDates[sortedDates.length - 1], dropoffTime);
//   let totalHours = (totalEnd - totalStart) / (1000 * 60 * 60);

//   // If end time is earlier than start time on the last day, add 24 hours
//   if (pickupTime > dropoffTime && sortedDates.length > 1) {
//     totalHours += 24;
//   }

//   console.log("Total hours between first pickup and last dropoff:", totalHours);

//   // Calculate days based on 24-hour periods
//   if (totalHours <= 24) {
//     totalDays = 1;
//   } else {
//     totalDays = Math.ceil(totalHours / 24);
//   }

//   // Special case: if dates are consecutive and within 24 hours
//   if (sortedDates.length === 2) {
//     const firstDate = new Date(sortedDates[0]);
//     const secondDate = new Date(sortedDates[1]);
//     const daysDiff = (secondDate - firstDate) / (1000 * 60 * 60 * 24);

//     if (daysDiff === 1) {
//       // Check if the total time is less than 24 hours
//       if (totalHours <= 24) {
//         totalDays = 1;
//       } else {
//         totalDays = 2;
//       }
//     }
//   }

//   return totalDays;
// };

function convertTo24Hour(timeStr) {
  const [time, modifier] = timeStr.split(" ");
  let [hours, minutes] = time.split(":");

  hours = parseInt(hours, 10);
  if (modifier.toLowerCase() === "pm" && hours !== 12) {
    hours += 12;
  }
  if (modifier.toLowerCase() === "am" && hours === 12) {
    hours = 0;
  }

  return `${String(hours).padStart(2, "0")}:${minutes}:00`;
}

const calculateDays = (selectedDates, pickupTime, dropoffTime) => {
  console.log("selectedDates:", selectedDates);
  console.log("pickupTime:", pickupTime);
  console.log("dropoffTime:", dropoffTime);

  if (
    !Array.isArray(selectedDates) ||
    selectedDates.length === 0 ||
    !pickupTime ||
    !dropoffTime
  ) {
    return 0;
  }

  // Helper function to convert date string and time string to a Date object
  const toDateTime = (dateStr, timeStr) => {
    const [month, day, year] = dateStr.split("/");
    const formattedDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
      2,
      "0"
    )}`;
    const time24 = convertTo24Hour(timeStr.trim());
    return new Date(`${formattedDate}T${time24}`);
  };

  // Process each selected date individually
  let totalDays = 0;

  // Process each date individually
  selectedDates.forEach((dateStr) => {
    const start = toDateTime(dateStr, pickupTime);
    const end = toDateTime(dateStr, dropoffTime);

    // If end time is earlier than start time, assume it's for the next day
    if (end <= start) {
      const diffHours =
        24 -
        (start.getHours() + start.getMinutes() / 60) +
        (end.getHours() + end.getMinutes() / 60);
      // If rental spans to next day, consider it as 1 day
      totalDays += 1;
    } else {
      // Same day rental
      totalDays += 1;
    }
  });

  // Special case: Check if there are exactly 2 consecutive dates with times that make it within 24 hours
  if (selectedDates.length === 2) {
    // Sort the dates to ensure correct order
    const sortedDates = [...selectedDates].sort(
      (a, b) => new Date(a) - new Date(b)
    );

    const firstStart = toDateTime(sortedDates[0], pickupTime);
    const secondEnd = toDateTime(sortedDates[1], dropoffTime);

    // Calculate total hours between first pickup and second dropoff
    const totalHours = (secondEnd - firstStart) / (1000 * 60 * 60);

    // Check if dates are consecutive
    const firstDate = new Date(sortedDates[0]);
    const secondDate = new Date(sortedDates[1]);
    const oneDayInMs = 24 * 60 * 60 * 1000;
    const isConsecutive = secondDate - firstDate === oneDayInMs;

    // If dates are consecutive and total time is less than 24 hours, count as 1 day
    if (isConsecutive && totalHours <= 24) {
      return 1;
    }
  }

  return totalDays;
};

// const calculateDays = (dateRange, pickupTime) => {
//   if (!dateRange || !pickupTime) return 0;

//   const [startDateStr, endDateStr] = dateRange.split(" - ");

//   // Combine each date with the pickupTime (e.g., "2024-04-01 11:00 PM")
//   const start = new Date(`${startDateStr} ${pickupTime}`);
//   const end = new Date(`${endDateStr} ${pickupTime}`);

//   const diffInMs = end - start;
//   const diffInHours = diffInMs / (1000 * 60 * 60);
//   console.log("Difference in hours:", diffInHours);

//   // Convert to full 24-hour days (rounded up if you want partial days to count as full)
//   const totalDays = Math.ceil(diffInHours / 24);

//   return totalDays;
// };

const TrailerDetailsCom = ({ trailerData }) => {
  const navigate = useNavigate();
  const { userAuth } = useSelector((state) => state.userAuth);

  const [selectedPricing, setSelectedPricing] = useState("");
  const [dateRange, setDateRange] = useState("");
  const [pickupStartTime, setPickupStartTime] = useState("12:00 am");
  const [pickupEndTime, setPickupEndTime] = useState("11:59 pm");
  const [pickupTab, setPickupTab] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);

  const handleDeliveryAddressChange = (address) => {
    setDeliveryAddress(address);
  };
  const handleDateRangeChange = (range) => {
    setDateRange(range);
  };

  const handlePickupStartTimeChange = (time) => {
    setPickupStartTime(time);
  };
  const handlePickupEndTimeChange = (time) => {
    setPickupEndTime(time);
  };
  const handleTabChange = (time) => {
    setPickupTab(time);
  };
  const numberOfDays = calculateDays(dateRange, pickupStartTime, pickupEndTime);
  console.log("number of days", numberOfDays);

  const obj = {
    trailerData,
    numberOfDays,
    dateRange,
    pickupStartTime,
    pickupEndTime,
    pickupTab:
      pickupTab === "" ? "Pickup" : pickupTab === 0 ? "Pickup" : "Delivery",
    address:
      pickupTab === ""
        ? trailerData?.location
        : pickupTab === 0
        ? trailerData?.location
        : deliveryAddress,
  };

  // Update isDisabled state based on obj's field values
  useEffect(() => {
    const allFieldsFilled =
      trailerData &&
      numberOfDays &&
      dateRange &&
      pickupStartTime &&
      pickupEndTime &&
      obj.address;

    setIsDisabled(!allFieldsFilled);
  }, [
    trailerData,
    numberOfDays,
    dateRange,
    pickupStartTime,
    pickupEndTime,
    obj.address,
  ]);

  const handleBookNowHandle = () => {
    if (userAuth) {
      // Navigate to checkout with the booking details
      navigate("/checkout", { state: obj });
    } else {
      // Store the intended route and state
      sessionStorage.setItem(
        "redirectPath",
        JSON.stringify({
          path: "/checkout",
          state: obj,
        })
      );
      sessionStorage.setItem(
        "trailer_id",
        JSON.stringify({
          state: trailerData?._id,
        })
      );

      // Redirect to login page
      navigate("/login");
    }
  };

  return (
    <Grid container spacing={2} style={{ padding: "20px" }}>
      {/* Left Section for Trailer Details */}
      <Grid item xs={12} md={8}>
        <Card>
          <CardContent>
            <Stack
              alignItems={"flex-start"}
              direction="row"
              justifyContent={"space-between"}
            >
              <Typography variant="h5" fontWeight={"bold"} width={"50%"}>
                {trailerData?.title} Trailer
              </Typography>
              <Typography
                variant="body1"
                style={{
                  backgroundColor: themeRed,
                  color: "white",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "10px",
                  fontSize: "12px",
                  fontWeight: "bold",
                  padding: "10px",
                }}
              >
                Trailer ID : {trailerData?.trailer_id}
              </Typography>
            </Stack>
            <Typography variant="body2" color="textSecondary">
              <span style={{ display: "flex", alignItems: "center" }}>
                <span>
                  <FaLocationDot color={themeRed} size={20} />
                </span>
                <span>{trailerData?.complete_address}</span>
              </span>
            </Typography>
            <Typography variant="body1" style={{ marginTop: "20px" }}>
              {trailerData?.trailer_description}
            </Typography>

            {/* Specifications */}
            <Typography
              variant="h6"
              style={{ marginTop: "20px", fontWeight: "bold" }}
            >
              Specifications:
            </Typography>
            <Typography variant="body1">
              <strong>Trailer Category:</strong> {trailerData?.category}
            </Typography>
            <Typography variant="body1">
              <strong>Trailer Dimensions:</strong>{" "}
              {trailerData?.rear_door_opening_height} x{" "}
              {trailerData?.trailer_width}
            </Typography>
            <Typography variant="body1">
              <strong>Hitch Type:</strong> {trailerData?.hitch_type}
            </Typography>
            <Typography variant="body1">
              <strong>Ball Size:</strong> {trailerData?.ball_size}
            </Typography>
            <Typography variant="body1">
              <strong>Payload Capacity:</strong> {trailerData?.payload_capacity}
            </Typography>
            <Typography variant="body1">
              <strong>Length:</strong> {trailerData?.trailer_length}
            </Typography>
            <Typography variant="body1">
              <strong>Make:</strong> {trailerData?.make}
            </Typography>
            <Typography variant="body1">
              <strong>Model:</strong> {trailerData?.model}
            </Typography>
            <Typography variant="body1">
              <strong>Year:</strong> {trailerData?.year}
            </Typography>
            <Typography variant="body1">
              <strong>Axles:</strong> {trailerData?.axles}
            </Typography>
            {/* Add more specifications */}
          </CardContent>
        </Card>

        {/* Cancellation Policy Accordion */}
        <Accordion sx={{ marginTop: "20px" }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              Cancellation Policy
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="h6">
              {" "}
              Rentals Booked More Than 24 Hours in Advance
            </Typography>
            <Typography
              variant="body1"
              sx={{ marginTop: "8px", color: "gray" }}
            >
              {" "}
              Cancel at least 24 hours prior to the start of your rental and
              receive a 100% refund.
            </Typography>
            <Typography
              variant="body1"
              sx={{ marginTop: "5px", color: "gray" }}
            >
              {" "}
              For rentals booked within 24 hours of the trip start time, guests
              have up to one (1) hour after booking to cancel and receive a full
              refund.
            </Typography>
            <Typography
              variant="body1"
              sx={{ marginTop: "5px", color: "gray" }}
            >
              {" "}
              Otherwise, no refund for cancellations within 24 hours.
            </Typography>
            <Typography
              variant="body1"
              sx={{ marginTop: "5px", color: "gray" }}
            >
              {" "}
              Platform fees are non-refundable after the booking is accepted.
            </Typography>
            <Typography
              variant="body1"
              sx={{ marginTop: "5px", color: "gray" }}
            >
              {" "}
              For a complete explanation of our Cancellation Policies, we invite
              you to check out:{" "}
              <Typography
                component="span"
                sx={{
                  marginTop: "5px",
                  color: "black",
                  cursor: "pointer",
                  ":hover": {
                    textDecoration: "underline",
                  },
                  // textDecorationLine: "underline",
                }}
                onClick={() => navigate("/cancellation-policy")}
              >
                Click here to read our Cancellation Policy.
              </Typography>{" "}
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* Posted By Section */}
        <Card style={{ marginTop: "20px" }}>
          <CardContent>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              gap={3}
              my={1}
            >
              <Typography
                variant="h6"
                style={{ marginBottom: "20px", fontWeight: "bold" }}
              >
                Posted By
              </Typography>
              {trailerData?.host_id?.kyc === "Completed" &&
                trailerData?.host_id?.status === "Approved" && (
                  <Chip
                    label="Verified"
                    icon={
                      <HiCheckBadge
                        style={{ color: "green", fontSize: "18px" }}
                      />
                    }
                    sx={{
                      backgroundColor: "greenyellow",
                      color: "green",
                      fontWeight: 500,
                      "& .MuiChip-icon": { ml: 0.5 },
                    }}
                  />
                )}
            </Stack>
            {/* <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    gap={1}
                    sx={{
                      p: "4px 8px",
                      borderRadius: "10px",
                      backgroundColor: "greenyellow",
                      width: "fit-content",
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        color: "green",
                        fontSize: "15px",
                      }}
                    >
                      Verified
                    </Typography>
                    <HiCheckBadge style={{ color: "green" }} />
                  </Stack> */}
            <Stack direction="row" spacing={2} alignItems={"center"}>
              <Stack>
                <Avatar
                  sx={{ width: 60, height: 60 }}
                  src={trailerData?.host_id?.avatar}
                />
              </Stack>
              <Stack>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "500", fontSize: "18px" }}
                >
                  {trailerData?.host_id?.first_name}{" "}
                  {trailerData?.host_id?.last_name[0]}.
                </Typography>

                {/* <Typography
                  sx={{ fontWeight: "400", fontSize: "15px", color: "gray" }}
                >
                  Email:{trailerData?.host_id?.email}
                </Typography> */}
              </Stack>
            </Stack>
          </CardContent>
        </Card>

        {/* Reviews  By Section */}
        <Card style={{ marginTop: "20px" }}>
          <CardContent>
            <Typography
              variant="h6"
              style={{ marginBottom: "20px", fontWeight: "bold" }}
            >
              Reviews
            </Typography>
            <Typography>No reviews yet</Typography>
          </CardContent>
        </Card>
      </Grid>

      {/* Right Section for Pricing, Booking, and Map */}
      <Grid item xs={12} md={4}>
        <Card>
          <CardContent>
            {/* Pricing Section */}
            <div
              style={{
                border: `1px solid #ddd`,
                borderRadius: "10px",
                padding: "20px",
                textAlign: "center",
                margin: "auto",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    background: numberOfDays <= 6 ? themeRed : "#F0F0F0",
                    padding: "10px",
                    borderRadius: "8px",
                    flex: 1,
                    marginRight: "10px",
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: "bold",
                      color: numberOfDays <= 6 ? "white" : "#000",
                      fontSize: "14px",
                    }}
                  >
                    ${trailerData?.daily_rate}.00
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{
                      fontSize: "12px",
                      color: numberOfDays <= 6 ? "white" : "#000",
                    }}
                  >
                    Per Day
                  </Typography>
                </div>
                <div
                  style={{
                    background:
                      numberOfDays > 6 && numberOfDays <= 29
                        ? themeRed
                        : "#E0E0E0",
                    padding: "10px",
                    borderRadius: "8px",
                    flex: 1,
                    marginRight: "10px",
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: "bold",
                      color:
                        numberOfDays > 6 && numberOfDays <= 29
                          ? "white"
                          : "#000",
                      fontSize: "14px",
                    }}
                  >
                    ${trailerData?.weekly_rate}.00
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{
                      fontSize: "12px",
                      color:
                        numberOfDays > 6 && numberOfDays <= 29
                          ? "white"
                          : "#000",
                    }}
                  >
                    Per Week
                  </Typography>
                </div>
                <div
                  style={{
                    background:
                      numberOfDays > 29 && numberOfDays <= 100
                        ? themeRed
                        : "#D0D0D0",
                    padding: "10px",
                    borderRadius: "8px",
                    flex: 1,
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: "bold",
                      color:
                        numberOfDays > 29 && numberOfDays <= 100
                          ? "white"
                          : "#000",
                      fontSize: "14px",
                    }}
                  >
                    ${trailerData?.monthly_rate}.00
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{
                      fontSize: "12px",
                      color:
                        numberOfDays > 29 && numberOfDays <= 100
                          ? "white"
                          : "#000",
                    }}
                  >
                    Per Month
                  </Typography>
                </div>
              </div>
              <Typography
                variant="h6"
                style={{
                  marginTop: "10px",
                  color: "#000",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                Refundable Deposit:
              </Typography>
              <Typography variant="h6" style={{ color: "#000" }}>
                ${trailerData?.refundable_deposit}.00
              </Typography>
            </div>

            {/* <BookingTabsManagement setSelectedPricing={setSelectedPricing} /> */}
            <BookingTabsManagement
              setSelectedPricing={setSelectedPricing}
              onDateRangeChange={handleDateRangeChange}
              onPickupStartTimeChange={handlePickupStartTimeChange}
              onPickupEndTimeChange={handlePickupEndTimeChange}
              onTabChange={handleTabChange}
              setDeliveryAddress={handleDeliveryAddressChange}
              onClick={handleBookNowHandle}
              isDisabled={isDisabled}
            />
          </CardContent>
        </Card>

        {/* Map Section */}
        <Card style={{ marginTop: "20px" }}>
          <CardContent>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              Trailer General Location
            </Typography>
            <GoogleMap
              latitude={trailerData?.latitude}
              longitude={trailerData?.longitude}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default TrailerDetailsCom;
