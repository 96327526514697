import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { apiHandle, baseURL } from "../config/apiHandle/apiHandle";
import { type_constant } from "../utils/asyncStatus";

export const login_service_auth = createAsyncThunk(
  type_constant.LOGIN,
  async (post_data) => {
    try {
      const response = await apiHandle.post(`login`, post_data);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("error", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const signup_service_auth = createAsyncThunk(
  type_constant.SIGNUP,
  async (post_data) => {
    try {
      const response = await apiHandle.post(`register`, post_data);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("error", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const check_auth = createAsyncThunk(
  type_constant.CHECK_AUTH,
  async () => {
    try {
      const response = await apiHandle.get(`check-auth`);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const update_profile_verify_async = createAsyncThunk(
  type_constant.UPDATE_PROFILE,
  async (post_data) => {
    try {
      const response = await apiHandle.post(`host/update-profile`, post_data);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const kyc_verify_async = createAsyncThunk(
  type_constant.VERIFY_KYC,
  async (post_data) => {
    try {
      const response = await apiHandle.post(`kyc-verification`, post_data);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const verify_otp_async = createAsyncThunk(
  type_constant.VERIFY_OTP,
  async (post_data) => {
    try {
      const response = await apiHandle.post(`code-verification`, post_data);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const resend_otp_async = createAsyncThunk(
  type_constant.RESEND_OTP,
  async () => {
    try {
      const response = await apiHandle.get(`resend-codes`);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const dashboard_api_async = createAsyncThunk(
  type_constant.DASHBOARD_API,
  async () => {
    try {
      const response = await apiHandle.get(`host/dashboard`);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const get_all_trailer_async = createAsyncThunk(
  type_constant.GET_ALL_TRAILERS,
  async () => {
    try {
      const response = await apiHandle.get(`host/trailers`);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const signup_vendor_auth = createAsyncThunk(
  type_constant.SIGNUP_VENDOR,
  async (post_data) => {
    try {
      const response = await apiHandle.post(`/vendor-signup`, post_data);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("error", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const get_signup_services = createAsyncThunk(
  type_constant.SIGNUP_SERVICES_GET,
  async (post_data) => {
    const { services, city } = post_data;
    try {
      const response = await apiHandle.get(
        `/get-general-data?services=${services}&cities=${city}`
      );
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("error", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const get_services = createAsyncThunk(
  type_constant.GET_SERVICES_HOME,
  async () => {
    try {
      const response = await apiHandle.get(`/get-homepage`);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("error", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const resetPasswordAsync = createAsyncThunk(
  type_constant.RESET_PASSWORD_ASYNC,
  async (post_data) => {
    try {
      const response = await apiHandle.post(`/reset-password-req`, post_data);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("error", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const resetPasswordOTPAsync = createAsyncThunk(
  type_constant.RESET_PASSWORD_OTP_ASYNC,
  async (post_data) => {
    try {
      const response = await apiHandle.post(
        `/reset-password-otp-verify`,
        post_data
      );
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("error", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const forgotPasswordChangeAsync = createAsyncThunk(
  type_constant.FORGOT_PASSWORD_CHANGE_ASYNC,
  async (post_data) => {
    try {
      const response = await apiHandle.post(
        `/reset-password-create`,
        post_data
      );
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("error", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const contactUsFormAsync = createAsyncThunk(
  type_constant.CONTACT_US_FORM_ASYNC,
  async (post_data) => {
    try {
      const response = await apiHandle.post(`/submit-contact`, post_data);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("error", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const getProvinceListAsync = createAsyncThunk(
  type_constant.GET_PROVINCE_LIST_ASYNC,
  async () => {
    try {
      const response = await apiHandle.get(`provinces`);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("error", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
