import React, { useEffect, useState } from "react";
import {
  TextField,
  MenuItem,
  FormControl,
  Grid,
  Box,
  Typography,
  Select,
  CircularProgress,
  Stack,
  Paper,
  IconButton,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { themeRed } from "../../utils/colorTheme";
import CustomButton from "../../component/common/Button/Button";
import bgIcon from "../../assets/bgIcon.png";
import { baseURL } from "../../config/apiHandle/apiHandle";
import axios from "axios";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { add_trailer, edit_trailer } from "../../services/bank_mange_service";
import { asyncStatus } from "../../utils/asyncStatus";
import { useLocation, useNavigate } from "react-router-dom";
import {
  setAddTrailerStatus,
  setEditTrailerStatus,
} from "../../store/slices/bank_manage_slice";
import { error_toast_message } from "../../utils/toast_message";
import { ToastContainer } from "react-toastify";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  axleOptions,
  ballSizeOptions,
  categoryOptions,
  hitchTypeOptions,
  lightPlugOptions,
  provinces,
  statesOptions,
} from "../../utils/ArrayData";
import { seteditTrailer } from "../../store/slices/trailers_details_slice";
import HeadingWithTooltip from "../../component/HeadingWithTooltip/HeadingWithTooltip";

const useStyles = makeStyles({
  label: {
    fontWeight: "bold",
    fontSize: "0.9rem",
    color: "#000000",
  },
  textField: {
    backgroundColor: "#fff",
  },
  formControl: {
    backgroundColor: "#fff",
    width: "100%",
  },
  select: {
    backgroundColor: "#fff",
    width: "100%",
  },
  button: {
    backgroundColor: "#d32f2f",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#b71c1c",
    },
    marginTop: "20px",
  },
  photoContainer: {
    position: "relative",
    margin: "0 auto",
    display: "flex",
    justifyContent: "center",
  },
  deleteButton: {
    position: "absolute",
    top: "5px",
    right: "5px",
    backgroundColor: "rgba(0,0,0,0.5)",
    color: "#fff",
    cursor: "pointer",
    borderRadius: "50%",
    padding: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1.2rem",
  },
  loadingOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 2,
  },
});

const TrailerAdd = () => {
  const classes = useStyles();
  const locationn = useLocation();
  const {
    add_trailer_status,
    add_trailer_data,
    add_trailer_error,
    edit_trailer_status,
    edit_trailer_data,
    edit_trailer_error,
  } = useSelector((state) => state.bankManage);

  const addTrailerLoader = add_trailer_status === asyncStatus.LOADING;
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: "",
    year: "",
    make: "",
    model: "",
    category: "",
    hitch_type: "",
    ball_size: "",
    province_insured: "",
    light_plug_configuration: "",
    trailer_dimension: "",
    trailer_deck_length: "",
    trailer_length: "",
    trailer_value: "",
    trailer_width: "",
    rear_door_opening_height: "",
    payload_capacity: "",
    axles: "",
    refundable_deposit: "",
    vin: "",
    province_territory: "",
    complete_address: "",
    daily_rate: "",
    weekly_rate: "",
    monthly_rate: "",
    rental_delay_max: "",
    rental_delay_min: "",
    tag_words: "",
    state: "",
    location: "",
    trailer_description: "",
  });

  const [errors, setErrors] = useState({});
  const [photos, setPhotos] = useState([null, null, null, null, null]);
  const [insurancephotos, setInsurancePhotos] = useState([null, null]);
  const [uploadingIndex, setUploadingIndex] = useState(null);
  const [uploadingInsuranceIndex, setUploadingInsuranceIndex] = useState(null);
  const [location, setLocation] = useState("");
  const [locationErr, setLocationErr] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (locationn?.state?._id) {
      dispatch(seteditTrailer(true));
    } else {
      dispatch(seteditTrailer(false));
    }
  }, [locationn?.state, dispatch]);

  // console.log(
  //   "locationn.state.insurance_photos",
  //   locationn.state.insurance_photos
  // );
  // console.log("insurancephotos >>>>>>>>>>>>>>>", insurancephotos);
  useEffect(() => {
    if (locationn.state) {
      setFormData(locationn.state);

      const trailerPhotos = locationn.state.trailer_photos;
      const insurancePhotos = locationn.state.insurance_photos;

      setPhotos(
        trailerPhotos && trailerPhotos.length > 0
          ? trailerPhotos
          : [null, null, null, null]
      );

      setInsurancePhotos(
        insurancePhotos && insurancePhotos.length > 0
          ? insurancePhotos
          : [null, null]
      );
    }
    // if (locationn.state) {
    //   setFormData(locationn.state);
    //   setPhotos(
    //     locationn.state.trailer_photos || [null, null, null, null, null]
    //   );
    //   setInsurancePhotos(locationn.state.insurance_photos || [null, null]);
    // }
  }, [locationn.state]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateForm = () => {
    let tempErrors = {};

    if (!formData.title) tempErrors.title = "Title is required";
    if (!formData.year) tempErrors.year = "Year is required";
    if (!formData.make) tempErrors.make = "Make is required";
    if (!formData.model) tempErrors.model = "Model is required";
    if (!formData.category) tempErrors.category = "Category is required";
    if (!formData.hitch_type) tempErrors.hitch_type = "Hitch Type is required";
    if (!formData.ball_size) tempErrors.ball_size = "Ball Size is required";
    if (!formData.province_insured)
      tempErrors.province_insured = "Province is required";
    if (!formData.light_plug_configuration)
      tempErrors.light_plug_configuration =
        "Light Plug Configuration is required";
    if (!formData.trailer_deck_length)
      tempErrors.trailer_deck_length = "Trailer Deck Length are required";
    if (!formData.trailer_dimension)
      tempErrors.trailer_dimension = "Trailer Deck Width are required";
    if (!formData.trailer_length)
      tempErrors.trailer_length = "Trailer Length is required";
    if (!formData.trailer_value)
      tempErrors.trailer_value = "Trailer Value is required";
    // if (!formData.trailer_width)
    // tempErrors.trailer_width = "Trailer Width is required";
    if (!formData.rear_door_opening_height)
      tempErrors.rear_door_opening_height =
        "Rear Door Opening Height is required";
    if (!formData.payload_capacity)
      tempErrors.payload_capacity = "Payload Capacity is required";
    if (!formData.axles) tempErrors.axles = "Axles are required";
    if (!formData.refundable_deposit)
      tempErrors.refundable_deposit = "Refundable Deposit is required";
    if (!formData.vin) tempErrors.vin = "VIN is required";
    if (!formData.province_territory)
      tempErrors.province_territory = "Province/Territory is required";
    // if (!formData.city) tempErrors.city = "City is required";
    if (!formData.complete_address)
      tempErrors.complete_address = "Complete Addrress are required";
    if (!formData.daily_rate) tempErrors.daily_rate = "Daily Rate is required";
    if (!formData.weekly_rate)
      tempErrors.weekly_rate = "Weekly Rate is required";
    if (!formData.monthly_rate)
      tempErrors.monthly_rate = "Monthly Rate is required";
    // if (!formData.rental_delay_max)
    //   tempErrors.rental_delay_max = "Rental Delay Max is required";
    // if (!formData.rental_delay_min)
    //   tempErrors.rental_delay_min = "Rental Delay Min is required";
    if (!formData.tag_words) tempErrors.tag_words = "Tag Words are required";
    // if (!location) tempErrors.location = "Location is required";
    if (!formData.location) tempErrors.location = "Location url is required";
    // if (!formData.state) tempErrors.state = "State is required";
    if (!formData.trailer_description)
      tempErrors.trailer_description = "Trailer Description is required";

    setErrors(tempErrors);
    console.log(tempErrors);

    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if at least 4 images are uploaded
    const uploadedPhotos = photos.filter((photo) => photo !== null);
    const uploadedInsurancephotos = insurancephotos.filter(
      (photo) => photo !== null
    );
    if (uploadedPhotos.length < 4) {
      error_toast_message("Please upload at least 4 trailer images.");
      return;
    }
    if (uploadedInsurancephotos.length < 2) {
      error_toast_message("Please upload at least 2 insurance images.");
      return;
    }

    if (validateForm()) {
      const jsonData = { ...formData, ...location };
      jsonData.trailer_photos = uploadedPhotos;
      jsonData.insurance_photos = uploadedInsurancephotos;

      // console.log("insurance img", jsonData);
      dispatch(add_trailer(jsonData));
    }
  };

  const handleDraftSave = () => {
    const uploadedPhotos = photos.filter((photo) => photo !== null);
    const insuranceUploadedPhotos = insurancephotos.filter(
      (photo) => photo !== null
    );
    const jsonData = { ...formData, ...location };
    jsonData.approve_status = "Draft";
    jsonData.trailer_photos = uploadedPhotos;
    jsonData.insurance_photos = insuranceUploadedPhotos;

    // console.log("Draft Data", jsonData);
    if (locationn?.state?.trailer_id) {
      dispatch(
        edit_trailer({ id: locationn?.state?.trailer_id, data: jsonData })
      );
    } else {
      dispatch(add_trailer(jsonData));
    }
  };

  const handleEdit = () => {
    const uploadedPhotos = photos.filter((photo) => photo !== null);
    const uploadedInsurancePhotos = insurancephotos.filter(
      (photo) => photo !== null
    );
    const jsonData = { ...formData, ...location };
    jsonData.insurance_photos = uploadedInsurancePhotos;

    jsonData.approve_status = "Pending";
    jsonData.trailer_photos = uploadedPhotos;

    dispatch(
      edit_trailer({ id: locationn?.state?.trailer_id, data: jsonData })
    );
  };

  const handleInsuranceFileChange = async (index, event) => {
    const file = event.target.files[0];

    if (file) {
      // Check if the file type is an image
      if (!file.type.startsWith("image/")) {
        // alert("Please upload an image file. Documents or other files are not allowed.");
        error_toast_message(
          "Please upload an image file. Documents or other files are not allowed."
        );
        return;
      }

      let token = ""; // Replace with your actual token or token retrieval logic
      try {
        setUploadingInsuranceIndex(index);
        const formData = new FormData();
        formData.append("binary_file", file);

        const response = await axios.post(`${baseURL}upload-files`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        });

        const imageUrl = response.data.url;
        const updatedPhotos = [...insurancephotos];
        updatedPhotos[index] = imageUrl;
        setInsurancePhotos(updatedPhotos);
      } catch (error) {
        setUploadingInsuranceIndex(null);
        console.error("Error uploading image:", error);
      } finally {
        setUploadingInsuranceIndex(null);
      }
    }
  };

  const handleInsuranceDeletePhoto = (index) => {
    const updatedPhotos = [...insurancephotos];
    updatedPhotos[index] = null;
    setInsurancePhotos(updatedPhotos);
  };

  const handleFileChange = async (index, event) => {
    const file = event.target.files[0];

    if (file) {
      // Check if the file type is an image
      if (!file.type.startsWith("image/")) {
        // alert("Please upload an image file. Documents or other files are not allowed.");
        error_toast_message(
          "Please upload an image file. Documents or other files are not allowed."
        );
        return;
      }

      let token = ""; // Replace with your actual token or token retrieval logic
      try {
        setUploadingIndex(index);
        const formData = new FormData();
        formData.append("binary_file", file);

        const response = await axios.post(`${baseURL}upload-files`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        });

        const imageUrl = response.data.url;
        const updatedPhotos = [...photos];
        updatedPhotos[index] = imageUrl;
        setPhotos(updatedPhotos);
      } catch (error) {
        console.error("Error uploading image:", error);
      } finally {
        setUploadingIndex(null);
      }
    }
  };

  const handleDeletePhoto = (index) => {
    const updatedPhotos = [...photos];
    updatedPhotos[index] = null;
    setPhotos(updatedPhotos);
  };

  useEffect(() => {
    if (add_trailer_status === asyncStatus.SUCCEEDED) {
      navigate("/all-trailers");
      dispatch(setAddTrailerStatus());
    }
  }, [add_trailer_status]);

  useEffect(() => {
    if (edit_trailer_status === asyncStatus.SUCCEEDED) {
      navigate("/all-trailers");
      dispatch(setEditTrailerStatus());
    }
  }, [edit_trailer_status]);

  useEffect(() => {
    if (location !== "") {
      setLocationErr("");
    }
  }, [location]);

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <Stack
      maxWidth="800px"
      mx="auto" // Center horizontally
      mt={6} // Margin-top
    >
      <Paper
        elevation={3} // Equivalent to shadow-lg in Tailwind
        sx={{
          padding: 4, // Equivalent to p-8
          backgroundColor: "white",
          borderRadius: 2, // Equivalent to rounded-lg
        }}
      >
        {/* Back Button */}
        <IconButton onClick={handleBackClick} sx={{ mb: 2 }}>
          <ArrowBackIcon />
        </IconButton>
        <Box sx={{ padding: 4 }}>
          <div>
            <Grid container spacing={2}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography className={classes.label}>
                  Title of Trailer
                </Typography>
                <TextField
                  fullWidth
                  className={classes.textField}
                  variant="outlined"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  placeholder="Title of trailer *"
                  error={!!errors.title}
                  helperText={errors.title}
                />
              </Grid>

              <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                <Typography className={classes.label}>Year</Typography>
                <TextField
                  fullWidth
                  className={classes.textField}
                  variant="outlined"
                  name="year"
                  value={formData.year}
                  onChange={handleChange}
                  placeholder="Year *"
                  error={!!errors.year}
                  helperText={errors.year}
                />
              </Grid>

              <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                <Typography className={classes.label}>Make</Typography>
                <TextField
                  fullWidth
                  className={classes.textField}
                  variant="outlined"
                  name="make"
                  value={formData.make}
                  onChange={handleChange}
                  placeholder="Make *"
                  error={!!errors.make}
                  helperText={errors.make}
                />
              </Grid>

              <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                <Typography className={classes.label}>Model</Typography>
                <TextField
                  fullWidth
                  className={classes.textField}
                  variant="outlined"
                  name="model"
                  value={formData.model}
                  onChange={handleChange}
                  placeholder="Model *"
                  error={!!errors.model}
                  helperText={errors.model}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography className={classes.label}>
                  Select Category
                </Typography>

                <FormControl className={classes.formControl}>
                  <Select
                    value={formData.category}
                    onChange={handleChange}
                    name="category"
                    variant="outlined"
                    error={!!errors.category}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      <Typography sx={{ color: "#bfbfbf" }}>
                        Select Category *
                      </Typography>
                    </MenuItem>
                    {categoryOptions.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {!!errors.model && (
                  <Typography
                    sx={{
                      color: "#d32f2f",
                      fontSize: "0.75rem",
                      marginLeft: "14px",
                    }}
                  >
                    {errors.model}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12}>
                <Typography className={classes.label}>Hitch Type</Typography>
                <FormControl className={classes.formControl}>
                  <Select
                    value={formData.hitch_type}
                    onChange={handleChange}
                    name="hitch_type"
                    variant="outlined"
                    error={!!errors.hitch_type}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      <Typography sx={{ color: "#bfbfbf" }}>
                        Select Hitch Type *
                      </Typography>
                    </MenuItem>
                    {hitchTypeOptions.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {!!errors.hitch_type && (
                  <Typography
                    sx={{
                      color: "#d32f2f",
                      fontSize: "0.75rem",
                      marginLeft: "14px",
                    }}
                  >
                    {errors.hitch_type}
                  </Typography>
                )}
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <Typography className={classes.label}>Ball Size</Typography>
                <FormControl className={classes.formControl}>
                  <Select
                    value={formData.ball_size}
                    onChange={handleChange}
                    name="ball_size"
                    variant="outlined"
                    error={!!errors.ball_size}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      <Typography sx={{ color: "#bfbfbf" }}>
                        Select Ball Size *
                      </Typography>
                    </MenuItem>
                    {ballSizeOptions.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {!!errors.ball_size && (
                  <Typography
                    sx={{
                      color: "#d32f2f",
                      fontSize: "0.75rem",
                      marginLeft: "14px",
                    }}
                  >
                    {errors.ball_size}
                  </Typography>
                )}
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <Typography className={classes.label}>
                  Province Insured
                </Typography>
                <FormControl className={classes.formControl}>
                  <Select
                    value={formData.province_insured}
                    onChange={handleChange}
                    name="province_insured"
                    variant="outlined"
                    error={!!errors.province_insured}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      <Typography sx={{ color: "#bfbfbf" }}>
                        Select Province *
                      </Typography>
                    </MenuItem>
                    {provinces.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {!!errors.province_insured && (
                  <Typography
                    sx={{
                      color: "#d32f2f",
                      fontSize: "0.75rem",
                      marginLeft: "14px",
                    }}
                  >
                    {errors.province_insured}
                  </Typography>
                )}
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <HeadingWithTooltip
                  heading="Tag Words *"
                  info="List some keywords that Renters will use to find your trailer. Separate by commas."
                />
                <TextField
                  fullWidth
                  className={classes.textField}
                  variant="outlined"
                  name="tag_words"
                  value={formData.tag_words}
                  onChange={handleChange}
                  placeholder="Tag words *"
                  error={!!errors.tag_words}
                  helperText={errors.tag_words}
                />
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <Typography className={classes.label}>
                  Light Plug Configuration
                </Typography>
                <FormControl className={classes.formControl}>
                  <Select
                    value={formData.light_plug_configuration}
                    onChange={handleChange}
                    name="light_plug_configuration"
                    variant="outlined"
                    error={!!errors.light_plug_configuration}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      <Typography sx={{ color: "#bfbfbf" }}>
                        Select Light Plug Configuration *
                      </Typography>
                    </MenuItem>
                    {lightPlugOptions.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {!!errors.light_plug_configuration && (
                  <Typography
                    sx={{
                      color: "#d32f2f",
                      fontSize: "0.75rem",
                      marginLeft: "14px",
                    }}
                  >
                    {errors.light_plug_configuration}
                  </Typography>
                )}
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <Typography className={classes.label}>
                  Trailer Deck Width
                </Typography>
                <TextField
                  fullWidth
                  className={classes.textField}
                  variant="outlined"
                  name="trailer_dimension"
                  value={formData.trailer_dimension}
                  onChange={handleChange}
                  placeholder="Dimensions *"
                  error={!!errors.trailer_dimension}
                  helperText={errors.trailer_dimension}
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <Typography className={classes.label}>
                  Trailer Deck Length
                </Typography>
                <TextField
                  fullWidth
                  className={classes.textField}
                  variant="outlined"
                  name="trailer_deck_length"
                  value={formData.trailer_deck_length}
                  onChange={handleChange}
                  placeholder="Dimensions *"
                  error={!!errors.trailer_deck_length}
                  helperText={errors.trailer_deck_length}
                />
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <Typography className={classes.label}>
                  Rear Door Opening Height
                </Typography>
                <TextField
                  fullWidth
                  className={classes.textField}
                  variant="outlined"
                  name="rear_door_opening_height"
                  value={formData.rear_door_opening_height}
                  onChange={handleChange}
                  placeholder="17 *"
                  error={!!errors.rear_door_opening_height}
                  helperText={errors.rear_door_opening_height}
                />
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <Typography className={classes.label}>
                  Payload Capacity
                </Typography>
                <TextField
                  fullWidth
                  className={classes.textField}
                  variant="outlined"
                  name="payload_capacity"
                  value={formData.payload_capacity}
                  onChange={handleChange}
                  placeholder="Payload Capacity *"
                  error={!!errors.payload_capacity}
                  helperText={errors.payload_capacity}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography className={classes.label}>Axles</Typography>
                <FormControl className={classes.formControl}>
                  <Select
                    value={formData.axles}
                    onChange={handleChange}
                    name="axles"
                    variant="outlined"
                    error={!!errors.axles}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      {/* Select Axles */}
                      <Typography sx={{ color: "#bfbfbf" }}>
                        Select Axle *
                      </Typography>
                    </MenuItem>

                    {axleOptions.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {!!errors.axles && (
                  <Typography
                    sx={{
                      color: "#d32f2f",
                      fontSize: "0.75rem",
                      marginLeft: "14px",
                    }}
                  >
                    {errors.axles}
                  </Typography>
                )}
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <HeadingWithTooltip
                  heading="Daily Rate"
                  info="You will receive this amount minus booking  fees after you mark the rental complete."
                />

                <TextField
                  fullWidth
                  className={classes.textField}
                  variant="outlined"
                  name="daily_rate"
                  value={formData.daily_rate}
                  onChange={handleChange}
                  placeholder="Daily rate *"
                  error={!!errors.daily_rate}
                  helperText={errors.daily_rate}
                />
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <HeadingWithTooltip
                  heading="Weekly Rate"
                  info="You will receive this amount minus booking  fees after you mark the rental complete. To encourage the weekly rental, we recommend offering a slight discount. Example: Renter pays for 5 days and can keep for 7. Calculate a 5 day rental price and enter it here."
                />

                <TextField
                  fullWidth
                  className={classes.textField}
                  variant="outlined"
                  name="weekly_rate"
                  value={formData.weekly_rate}
                  onChange={handleChange}
                  placeholder="Weekly rate *"
                  error={!!errors.weekly_rate}
                  helperText={errors.weekly_rate}
                />
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <HeadingWithTooltip
                  heading="Monthly Rate"
                  info="You will receive this amount minus booking fees after you mark the rental complete.  To encourage the Monthly Rate, we recommend offering a discount.  Example:  Calculate your Daily Rate x 30. Then, discount that number by 50% to arrive at your Monthly Rate."
                />

                <TextField
                  fullWidth
                  className={classes.textField}
                  variant="outlined"
                  name="monthly_rate"
                  value={formData.monthly_rate}
                  onChange={handleChange}
                  placeholder="Monthly rate *"
                  error={!!errors.monthly_rate}
                  helperText={errors.monthly_rate}
                />
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <Typography className={classes.label}>
                  Refundable Deposit
                </Typography>
                <TextField
                  fullWidth
                  className={classes.textField}
                  variant="outlined"
                  name="refundable_deposit"
                  value={formData.refundable_deposit}
                  onChange={handleChange}
                  placeholder="Refundable Deposit *"
                  error={!!errors.refundable_deposit}
                  helperText={errors.refundable_deposit}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography fontWeight={600} fontSize={20}>
                  Insurance Eligibility
                </Typography>
                <Typography className={classes.label}>
                  (This information is required to determine insurance
                  eligibility.)
                </Typography>
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <HeadingWithTooltip
                  heading="Trailer Length *"
                  info="Overall length of trailer"
                />

                <TextField
                  fullWidth
                  className={classes.textField}
                  variant="outlined"
                  name="trailer_length"
                  value={formData.trailer_length}
                  onChange={handleChange}
                  placeholder="Length *"
                  error={!!errors.trailer_length}
                  helperText={errors.trailer_length}
                />
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <HeadingWithTooltip
                  heading="Trailer Value *"
                  info="Approximate replacement value"
                />
                <TextField
                  fullWidth
                  className={classes.textField}
                  variant="outlined"
                  name="trailer_value"
                  value={formData.trailer_value}
                  onChange={handleChange}
                  placeholder="Value *"
                  error={!!errors.trailer_value}
                  helperText={errors.trailer_value}
                />
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <HeadingWithTooltip heading="VIN *" info="Complete VIN" />
                <TextField
                  fullWidth
                  className={classes.textField}
                  variant="outlined"
                  name="vin"
                  value={formData.vin}
                  onChange={handleChange}
                  placeholder="VIN *"
                  error={!!errors.vin}
                  helperText={errors.vin}
                />
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <Typography className={classes.label}>
                  {" "}
                  Province/Territory
                </Typography>
                <FormControl className={classes.formControl}>
                  <Select
                    value={formData.province_territory}
                    onChange={handleChange}
                    name="province_territory"
                    variant="outlined"
                    error={!!errors.province_territory}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      <Typography sx={{ color: "black" }}>
                        Select Province *
                      </Typography>
                    </MenuItem>
                    {statesOptions.map(({ value, label }, index) => (
                      <MenuItem key={index} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {!!errors.state && (
                  <Typography
                    sx={{
                      color: "#d32f2f",
                      fontSize: "0.75rem",
                      marginLeft: "14px",
                    }}
                  >
                    {errors.state}
                  </Typography>
                )}
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <Typography className={classes.label}>City</Typography>
                <TextField
                  fullWidth
                  className={classes.textField}
                  variant="outlined"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  placeholder="City *"
                  error={!!errors.city}
                  helperText={errors.city}
                />
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <Typography className={classes.label}>Postal Code</Typography>
                <TextField
                  fullWidth
                  className={classes.textField}
                  variant="outlined"
                  name="postal_code"
                  value={formData.postal_code}
                  onChange={handleChange}
                  placeholder="Postal Code *"
                  error={!!errors.postal_code}
                  helperText={errors.postal_code}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography className={classes.label}>
                  Location URL{" "}
                  <span style={{ fontSize: "12px", fontWeight: "400" }}>
                    (Google Map URL)
                  </span>
                </Typography>
                <TextField
                  type="url"
                  fullWidth
                  className={classes.textField}
                  variant="outlined"
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                  placeholder="Location URL *"
                  error={!!errors.location}
                  helperText={errors.location}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography className={classes.label}>
                  Complete Address{" "}
                  <span style={{ fontSize: "12px", fontWeight: "400" }}>
                    (Enter your full address. Only your city and Province will
                    be publicly visible)
                  </span>
                </Typography>

                <TextField
                  fullWidth
                  className={classes.textField}
                  variant="outlined"
                  name="complete_address"
                  value={formData.complete_address}
                  onChange={handleChange}
                  placeholder="Complete Address *"
                  error={!!errors.complete_address}
                  helperText={errors.complete_address}
                />
              </Grid>

              <Grid item xs={12}>
                <HeadingWithTooltip
                  heading={
                    <>
                      <Typography className={classes.label}>
                        Trailer Description *{" "}
                        <span style={{ fontSize: "12px", fontWeight: "400" }}>
                          (Use of email, phone number, and website URL is
                          Prohibited)
                        </span>
                      </Typography>
                    </>
                  }
                  info={`Here is your chance to "sell" your trailer! List its condition, mention the maintenance that's been done, mention any extras like "ball mount, the ratchet straps, the spare wheel that come with...!"  Anything else that will make the potential renter want to rent YOUR trailer!`}
                />
                <textarea
                  type="text"
                  name="trailer_description"
                  value={formData.trailer_description}
                  onChange={handleChange}
                  placeholder="Enter a Trailer Description *"
                  className="trailer_description_inp"
                  style={{
                    border: `1px solid ${
                      !!errors.trailer_description ? "red" : "lightgrey"
                    }`,
                    borderRadius: "4px",
                    fontSize: "1rem",
                    fontWeight: "400",
                    padding: "16.5px 14px",
                    resize: "none",
                    width: "100%",
                    color: "rgba(0, 0, 0, 0.6)",
                  }}
                />
                {!!errors.trailer_description && (
                  <Typography
                    sx={{
                      color: "#d32f2f",
                      fontSize: "0.75rem",
                      marginLeft: "14px",
                    }}
                  >
                    {errors.trailer_description}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} md={12}>
                <Stack sx={{ height: "100%" }}>
                  <Typography className={classes.label}>
                    Insurance Photos *{" "}
                  </Typography>
                  <Typography className={classes.label}>
                    Drop files here or (Browse Files)
                  </Typography>
                  <Typography className={classes.label}>
                    For best results, use JPG, GIF or PNG images that are
                    660x440 pixels
                  </Typography>
                  <Grid container spacing={2} alignItems={"stretch"} mt={1}>
                    {insurancephotos?.map((photo, index) => (
                      <Grid
                        item
                        xs={12}
                        md={2}
                        lg={2}
                        xl={2}
                        sm={6}
                        // my={3}
                        key={index}
                      >
                        <Stack sx={{ height: "100%", width: "100%" }}>
                          <div className={classes.photoContainer}>
                            {uploadingInsuranceIndex === index && (
                              <div className={classes.loadingOverlay}>
                                <CircularProgress color="error" />
                              </div>
                            )}

                            {photo ? (
                              <>
                                <img
                                  src={photo}
                                  alt={`Trailer ${index}`}
                                  className="photo"
                                  style={{ borderRadius: "10px" }}
                                />
                                <div
                                  className={classes.deleteButton}
                                  onClick={() =>
                                    handleInsuranceDeletePhoto(index)
                                  }
                                >
                                  <MdClose />
                                </div>
                              </>
                            ) : (
                              <label className="photo-placeholder">
                                <input
                                  type="file"
                                  accept="image/*" // Restrict to image files only
                                  onChange={(event) =>
                                    handleInsuranceFileChange(index, event)
                                  }
                                  style={{ display: "none" }}
                                />
                                <span className="add-photo-icon">
                                  <img width={"100%"} src={bgIcon} alt="Add" />
                                </span>
                              </label>
                            )}
                          </div>
                        </Stack>
                      </Grid>
                    ))}
                  </Grid>
                </Stack>
              </Grid>

              <Grid item xs={12} md={12}>
                <Stack sx={{ height: "100%" }}>
                  <Typography className={classes.label}>
                    Trailer Photos *{" "}
                    <span style={{ fontSize: "12px", fontWeight: "400" }}>
                      {" "}
                      The display of a phone number, website URL or email in
                      uploaded images is Prohibited.
                    </span>
                  </Typography>
                  <Typography className={classes.label}>
                    Drop files here or (Browse Files)
                  </Typography>
                  <Typography className={classes.label}>
                    For best results, use JPG, GIF or PNG images that are
                    660x440 pixels
                  </Typography>
                  <Grid container spacing={2} alignItems={"stretch"} mt={1}>
                    {photos.map((photo, index) => (
                      <Grid
                        item
                        xs={12}
                        md={2}
                        lg={2}
                        xl={2}
                        sm={6}
                        // my={3}
                        key={index}
                      >
                        <Stack sx={{ height: "100%", width: "100%" }}>
                          <div className={classes.photoContainer}>
                            {uploadingIndex === index && (
                              <div className={classes.loadingOverlay}>
                                <CircularProgress color="error" />
                              </div>
                            )}

                            {photo ? (
                              <>
                                <img
                                  src={photo}
                                  alt={`Trailer ${index}`}
                                  className="photo"
                                  style={{ borderRadius: "10px" }}
                                />
                                <div
                                  className={classes.deleteButton}
                                  onClick={() => handleDeletePhoto(index)}
                                >
                                  <MdClose />
                                </div>
                              </>
                            ) : (
                              <label className="photo-placeholder">
                                <input
                                  type="file"
                                  accept="image/*" // Restrict to image files only
                                  onChange={(event) =>
                                    handleFileChange(index, event)
                                  }
                                  style={{ display: "none" }}
                                />
                                <span className="add-photo-icon">
                                  <img width={"100%"} src={bgIcon} alt="Add" />
                                </span>
                              </label>
                            )}
                          </div>
                        </Stack>
                      </Grid>
                    ))}
                  </Grid>
                </Stack>
              </Grid>

              <Grid item xs={12} mt={2}>
                <div className="buttons">
                  <CustomButton
                    onClick={
                      locationn?.state?.trailer_id ? handleEdit : handleSubmit
                    }
                    disable={addTrailerLoader}
                    style={{
                      backgroundColor: themeRed,
                      color: "white",
                    }}
                    type="submit"
                  >
                    {addTrailerLoader ||
                    edit_trailer_status === asyncStatus.LOADING ? (
                      <CircularProgress size={20} sx={{ color: "white" }} />
                    ) : locationn?.state?.trailer_id ? (
                      "Submit"
                    ) : (
                      "Submit for Review"
                    )}
                  </CustomButton>

                  {!locationn.state?._id && (
                    <CustomButton
                      disable={
                        addTrailerLoader ||
                        edit_trailer_status === asyncStatus.LOADING
                      }
                      style={{
                        backgroundColor: themeRed,
                        color: "white",
                      }}
                      onClick={handleDraftSave}
                    >
                      {addTrailerLoader ||
                      edit_trailer_status === asyncStatus.LOADING ? (
                        <CircularProgress size={20} sx={{ color: "white" }} />
                      ) : (
                        "Save as Draft"
                      )}
                    </CustomButton>
                  )}
                  <CustomButton
                    disable={addTrailerLoader}
                    style={{
                      backgroundColor: themeRed,
                      color: "white",
                    }}
                    onClick={() => navigate("/all-trailers")}
                  >
                    Cancel
                  </CustomButton>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Typography my={2} className={classes.label}>
                  *All listings on Trailer4Rent will be manually reviewed before
                  publishing. Once your listing has been approved, you will be
                  notified by email and it will be visible on our site.
                </Typography>
                <Typography my={2} className={classes.label}>
                  *20% of platform charges will charge upon booking. This flat
                  fee covers licensing, site design, processing fees and
                  insurances
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Box>
      </Paper>
      <ToastContainer />
    </Stack>
  );
};

export default TrailerAdd;
