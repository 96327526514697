// import React from 'react'

// const HowItsWork = () => {
//   return (
//     <div>HowItsWork</div>
//   )
// }

// export default HowItsWork

import { useNavigate } from "react-router-dom";
// import Navbar from "../component/common/navbar/NavBar";
// import "../index.css";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import "../../pages/home.css";
import ProvinceCarousel from "../../component/UserProvinceSlider";
import TestimonialSlider from "../../component/TestimonialSlider";
import SecondSectionSlider from "../../component/SecondSectionSlider";
import UniqueReviewSlider from "../AboutUsSection/AboutUsSection";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Navbar from "../../component/navbar/Navbar";
import Footer from "../Footer/Footer";
import secondSec from "../../assets/secondSec.png";
import SearchBar from "../../component/SearchBarComponent";
import LocationSearch from "../../component/LocationSearch/LocationSearch";
import HeroSection from "../../pages/HomeSections/HeroSection/HeroSection";
import ProvinceCarouselSection from "../../pages/HomeSections/ProvinceCarouselSection/ProvinceCarouselSection";
import FaqsSection from "../../pages/HomeSections/FaqsSection/FaqsSection";
import CategoriesSection, {
  trailerCategories,
} from "../../pages/HomeSections/CategorieSection/CategoriesSection";
import { themeRed } from "../../utils/colorTheme";
import CustomButton from "../../component/common/Button/Button";

function HowItsWork() {
  const navigate = useNavigate();
  const handleSearch = (location, fromDate, untilDate) => {
    console.log("Search initiated with:", location, fromDate, untilDate);
    // Add your logic here to perform the search or API call
  };

  const faqsData = [
    {
      question:
        "How do I know my bank account and other sensitive information is safe and secure?",
      upper_description: (
        <>
          In order to provide a safe, secure environment for your financial
          information and other sensitive data, Trailer4Rent.ca proudly utilizes
          TD Merchant Services. TD Merchant Services has the highest ratings in
          all of North America towards cybersecurity and data protection.
          Companies on platforms such as Shopify, WooCommerce, and Magento may
          choose to have TD Merchant Services as their e-commerce solution. For
          more information, visit their Privacy Center at:
          <br />
          <br />
          We will ask for the following information, so you’re welcome to begin
          gathering it:
        </>
      ),
      lower_description: (
        <>
          Don’t worry, we will walk you through all the details, and get your
          trailers ready to be rented!
        </>
      ),
      content: [
        {
          title: "",
          point: <>1. A detailed description to help it stand out</>,
        },
        {
          title: "",
          point: (
            <>2. Trailer VIN and License plate number / State (or photo)</>
          ),
        },
        {
          title: "",
          point: <>3. Trailer address (where you guest will pick it up)</>,
        },
        {
          title: "",
          point: <>4. Trailer weight and max payload</>,
        },
        {
          title: "",
          point: (
            <>
              5. At least 6 photos of the trailer (see “Do you have any tips…”
              question in FAQ for suggestions)
            </>
          ),
        },
        {
          title: "",
          point: <>6. Pricing Details</>,
        },
        {
          title: "",
          point: <>7. Host Delivery (Opt in or out)</>,
        },
      ],
    },
    {
      question: "What do I need to do to rent a trailer on Trailer4Rent?",
      upper_description: (
        <>To book a trailer on trailer4rent.ca, you will need the following:</>
      ),
      lower_description: "",
      content: [
        {
          title: "1. Account Creation: ",
          point: (
            <>
              Sign up or log into your account on our site to access available
              trailers for rent.
            </>
          ),
        },
        {
          title: "2. Valid Driver's License: ",
          point: (
            <>
              A valid driver’s license is required for identification and to
              ensure you can legally tow the trailer.
            </>
          ),
        },
        {
          title: "3. Payment Method: ",
          point: (
            <>
              A credit card or another accepted payment method to secure your
              booking and cover any potential deposits or fees.
            </>
          ),
        },
        {
          title: "4. Rental Agreement: ",
          point: (
            <>
              Review and agree to the rental terms and conditions, including the
              responsibilities associated with renting the trailer.
            </>
          ),
        },
        {
          title:
            "5. Insurance or Class/Restriction to renters licence (if required): ",
          point: (
            <>
              Depending on the GVW of trailer and the rental terms set out by
              that particular trailer host, you may need additional Class of
              licence or proof of insurance for the trailer during your rental
              period. *Applies to some Specialty trailers or trailers with GVW
              over 10,990lb.
            </>
          ),
        },
        {
          title:
            "6. You must only rent trailer that is insured with same Provincial policy as your truck’s insurance has. ",
          point: (
            <>
              Example: Alberta-insured truck? Look for Alberta-insured trailers
              only.{" "}
            </>
          ),
        },
      ],
    },
    {
      question: "Do I need my own insurance?",
      upper_description: (
        <>
          Each renter must have valid insurance policy on their own tow vehicle.
          As well, if the trailer you wish to rent and/or the payload exceeds
          what your truck policy is rated to carry, you must increase (at your
          expense) the payload capacity amount on your insurance policy prior to
          renting. Trailer4Rent Inc. nor the trailer host will be held
          responsible if your tow vehicle is not properly maintained or
          sufficiently insured to pull the trailer and load.{" "}
        </>
      ),
      lower_description: (
        <>
          Each trailer host must carry valid insurance policies on the
          trailer(s) that they post on our platform.{" "}
        </>
      ),
      content: [],
    },
    {
      question: "Can other people tow a trailer that I booked?",
      upper_description: (
        <>
          Yes, someone else can tow the trailer you booked on trailer4rent.ca,
          but there are a few requirements:
        </>
      ),
      lower_description: (
        <>
          Adding an approved driver to your booking helps ensure that everyone
          involved understands the responsibilities and conditions of the
          rental. However, know that YOU as the primary “Renter” will be held
          100% responsible for any negligence, damage, or theft of the rented
          trailer. Your payment method as well as deposit may be utilized to
          cover damages. <br /> If you need assistance adding someone to your
          booking, feel free to contact us!
        </>
      ),
      content: [
        {
          title: `1. Driver Approval: `,
          point: (
            <>
              The person towing the trailer MUST be added to your booking as an
              approved driver. This will involve providing their driver’s
              license information for verification.
            </>
          ),
        },
        {
          title: `2. Driver’s License and Insurance: `,
          point: (
            <>
              The individual must have a valid driver’s license and, if
              required, provide proof of insurance that covers them while towing
              the trailer.
            </>
          ),
        },
        {
          title: `3. Agreement to Terms: `,
          point: (
            <>
              The additional driver must also review and agree to our Rental
              Terms and Conditions.
            </>
          ),
        },
      ],
    },
    {
      question: "What is the cancellation policy? ",
      upper_description: (
        <>The cancellation policy for Trailer4Rent is as follows:</>
      ),
      lower_description: (
        <>
          For more details or to discuss special circumstances, feel free to
          reach out to our support team directly.
        </>
      ),
      content: [
        {
          title: `1. Free Cancellation (up to 24 hours before pick-up): `,
          point: (
            <>
              You can cancel your booking up to 24 hours before your scheduled
              pick-up time for a full refund.
            </>
          ),
        },
        {
          title: `2. Late Cancellation (within 24 hours of pick-up): `,
          point: (
            <>
              If you cancel within 24 hours of your pick-up time, your booking
              will incur a cancellation fee payable to the Host (minus
              Trailer4Rent.ca fee), and you may only receive a partial refund.
            </>
          ),
        },
        {
          title: `3. No-Show: `,
          point: (
            <>
              If you do not show up at the scheduled pick-up time and do not
              contact us to adjust your booking, the rental fee may be
              non-refundable.
            </>
          ),
        },
        {
          title: `4. The earlier you communicate a change in plans to your rental, the higher chance of you receiving a favourable outcome!  `,
          point: (
            <>
              We understand that things can happen, plans change. Your host may
              be able to work with you on postponing your rental at their
              discretion. Reach out quickly to your host directly.
            </>
          ),
        },
      ],
    },

    {
      question:
        "We’re working on providing intuitive, supplemental insurance options for both trailer Owners (Hosts) and Renters. Here are some options currently available?",
      upper_description: (
        <>
          At this time, we are not offering the option for trailer to be
          delivered to Renter. Therefore, Renter and Renter’s vehicle MUST
          comply with our (Term-conditions for Renters) and pick up trailer from
          Host’s designated location.{" "}
        </>
      ),
      lower_description: (
        <>
          Our team will support you through the process, helping to ensure a
          smooth resolution.
        </>
      ),
      content: [
        {
          title: ``,
          point: (
            <>
              1. Important! As defined in Section : Specific Terms For Guests
              (https:// ), the Renter bears full financial responsibility for
              any physical damage or loss to the trailer while they are renting
              it. If this occurs, either party can initiate a dispute via the
              Trailer4Rent website in the event they are unable to come to a
              mutual agreement.
            </>
          ),
        },
        {
          title: ``,
          point: (
            <>
              2. Almost without exception, the towing vehicle’s insurance covers
              the liability costs when the trailer causes 3rd party property
              damage and/or bodily injury, but does not cover the contents being
              hauled or damage to the trailer. You as the Renter MUST confirm
              that the auto insurance on your towing vehicle is current and
              satisfactory, and verify that with the Host
            </>
          ),
        },
      ],
    },
    {
      question: "Can I get the trailer delivered to me? ",
      upper_description: (
        <>
          At this time, we are not offering the option for trailer to be
          delivered to Renter. Therefore, Renter and Renter’s vehicle MUST
          comply with our (Term-conditions for Renters) and pick up trailer from
          Host’s designated location.{" "}
        </>
      ),
      lower_description: "",
      content: [],
    },
    {
      question: "How do I get discounts when booking a trailer?",
      upper_description: (
        <>
          Good question! Everyone loves a discount! Trailer4Rent has a referral
          program! Check details here:{" "}
        </>
      ),
      lower_description: "",
      content: [],
    },
    {
      question: "Booking fees?",
      upper_description: (
        <>
          The good news is that there are no fees charged to the Renter from
          Trailer4Rent directly. Creating a Rental profile is free.
        </>
      ),
      lower_description: (
        <>
          As a good Renter, you should have sufficient insurance coverage, opt
          in to additional insurance for your trip and keep your vehicle in good
          mechanical condition. The Host will retain a refundable deposit via
          our site with the optimistic expectation to refund the deposit in it’s
          entirety back to you. Provided you take care of the rental unit.{" "}
          <br />
          Trailer4Rent.ca functions as a traditional marketplace. Currently,
          hosts retain 80% of their rental rate, with Trailer4Rent receiving the
          remaining 20%. Hosts set their own daily rental rate. Feel good in the
          fact that your rental fee is going to help a local in your community
          pay for their family’s needs and not going to a large corporation to
          increase their “bottom line”. Support local!
        </>
      ),
      content: [],
    },
    {
      question: "What happens if I have an accident?",
      upper_description: (
        <>
          If you have an accident while renting a trailer from Trailer4Rent,
          here’s what you should do:
        </>
      ),
      lower_description: <></>,
      content: [
        {
          title: `1. Ensure Safety First: `,
          point: (
            <>
              Make sure everyone is safe. If necessary, call emergency services.
            </>
          ),
        },
        {
          title: `2. Contact the Police: `,
          point: (
            <>
              Report the accident to the local authorities, especially if
              there’s property damage, injury, or if other vehicles are
              involved.
            </>
          ),
        },
        {
          title: `3. Document the Accident: `,
          point: (
            <>
              Take photos of the accident scene, including the trailer and any
              other vehicles involved, and note any damages.
            </>
          ),
        },
        {
          title: `4. Notify Trailer4Rent: `,
          point: (
            <>
              Contact Trailer4Rent as soon as possible to report the accident.
              We’ll guide you through the next steps, including the claims
              process and any repair arrangements.
            </>
          ),
        },
        {
          title: `5. Insurance: `,
          point: (
            <>
              Depending on the insurance coverage you chose during booking,
              we’ll work with you to determine what costs or deductibles may
              apply.
            </>
          ),
        },
        {
          title: `6. Repair Costs: `,
          point: (
            <>
              If you’re responsible for the accident, you may be liable for
              repair costs up to the deductible amount specified in the rental
              agreement or insurance terms.
            </>
          ),
        },
      ],
    },
  ];

  return (
    <div style={{ backgroundColor: "white" }}>
      <Navbar />
      {/* First Section */}
      {/* <Stack className="mainBg">
                <Stack alignItems={"center"} justifyContent={"center"} sx={{ marginTop: { xs: "95px", sm: "95px", md: "140px", lg: "140px", xl: "140px" } }}>
                    <Stack mt={20}>
                        <Stack>
                            <LocationSearch padding={1} />
                        </Stack>
                    </Stack>
                </Stack>
            </Stack> */}
      <HeroSection />
      {/* First Section */}

      {/* Second Section */}
      <Stack>
        <Container maxWidth="lg">
          <Stack alignItems={"center"} mb={5}>
            <Stack
              maxWidth={"1000px"}
              pt={{
                xl: "100px",
                lg: "100px",
                md: "70px",
                sm: "70px",
                xs: "70px",
              }}
            >
              <Typography
                textAlign={"center"}
                fontWeight={"700"}
                sx={{
                  fontFamily: "Book Antiqua, serif !important",
                  fontSize: {
                    xl: "60px",
                    lg: "50px",
                    md: "45px",
                    sm: "40px",
                    xs: "30px",
                  },
                  lineHeight: "normal",
                  zIndex: "1000",
                }}
              >
                {/* Explore Canada's <span className="highlight-text">"Go-to"</span> trailer
              sharing Marketplace */}
                How Trailer4Rent works
              </Typography>
              <Box
                sx={{
                  borderBottom: {
                    xl: `25px solid ${themeRed}`,
                    lg: `20px solid ${themeRed}`,
                    md: `20px solid ${themeRed}`,
                    sm: `17px solid ${themeRed}`,
                    xs: `15px solid ${themeRed}`,
                  },
                  outline: "none",
                  color: "#99000B26",
                  marginTop: {
                    xl: "-23px",
                    lg: "-21px",
                    md: "-21px",
                    sm: "-17px",
                    xs: "-13px",
                  },
                }}
              ></Box>
            </Stack>
            <Stack maxWidth={"1200px"} mt={5}>
              <Grid container alignItems={"stretch"}>
                <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Stack
                    direction={"row"}
                    gap={3}
                    my={4}
                    // sx={{ backgroundColor: "red" }}
                  >
                    {/* <Stack border={"1px solid black"} borderRadius={"20px"} px={"2px"}> */}
                    <Typography
                      fontWeight={"700"}
                      sx={{
                        fontSize: {
                          xs: "20px",
                          sm: "20px",
                          md: "24px",
                          lg: "24px",
                          xl: "24px",
                        },
                      }}
                    >
                      1
                    </Typography>
                    {/* </Stack> */}
                    <Stack>
                      <Typography
                        fontWeight={"700"}
                        sx={{
                          fontSize: {
                            xs: "20px",
                            sm: "20px",
                            md: "24px",
                            lg: "24px",
                            xl: "24px",
                          },
                        }}
                      >
                        Browse through trailers in your area.
                      </Typography>
                      {/* <Typography>
                        Content will be provided by the client. Content will be
                        provided by the client.
                      </Typography> */}
                    </Stack>
                  </Stack>
                  <Stack direction={"row"} gap={3} my={4}>
                    <Typography
                      fontWeight={"700"}
                      sx={{
                        fontSize: {
                          xs: "20px",
                          sm: "20px",
                          md: "24px",
                          lg: "24px",
                          xl: "24px",
                        },
                      }}
                    >
                      2
                    </Typography>
                    <Stack>
                      <Typography
                        fontWeight={"700"}
                        sx={{
                          fontSize: {
                            xs: "20px",
                            sm: "20px",
                            md: "24px",
                            lg: "24px",
                            xl: "24px",
                          },
                        }}
                      >
                        Book the trailer that's right for your job.
                      </Typography>
                      {/* <Typography>
                        Content will be provided by the client. Content will be
                        provided by the client.
                      </Typography> */}
                    </Stack>
                  </Stack>
                  <Stack direction={"row"} gap={3} my={4}>
                    <Typography
                      fontWeight={"700"}
                      sx={{
                        fontSize: {
                          xs: "20px",
                          sm: "20px",
                          md: "24px",
                          lg: "24px",
                          xl: "24px",
                        },
                      }}
                    >
                      3
                    </Typography>
                    <Stack>
                      <Typography
                        fontWeight={"700"}
                        sx={{
                          fontSize: {
                            xs: "20px",
                            sm: "20px",
                            md: "24px",
                            lg: "24px",
                            xl: "24px",
                          },
                        }}
                      >
                        Pick up trailer directly from Host.
                      </Typography>
                      {/* <Typography>
                        Content will be provided by the client. Content will be
                        provided by the client.
                      </Typography> */}
                    </Stack>
                  </Stack>
                  <Stack my={4}>
                    <CustomButton
                      onClick={() => navigate("/dashboard-home")}
                      style={{
                        backgroundColor: themeRed,
                        color: "white",
                        marginTop: "10px",
                      }}
                      children={"GET STARTED!"}
                    />
                  </Stack>
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Stack
                    alignItems={"center"}
                    justifyContent={"center"}
                    sx={{ height: "100%" }}
                  >
                    <img
                      width={"100%"}
                      src="https://res.cloudinary.com/dnptzwqt1/image/upload/v1736363091/Ben_Mc_trailer_uz45ol.png"
                      style={{ borderRadius: "20px" }}
                      alt="rent_trailer"
                    />
                    {/* // src="https://res.cloudinary.com/dpfni06vw/image/upload/v1733267764/Rectangle_53_1_r9tp3a_csewgj.webp" */}
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
            {/* <Stack my={5}>
              <img
                width={"200px"}
                src="https://res.cloudinary.com/dpfni06vw/image/upload/v1726186851/Group_36461_rlaypg.png"
              />
            </Stack> */}
          </Stack>
        </Container>

        {/* <Stack alignItems={"center"}>
          <SecondSectionSlider />
        </Stack> */}
        <Container maxWidth={"lg"}>
          <Stack alignItems={"center"}>
            <SecondSectionSlider trailersData={trailerCategories} />
          </Stack>
        </Container>

        <Stack alignItems={"center"} className="howItsWork">
          <Stack mt={5}>
            <Container maxWidth={"lg"}>
              <Grid container alignItems={"self-start"}>
                <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Stack direction={"row"} gap={3}>
                    {/* <Stack border={"1px solid black"} borderRadius={"20px"} px={"2px"}> */}
                    {/* <Typography fontWeight={"700"} sx={{ fontSize: { xs: "20px", sm: "20px", md: "24px", lg: "24px", xl: "24px" }, }}>1</Typography> */}
                    {/* </Stack> */}
                    <Stack>
                      <Typography
                        fontWeight={"700"}
                        sx={{
                          fontSize: {
                            xl: "50px",
                            lg: "40px",
                            md: "30px",
                            sm: "28px",
                            xs: "25px",
                          },
                          lineHeight: "normal",
                        }}
                      >
                        Why Trailer4Rent?
                      </Typography>
                      <Typography fontWeight={"700"}>
                        {/* Skip the rental counter. */}
                        Why Use Our Peer-to-Peer Trailer Rental Platform?
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack gap={3} my={4}>
                    <Typography
                      fontWeight={"700"}
                      sx={{
                        fontSize: {
                          xs: "20px",
                          sm: "20px",
                          md: "24px",
                          lg: "24px",
                          xl: "24px",
                        },
                      }}
                    >
                      {/* More bang for your buck */}
                      Different task? Use a different trailer
                    </Typography>
                    <Stack>
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "12px",
                            sm: "12px",
                            md: "16px",
                            lg: "16px",
                            xl: "16px",
                          },
                        }}
                      >
                        Sometimes you own a trailer but need a different type
                        for a specific job. Not all trailers are suited for
                        every task. Whether it’s hauling construction materials,
                        moving livestock, or transporting a vehicle, our
                        platform connects you to the perfect trailer for the
                        job.
                        {/* Content will be provided by the client. Content will be
                        provided by the client. Content will be provided by the
                        client. Content will be provided by the client. Content
                        will be provided by the client. Content will be provided
                        by the client. Content will be provided by the client. */}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack gap={3} my={4}>
                    <Typography
                      fontWeight={"700"}
                      sx={{
                        fontSize: {
                          xs: "20px",
                          sm: "20px",
                          md: "24px",
                          lg: "24px",
                          xl: "24px",
                        },
                        lineHeight: "normal",
                      }}
                    >
                      {/* Free cancellation */}
                      Save time and money by renting exactly what you need, when
                      you need it, without committing to a purchase
                    </Typography>
                    <Stack>
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "12px",
                            sm: "12px",
                            md: "16px",
                            lg: "16px",
                            xl: "16px",
                          },
                        }}
                      >
                        You need a trailer but don’t have the space or money to
                        buy one. Owning a trailer comes with high upfront costs
                        and the hassle of storage. Renting through Trailer4Rent
                        lets you avoid those expenses while gaining access to a
                        variety of trailers. Flexible rental options mean you
                        only pay for what you use, keeping your budget intact.
                        {/* Content will be provided by the client. Content will be
                        provided by the client. Content will be provided by the
                        client. Content will be provided by the client. */}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack gap={3} my={4}>
                    <Typography
                      fontWeight={"700"}
                      sx={{
                        fontSize: {
                          xs: "20px",
                          sm: "20px",
                          md: "24px",
                          lg: "24px",
                          xl: "24px",
                        },
                        lineHeight: "normal",
                      }}
                    >
                      {/* Convenient delivery options */}
                      You just need to move something once and need a trailer
                      for that day
                    </Typography>
                    <Stack>
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "12px",
                            sm: "12px",
                            md: "16px",
                            lg: "16px",
                            xl: "16px",
                          },
                        }}
                      >
                        For one-time tasks like moving furniture, equipment, or
                        other items, renting is the smarter choice. No long-term
                        commitment, no maintenance costs—just an easy and
                        affordable solution for a single job.
                        <br />
                        <br />
                        Find a trailer near you, book it, and get rolling the
                        same day!
                        <br />
                        <CustomButton
                          onClick={() => navigate("/signup")}
                          style={{
                            backgroundColor: themeRed,
                            color: "white",
                            marginTop: "10px",
                          }}
                          children={"Sign Up"}
                        />
                        {/* Content will be provided by the client. Content will be
                        provided by the client. Content will be provided by the
                        client. Content will be provided by the client. */}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Stack maxWidth={"1200px"} px={2}>
                    <img
                      width={"100%"}
                      // src="https://res.cloudinary.com/dpfni06vw/image/upload/v1726246244/Group_36510_vnkkgr.png"
                      // src="https://res.cloudinary.com/dpfni06vw/image/upload/v1733262541/Group_36510_vnkkgr_qdf4po.webp"
                      src="https://res.cloudinary.com/dnptzwqt1/image/upload/v1736362132/ET_dump_at_2457_luuwio.jpg"
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Container>
          </Stack>
        </Stack>
      </Stack>
      {/* Second Section */}
      {/* Third Section */}
      <Stack>
        {/* <ProvinceCarousel /> */}
        <ProvinceCarouselSection />
      </Stack>

      <FaqsSection />

      {/* <Stack alignItems={"center"} p={10}>
        <Stack maxWidth={"1000px"} mb={10}>
          <Typography
            fontWeight={"700"}
            textAlign={"center"}
            sx={{
              fontSize: {
                xs: "50px",
                sm: "50px",
                md: "60px",
                lg: "60px",
                xl: "60px",
              },
            }}
          >
            Frequently asked questions
          </Typography>
          <div
            style={{
              borderBottom: "35px solid #99000B26",
              outline: "none",
              color: "#99000B26",
              marginTop: -50,
            }}
          ></div>
        </Stack>
        <Grid container alignItems={"stretch"} spacing={3}>
          
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Stack sx={{ height: "100%" }}>
              <Grid container alignItems={"stretch"} spacing={3}>
                {faqsData
                  .slice(0, 5)
                  .map(
                    (
                      {
                        question,
                        upper_description,
                        lower_description,
                        content,
                      },
                      index
                    ) => (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        key={index}
                      >
                        <Stack sx={{ height: "100%" }}>
                          <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <Typography fontWeight="bold">
                                {question}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              {upper_description && (
                                <Typography variant="body1" gutterBottom>
                                  {upper_description}
                                </Typography>
                              )}

                              {content.length > 0 && (
                                <Stack spacing={2} sx={{ marginLeft: "20px" }}>
                                  {content.map((item, idx) => (
                                    <div key={idx}>
                                      <Typography
                                        variant="subtitle1"
                                        fontWeight="bold"
                                      >
                                        {item.title}
                                      </Typography>
                                      <Typography variant="body2">
                                        {item.point}
                                      </Typography>
                                    </div>
                                  ))}
                                </Stack>
                              )}

                              {lower_description && (
                                <Typography
                                  variant="body1"
                                  style={{ marginTop: "10px" }}
                                >
                                  {lower_description}
                                </Typography>
                              )}
                            </AccordionDetails>
                          </Accordion>
                        </Stack>
                      </Grid>
                    )
                  )}
              </Grid>
            </Stack>
          </Grid>
         
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Stack sx={{ height: "100%" }}>
              <Grid container alignItems={"stretch"} spacing={3}>
                {faqsData
                  .slice(5, 12)
                  .map(
                    (
                      {
                        question,
                        upper_description,
                        lower_description,
                        content,
                      },
                      index
                    ) => (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        key={index}
                      >
                        <Stack sx={{ height: "100%" }}>
                          <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <Typography fontWeight="bold">
                                {question}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              {upper_description && (
                                <Typography variant="body1" gutterBottom>
                                  {upper_description}
                                </Typography>
                              )}
                              {content.length > 0 && (
                                <Stack spacing={2} sx={{ marginLeft: "20px" }}>
                                  {content.map((item, idx) => (
                                    <div key={idx}>
                                      <Typography
                                        variant="subtitle1"
                                        fontWeight="bold"
                                      >
                                        {item.title}
                                      </Typography>
                                      <Typography variant="body2">
                                        {item.point}
                                      </Typography>
                                    </div>
                                  ))}
                                </Stack>
                              )}

                              {lower_description && (
                                <Typography
                                  variant="body1"
                                  style={{ marginTop: "10px" }}
                                >
                                  {lower_description}
                                </Typography>
                              )}
                            </AccordionDetails>
                          </Accordion>
                        </Stack>
                      </Grid>
                    )
                  )}
              </Grid>
            </Stack>
          </Grid>
        </Grid>
      </Stack> */}
      <Footer />
    </div>
  );
}

export default HowItsWork;
