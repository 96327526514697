import { AppBar, Container, IconButton, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { UserTopAppBar } from "./UserTopBar";
import {
  userDashboardRoutes,
  userFromUserSideRoutes,
} from "../../utils/sideRoute";
import { useSelector } from "react-redux";
import useWindowDimensions from "../../utils/hooks/windowDimensions";

export const UserContentView = (props) => {
  const { width } = useWindowDimensions();
  const { login_status, login_error, userAuth, userData, check_auth_status } =
    useSelector((state) => state.userAuth);
  const { active, onClickHanlde, isActiveState } = props;

  const [setIsActiveView, setIsActiveViewState] = useState();
  const userRole = 2;

  useEffect(() => {
    if (width < 851) {
      setIsActiveViewState(true);
    }
    if (width > 851) {
      setIsActiveViewState(false);
    }
  }, [width]);

  const location = useLocation();

  // Get the current path
  const currentPath = location.pathname;

  // Extract the screen name from the path
  const screenName =
    currentPath.split("/").filter(Boolean).pop() || "Dashboard";
  const userType = useSelector((state) => state.userType.userType);

  return (
    <Stack
      className={!setIsActiveView ? "content-view" : "content-view-mobile"}
      bgcolor={"#FAFAFA"}
      flex={1}
    >
      {/* <Stack > */}
      <UserTopAppBar
        onClickHanlde={onClickHanlde}
        setIsActiveView={setIsActiveView}
      />
      <Container maxWidth={false}>
        <Stack sx={{ paddingTop: screenName === "settings" ? "0px" : "30px" }}>
          <Routes>
            {userType === "User"
              ? React.Children.toArray(
                  userFromUserSideRoutes.map((e) => {
                    return <Route path={e.linkTo} element={e.element} />;
                  })
                )
              : React.Children.toArray(
                  userDashboardRoutes.map((e) => {
                    return <Route path={e.linkTo} element={e.element} />;
                  })
                )}
          </Routes>
        </Stack>
      </Container>
      {/* </Stack> */}
    </Stack>
  );
};
