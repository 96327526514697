import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Tabs,
  Tab,
  Box,
  Typography,
  Stack,
  ThemeProvider,
  createTheme,
  Checkbox,
} from "@mui/material";
import DatePicker, { DateObject } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import MapIcon from "@mui/icons-material/Map";
import PickupIcon from "@mui/icons-material/LocalShipping";
import { themeRed } from "../../utils/colorTheme";
import { useNavigate } from "react-router-dom";
import TimePicker from "rc-time-picker";
import moment from "moment";
import "rc-time-picker/assets/index.css";
import { useSelector } from "react-redux";

const format = "MM/DD/YYYY";

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: "#C3282F",
            },
          },
          "& .MuiInputLabel-root": {
            "&.Mui-focused": {
              color: "#C3282F",
            },
          },
        },
      },
    },
  },
});

// const BookingTabsManagement = ({ setSelectedPricing, onDateRangeChange, onPickupTimeChange }) => {
//     const navigate = useNavigate();
//     const [tab, setTab] = useState(0);
//     const [dates, setDates] = useState([]);
//     const [dateRange, setDateRange] = useState('');
//     const [pickupTime, setPickupTime] = useState('');

//     const handleTabChange = (event, newValue) => {
//         setTab(newValue);
//     };

//     useEffect(() => {
//         const daysSelected = dates.length;

//         if (daysSelected >= 1 && daysSelected <= 6) {
//             setSelectedPricing('Per Day');
//         } else if (daysSelected >= 7 && daysSelected <= 29) {
//             setSelectedPricing('Per Week');
//         } else if (daysSelected >= 30) {
//             setSelectedPricing('Per Month');
//         } else {
//             setSelectedPricing('');
//         }

//         // Set date range display for input
//         if (dates.length > 0) {
//             const startDate = dates[0].format(format);
//             const endDate = dates[dates.length - 1].format(format);
//             const range = `${startDate} - ${endDate}`;
//             setDateRange(range);
//             onDateRangeChange(range);
//         } else {
//             setDateRange('');
//             onDateRangeChange('');
//         }
//     }, [dates, setSelectedPricing, onDateRangeChange]);

//     // Get the current date
//     const today = new DateObject();

//     const renderMultiDatePicker = () => (
//         <ThemeProvider theme={theme}>
//             <Typography variant="body1" style={{ fontSize: '12px' }}>Select Dates*</Typography>
//             <Stack sx={{ width: '100%' }}>
//                 <DatePicker
//                     placeholder='Select Dates'
//                     value={dates}
//                     onChange={setDates}
//                     multiple
//                     sort
//                     format={format}
//                     minDate={today}
//                     calendarPosition="bottom-center"
//                     plugins={[<DatePanel />]}
//                     style={{ width: '100%', zIndex: 2, padding: '25px 10px' }}
//                 />
//             </Stack>

//             {
//                 dateRange && <TextField
//                     label="Selected Date Range"
//                     value={dateRange}
//                     fullWidth
//                     style={{ marginTop: '20px' }}
//                     InputProps={{
//                         readOnly: true,
//                     }}
//                 />
//             }

//             <TextField
//                 label="Pickup Time"
//                 type="time"
//                 value={pickupTime}
//                 onChange={(e) => setPickupTime(e.target.value)}
//                 fullWidth
//                 style={{ marginTop: '20px' }}
//                 InputProps={{
//                     disableUnderline: true,
//                 }}
//                 InputLabelProps={{
//                     shrink: true,
//                 }}
//             />
//         </ThemeProvider>
//     );

//     return (
//         <Box sx={{ width: '100%' }}>
//             <Tabs
//                 value={tab}
//                 onChange={handleTabChange}
//                 aria-label="Pickup and Delivery Tabs"
//                 centered
//                 TabIndicatorProps={{ style: { backgroundColor: themeRed } }}
//             >
//                 <Tab
//                     icon={<PickupIcon />}
//                     label="Pickup"
//                     sx={{
//                         '&.Mui-selected': {
//                             color: themeRed,
//                         }
//                     }}
//                 />
//                 <Tab
//                     icon={<MapIcon />}
//                     label="Delivery"
//                     sx={{
//                         '&.Mui-selected': {
//                             color: themeRed,
//                         }
//                     }}
//                 />
//             </Tabs>

//             <Box sx={{ p: 3 }}>
//                 {tab === 0 ? (
//                     <>
//                         {/* Pickup section */}
//                         {renderMultiDatePicker()}
//                     </>
//                 ) : (
//                     <>
//                         {/* Delivery section */}
//                         <ThemeProvider theme={theme}>
//                             <TextField
//                                 label="Delivery Address"
//                                 fullWidth
//                                 style={{ marginTop: '10px' }}
//                             />
//                         </ThemeProvider>
//                         <Typography variant="body2" color="textSecondary" style={{ marginTop: '10px' }}>
//                             It will cost you $4.75 per mile.
//                         </Typography>
//                         <Stack mt={2}>
//                             {renderMultiDatePicker()}
//                         </Stack>
//                     </>
//                 )}
//             </Box>

//             <Button
//                 variant="contained"
//                 color="error"
//                 fullWidth
//                 style={{ marginTop: '20px' }}
//                 onClick={() => navigate("/checkout")}
//             >
//                 Book Now
//             </Button>
//         </Box>
//     );
// };

// export default BookingTabsManagement;

const formatTime = (time) => {
  const [hours, minutes] = time.split(":");
  let period = "AM";
  let formattedHours = parseInt(hours, 10);

  if (formattedHours >= 12) {
    period = "PM";
    if (formattedHours > 12) {
      formattedHours -= 12;
    }
  } else if (formattedHours === 0) {
    formattedHours = 12;
  }

  return `${formattedHours}:${minutes} ${period}`;
};

const BookingTabsManagement = ({
  setSelectedPricing,
  onDateRangeChange,
  onPickupStartTimeChange,
  onPickupEndTimeChange,
  onTabChange,
  onClick,
  setDeliveryAddress,
  isDisabled,
}) => {
  const [tab, setTab] = useState(0);
  const [dates, setDates] = useState([]);
  const [dateRange, setDateRange] = useState("");
  const [pickupStartTime, setPickupStartTime] = useState(
    moment().startOf("day")
  );
  const [pickupEndTime, setPickupEndTime] = useState("11:59 am");
  const [insurance, setInsurance] = useState(false);
  const [deliveryAddress, setDeliveryAddressLocal] = useState("");
  const handleTabChange = (event, newValue) => {
    setTab(newValue);
    onTabChange(newValue);
  };

  const { user } = useSelector((state) => state.userAuth);

  // useEffect(() => {
  //   const daysSelected = dates?.length || [];

  //   if (daysSelected >= 1 && daysSelected <= 6) {
  //     setSelectedPricing("Per Day");
  //   } else if (daysSelected >= 7 && daysSelected <= 29) {
  //     setSelectedPricing("Per Week");
  //   } else if (daysSelected >= 30) {
  //     setSelectedPricing("Per Month");
  //   } else {
  //     setSelectedPricing("");
  //   }

  //   // Set date range display for input and call the callback
  //   if (dates?.length > 0) {
  //     const startDate = dates[0]?.format(format);
  //     const endDate = dates[dates?.length - 1].format(format);
  //     const range = `${startDate} - ${endDate}`;
  //     setDateRange(range);
  //     onDateRangeChange(range); // Pass date range to parent component
  //   } else {
  //     setDateRange("");
  //     onDateRangeChange(""); // Clear date range in parent component
  //   }
  // }, [dates, setSelectedPricing, onDateRangeChange]);

  // useEffect(() => {
  //   if (!dates || dates.length === 0) {
  //     setSelectedPricing("");
  //     setDateRange("");
  //     onDateRangeChange([]);
  //     return;
  //   }

  //   // Sort selected dates just in case
  //   const sortedDates = [...dates].sort((a, b) => a.toDate() - b.toDate());

  //   const start = sortedDates[0].toDate();
  //   const end = sortedDates[sortedDates.length - 1].toDate();

  //   // Calculate total hours and convert to days (rounded up)
  //   const diffInMs = end - start;
  //   const totalHours = diffInMs / (1000 * 60 * 60);
  //   const totalDays = Math.ceil(totalHours / 24);

  //   // Pricing logic based on calculated days
  //   if (totalDays >= 1 && totalDays <= 6) {
  //     setSelectedPricing("Per Day");
  //   } else if (totalDays >= 7 && totalDays <= 29) {
  //     setSelectedPricing("Per Week");
  //   } else if (totalDays >= 30) {
  //     setSelectedPricing("Per Month");
  //   } else {
  //     setSelectedPricing("");
  //   }

  //   // Show all selected dates
  //   const formattedDates = sortedDates.map((d) => d.format(format));
  //   setDateRange(formattedDates.join(", "));
  //   onDateRangeChange(formattedDates); // send all selected dates to parent
  // }, [dates, setSelectedPricing, onDateRangeChange]);

  useEffect(() => {
    if (!dates || dates.length === 0) {
      setSelectedPricing("");
      setDateRange("");
      onDateRangeChange?.([]); // Safe check in case it's undefined
      return;
    }

    const sortedDates = [...dates].sort((a, b) => a.toDate() - b.toDate());

    const start = sortedDates[0].toDate();
    const end = sortedDates[sortedDates.length - 1].toDate();

    const diffInMs = end - start;
    const totalHours = diffInMs / (1000 * 60 * 60);
    const totalDays = Math.ceil(totalHours / 24);

    if (totalDays >= 1 && totalDays <= 6) {
      setSelectedPricing("Per Day");
    } else if (totalDays >= 7 && totalDays <= 29) {
      setSelectedPricing("Per Week");
    } else if (totalDays >= 30) {
      setSelectedPricing("Per Month");
    } else {
      setSelectedPricing("");
    }

    const formattedDates = sortedDates.map((d) => d.format(format));
    setDateRange(formattedDates.join(", "));
    onDateRangeChange?.(formattedDates);
  }, [dates, setSelectedPricing]); // <-- Only watch `dates`

  const handlePickupTimeChange = (e) => {
    const time = e.target.value;
    const formattedTime = formatTime(time);
    setPickupStartTime(formattedTime);
    setPickupEndTime(formattedTime);
    onPickupStartTimeChange(formattedTime);
    onPickupEndTimeChange(formattedTime);
  };

  const handleAddressChange = (event) => {
    const newAddress = event.target.value;
    setDeliveryAddressLocal(newAddress);
    setDeliveryAddress(newAddress);
  };

  const formatTime = "h:mm a";

  const now = moment().hour(0).minute(0);

  function onChange(value, key) {
    if (key === "start") {
      setPickupStartTime(value && value.format(formatTime));
      onPickupStartTimeChange(value && value.format(formatTime));
    } else {
      setPickupEndTime(value && value.format(formatTime));
      onPickupEndTimeChange(value && value.format(formatTime));
    }
  }

  const today = new DateObject();

  // Effect for loading insurance state from localStorage when component mounts
  useEffect(() => {
    const storedInsurance = localStorage.getItem("insurance");
    if (storedInsurance !== null) {
      setInsurance(JSON.parse(storedInsurance)); // Set checkbox state from localStorage
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("insurance", JSON.stringify(insurance)); // Save the state to localStorage
  }, [insurance]);

  // Handling the checkbox change
  const handleCheckboxChange = (e) => {
    setInsurance(e.target.checked); // Update the insurance state
  };
  const handleDateChange = (selectedDates) => {
    const formattedDates = selectedDates.map((date) => date.format(format)); // Or use any format you prefer
    setDates(selectedDates);
  };

  const renderMultiDatePicker = () => (
    <ThemeProvider theme={theme}>
      <Typography variant="body1" style={{ fontSize: "12px" }}>
        Select Dates*
      </Typography>
      <Stack sx={{ width: "100%" }}>
        <DatePicker
          placeholder="Select Dates"
          value={dates}
          onChange={handleDateChange}
          multiple
          sort
          format={format}
          minDate={today}
          calendarPosition="bottom-center"
          plugins={[<DatePanel />]}
          style={{ width: "100%", zIndex: 2, padding: "25px 10px" }}
        />
      </Stack>

      {dateRange && (
        <TextField
          label="Selected Date Range"
          value={dateRange}
          fullWidth
          style={{ marginTop: "20px" }}
          InputProps={{
            readOnly: true,
          }}
        />
      )}

      {/* <TextField
                label="Pickup Time"
                type="time"
                value={pickupTime}
                onChange={handlePickupTimeChange}
                fullWidth
                style={{ marginTop: '20px' }}
                InputProps={{
                    disableUnderline: true,
                }}
                InputLabelProps={{
                    shrink: true,
                }}
            /> */}

      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        gap={2}
        sx={{ width: "100%", mt: 2 }}
      >
        <div>
          <Typography variant="body1" style={{ fontSize: "12px" }}>
            Start Time*
          </Typography>
          <TimePicker
            placeholder="Pickup Start Time"
            showSecond={false}
            defaultValue={pickupStartTime}
            className="TimePickerClass"
            onChange={(e) => onChange(e, "start")}
            format={formatTime}
            use12Hours
            inputReadOnly
          />
        </div>
        <div>
          <Typography variant="body1" style={{ fontSize: "12px" }}>
            End Time*
          </Typography>
          <TimePicker
            placeholder="Pickup End Time"
            showSecond={false}
            defaultValue={moment(pickupEndTime, "h:mm a")}
            className="TimePickerClass"
            onChange={(e) => onChange(e, "end")}
            format={formatTime}
            use12Hours
            inputReadOnly
          />
        </div>
      </Stack>

      <Stack direction={"row"} alignItems={"center"}>
        <Typography variant="body1" style={{ fontSize: "12px" }}>
          Would you like to add insurance for ${user?.insurance || ""}?
        </Typography>
        <Checkbox
          checked={insurance}
          onChange={handleCheckboxChange} // Handle checkbox state change
          sx={{
            color: themeRed,
            "&.Mui-checked": {
              color: themeRed,
            },
          }}
        />
      </Stack>
    </ThemeProvider>
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={tab}
        onChange={handleTabChange}
        aria-label="Pickup and Delivery Tabs"
        centered
        TabIndicatorProps={{ style: { backgroundColor: themeRed } }}
      >
        <Tab
          icon={<PickupIcon />}
          label="Pickup"
          sx={{
            "&.Mui-selected": {
              color: themeRed,
            },
          }}
        />
        {/* <Tab
          icon={<MapIcon />}
          label="Delivery"
          sx={{
            "&.Mui-selected": {
              color: themeRed,
            },
          }}
        /> */}
      </Tabs>

      <Box sx={{ p: 3 }}>
        {tab === 0 ? (
          <>
            {/* Pickup section */}
            {renderMultiDatePicker()}
          </>
        ) : (
          <>
            {/* Delivery section */}
            <ThemeProvider theme={theme}>
              <TextField
                label="Delivery Address"
                value={deliveryAddress}
                onChange={handleAddressChange}
                fullWidth
                style={{ marginTop: "10px" }}
              />
            </ThemeProvider>
            {/* <Typography variant="body2" color="textSecondary" style={{ marginTop: '10px' }}>
                            It will cost you $4.75 per mile.
                        </Typography> */}
            <Stack mt={2}>{renderMultiDatePicker()}</Stack>
          </>
        )}
      </Box>

      <Button
        variant="contained"
        color="error"
        fullWidth
        style={{ marginTop: "20px" }}
        onClick={onClick}
        disabled={isDisabled}
      >
        Book Now
      </Button>
    </Box>
  );
};

export default BookingTabsManagement;
