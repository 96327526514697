import {
  Box,
  Card,
  CardContent,
  Container,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import { themeRed } from "../../../utils/colorTheme";
import Slider from "react-slick";
import css from "./ProvinceCarouselSection.module.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { trailerCategories } from "../CategorieSection/CategoriesSection";
import ButtonComp from "../../../component/common/ButtonComp";
import { useNavigate } from "react-router-dom";
import british_columbia_grey from "../../../assets/flags/british_columbia_grey.png";
import british_columbia_clr from "../../../assets/flags/british_columbia_clr.png";
import alberta_grey from "../../../assets/flags/alberta_grey.png";
import alberta_clr from "../../../assets/flags/alberta_clr.png";
import saskatchewan_grey from "../../../assets/flags/saskatchewan_grey.png";
import saskatchewan_clr from "../../../assets/flags/saskatchewan_clr.png";
import manitoba_grey from "../../../assets/flags/manitoba_grey.png";
import manitoba_clr from "../../../assets/flags/manitoba_clr.png";
import new_brunswick_grey from "../../../assets/flags/new_brunswick_grey.png";
import new_brunswick_clr from "../../../assets/flags/new_brunswick_clr.png";
import prince_edward_island_grey from "../../../assets/flags/prince_edward_island_grey.png";
import prince_edward_island_clr from "../../../assets/flags/prince_edward_island_clr.png";
import newfoundland_and_labrador_grey from "../../../assets/flags/newfoundland_and_labrador_grey.png";
import newfoundland_and_labrador_clr from "../../../assets/flags/newfoundland_and_labrador_clr.png";
import { useDispatch, useSelector } from "react-redux";
import { apiHandle } from "../../../config/apiHandle/apiHandle";
import CircularProgress from "@mui/material/CircularProgress";
import { getProvinceListAsync } from "../../../services/authentication";
import { asyncStatus } from "../../../utils/asyncStatus";

// Sample province data - grayscale image (default) and colorful image (on hover)
const provinces = [
  {
    name: "British Columbia",
    imgSrc: british_columbia_grey,
    colorfulImgSrc: british_columbia_clr,
    isTrailerAvailable: true,
    code: "BC",
  },
  {
    name: "Alberta",
    imgSrc: alberta_grey,
    colorfulImgSrc: alberta_clr,
    isTrailerAvailable: true,
    code: "AB",
  },
  {
    name: "Saskatchewan",
    imgSrc: saskatchewan_grey,
    colorfulImgSrc: saskatchewan_clr,
    isTrailerAvailable: true,
    code: "SK",
  },
  {
    name: "Manitoba",
    imgSrc: manitoba_grey,
    colorfulImgSrc: manitoba_clr,
    isTrailerAvailable: true,
    code: "MB",
  },
  {
    name: "Ontario",
    imgSrc:
      "https://res.cloudinary.com/dpfni06vw/image/upload/v1725923236/Group_1000002264_npbdif.png",
    colorfulImgSrc:
      "https://res.cloudinary.com/dpfni06vw/image/upload/v1725924976/Property_1_Variant2_2_sa7qxh.png",
    isTrailerAvailable: true,
    code: "ON",
  },
  {
    name: "Quebec",
    imgSrc:
      "https://res.cloudinary.com/dpfni06vw/image/upload/v1725923237/Mask_group_ddpwud.png",
    colorfulImgSrc:
      "https://res.cloudinary.com/dpfni06vw/image/upload/v1725924976/Property_1_Variant2_3_cwauod.png",
    isTrailerAvailable: true,
    code: "QC",
  },
  {
    name: "New Brunswick",
    imgSrc: new_brunswick_grey,
    colorfulImgSrc: new_brunswick_clr,
    isTrailerAvailable: true,
    code: "NB",
  },
  {
    name: "Prince Edward Island",
    imgSrc: prince_edward_island_grey,
    colorfulImgSrc: prince_edward_island_clr,
    isTrailerAvailable: true,
    code: "PE",
  },
  {
    name: "Nova Scotia",
    imgSrc:
      "https://res.cloudinary.com/dpfni06vw/image/upload/v1725923236/Group_1000002263_wcntmv.png",
    colorfulImgSrc:
      "https://res.cloudinary.com/dpfni06vw/image/upload/v1725924977/Property_1_Variant2_1_ppslzr.png",
    isTrailerAvailable: true,
    code: "NS",
  },
  {
    name: "Newfoundland and Labrador",
    imgSrc: newfoundland_and_labrador_grey,
    colorfulImgSrc: newfoundland_and_labrador_clr,
    isTrailerAvailable: true,
    code: "NL",
  },
  {
    name: "The Yukon",
    imgSrc:
      "https://res.cloudinary.com/dpfni06vw/image/upload/v1725923236/Group_1000002266_tex18z.png",
    colorfulImgSrc:
      "https://res.cloudinary.com/dpfni06vw/image/upload/v1725924977/Property_1_Variant2_5_nljzsi.png",
    isTrailerAvailable: true,
    code: "YT",
  },

  {
    name: "Northwest Territories",
    imgSrc:
      "https://res.cloudinary.com/dpfni06vw/image/upload/v1725923236/Group_1000002265_mqghck.png",
    colorfulImgSrc:
      "https://res.cloudinary.com/dpfni06vw/image/upload/v1725924977/Property_1_Variant2_4_nvohnj.png",
    isTrailerAvailable: true,
    code: "NT",
  },
  {
    name: "Nunavut",
    imgSrc:
      "https://res.cloudinary.com/dpfni06vw/image/upload/v1725923236/Group_1000002260_ubamqi.png",
    colorfulImgSrc:
      "https://res.cloudinary.com/dpfni06vw/image/upload/v1725924977/Property_1_Variant2_b1ki3c.png",
    isTrailerAvailable: true,
    code: "NU",
  },
];

const ProvinceCarouselSection = () => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { get_user_trailers_list_data } = useSelector(
    (state) => state.trailersDetailsManage
  );
  const { get_all_province_status, get_all_province_data } = useSelector(
    (state) => state.userAuth
  );

  const loading = get_all_province_status === asyncStatus.LOADING;

  useEffect(() => {
    dispatch(getProvinceListAsync());
  }, []);

  return (
    <Container maxWidth="lg">
      <div style={{ margin: "150px auto", width: "100%" }}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography variant="h4" gutterBottom fontWeight={"bold"}>
            Browse by your Province
          </Typography>
          <Stack direction={"row"} alignItems={"center"}>
            <IconButton
              sx={{ padding: "8px", fontSize: "30px" }}
              ref={prevRef}
              className="swiper-button-prev-custom"
            >
              <IoIosArrowBack style={{ color: themeRed }} />
            </IconButton>
            <IconButton
              sx={{ padding: "8px", fontSize: "30px" }}
              ref={nextRef}
              className="swiper-button-next-custom"
            >
              <IoIosArrowForward style={{ color: themeRed }} />
            </IconButton>
          </Stack>
        </Stack>

        <Swiper
          slidesPerView={5}
          spaceBetween={10}
          navigation={{
            prevEl: prevRef.current,
            nextEl: nextRef.current,
          }}
          onInit={(swiper) => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }}
          modules={[Navigation]}
          className="mySwiper"
          breakpoints={{
            1440: { slidesPerView: 5 },
            1366: { slidesPerView: 5 },
            1200: { slidesPerView: 4 },
            1024: { slidesPerView: 3 },
            768: { slidesPerView: 3 },
            600: { slidesPerView: 2 },
            0: { slidesPerView: 1 },
          }}
        >
          {/* {provinces.map((elem, i) => {
            const trailerProvinceData =
              get_user_trailers_list_data?.trailers_by_provice || [];
            const trailerData = trailerProvinceData?.find(
              (trailer) => trailer?.name === elem?.name
            );
            const hasTrailer = trailerData ? trailerData?.hasTrailer : false;

            return (
              <SwiperSlide key={i}>
                <Stack gap={4} sx={{ padding: "11px 0px !important" }}>
                  {!hasTrailer ? (
                    <div className={css.unique_carousel_wrapper}>
                      <div className={css.unique_carousel_item}>
                        <img
                          src={elem.imgSrc}
                          alt={elem?.name}
                          className={`${css.trailer_img} ${css.grayscale_img} ${css.trailer__blur}`}
                        />
                        <img
                          src={elem?.colorfulImgSrc}
                          alt={elem?.name}
                          className={`${css.trailer_img} ${css.colorful_img} ${css.trailer__blur}`}
                        />

                        <Typography>{elem.name}</Typography>
                      </div>

                      <div className={css.unique_carousel_content_section}>
                        <span class={css.title_text}>
                          Opening here soon. Check back!
                        </span>
                        <br />
                      </div>
                    </div>
                  ) : (
                    <div
                      className={css.unique_carousel_wrapper}
                      onClick={() => {
                        navigate("/trailer-map", {
                          state: { location_state: elem.code },
                        });
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <div className={css.unique_carousel_item}>
                        <img
                          src={elem?.colorfulImgSrc}
                          alt={elem?.name}
                          className={`${css.trailer_img} `}
                        />
                        <Typography>{elem.name}</Typography>
                      </div>
                    </div>
                  )}
                </Stack>
              </SwiperSlide>
            );
          })} */}
          {loading ? (
            <Stack
              alignItems={"center"}
              justifyContent={"center"}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress color="error" />
            </Stack>
          ) : (
            get_all_province_data?.map((province) => {
              const isActive = province.status === "Active";

              const trailerProvinceData =
                get_user_trailers_list_data?.trailers_by_provice || [];
              const trailerData = trailerProvinceData.find(
                (t) => t?.name === province?.name
              );

              const hasTrailer = isActive && trailerData?.hasTrailer;
              const wrapperClass = isActive
                ? "province-active"
                : "province-inactive";
              return (
                <SwiperSlide key={province._id}>
                  <Stack gap={4} sx={{ padding: "11px 0px !important" }}>
                    {!isActive ? (
                      <div
                        className={`${css.unique_carousel_wrapper} ${css[wrapperClass]}`}
                      >
                        <div className={css.unique_carousel_item}>
                          <img
                            src={province.image}
                            alt={province.name}
                            className={`${css.trailer_img} ${css.grayscale_img} ${css.trailer__blur}`}
                          />
                          <Typography>{province.name}</Typography>
                        </div>
                        <div className={css.unique_carousel_content_section}>
                          <span className={css.title_text}>
                            Opening here soon. Check back!
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div
                        className={css.unique_carousel_wrapper}
                        onClick={() => {
                          navigate("/trailer-map", {
                            state: { location_state: province.code },
                          });
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <div className={css.unique_carousel_item}>
                          <img
                            src={province.image}
                            alt={province.name}
                            className={`${css.trailer_img}`}
                          />
                          <Typography>{province.name}</Typography>
                        </div>
                      </div>
                    )}
                  </Stack>
                </SwiperSlide>
              );
            })
          )}
        </Swiper>
      </div>
    </Container>
  );
};

export default ProvinceCarouselSection;
