import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import { Container, Stack } from "@mui/material";
import logo from "../../assets/MainLogo.png";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CustomButton from "../common/Button/Button";
import ProfileMenu from "../common/menu/ProfileMenu";
import "./navbar.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleUserType } from "../../store/slices/userTypeSlice";
import { themeRed } from "../../utils/colorTheme";

const drawerWidth = 240;

const userRoutes = [
  { name: "Home", path: "/" },
  // { name: "About Us", path: "/faqs" },
  // { name: "Contact Us", path: "/faqs" },
  { name: "How it works", path: "/how-its-work" },
  { name: "FAQ", path: "/faqs" },
];

const Navbar = (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const userType = useSelector((state) => state.userType.userType);
  const { userAuth } = useSelector((state) => state.userAuth);

  const handleToggle = () => {
    dispatch(toggleUserType());
    navigate("/");
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  const isRouteMatch =
    userRoutes.some((route) => route.path === location.pathname) ||
    location.pathname === "/login" ||
    location.pathname === "/signup";

  const isPathMatch = location.pathname;

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <List>
        {userType === "Host"
          ? null
          : userRoutes.map((route) => (
              <ListItem
                button
                key={route.name}
                onClick={() => handleNavigation(route.path)}
              >
                <ListItemText
                  primary={route.name}
                  sx={{
                    color: isPathMatch === route.path ? themeRed : "black",
                    cursor: "pointer",
                  }}
                />
              </ListItem>
            ))}
        {!userAuth && (
          <>
            {" "}
            <ListItem button onClick={() => handleNavigation("/login")}>
              <ListItemText primary="Log In" sx={{ cursor: "pointer" }} />
            </ListItem>
            <ListItem button onClick={() => handleNavigation("/signup")}>
              <ListItemText primary="Sign Up" sx={{ cursor: "pointer" }} />
            </ListItem>{" "}
          </>
        )}
        {userAuth && (
          <ListItem button onClick={() => handleNavigation("/dashboard-home")}>
            <ListItemText primary="Dashboard" sx={{ cursor: "pointer" }} />
          </ListItem>
        )}
        {!userAuth && (
          <ListItem button>
            <CustomButton
              className="switch-btn"
              variant="outlined"
              onClick={handleToggle}
            >
              {userType === "Host" ? "Switch to User" : "Switch to Host"}
            </CustomButton>
          </ListItem>
        )}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Stack sx={{ backgroundColor: "white", borderBottom: "1px solid #ddd" }}>
      <Container maxWidth="lg">
        <CssBaseline />
        <AppBar
          component="nav"
          color="inherit"
          position="static"
          sx={{ boxShadow: "none" }}
        >
          <Stack
            className="navbar-container"
            display="flex"
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            py={5}
          >
            {/* Hamburger Icon for Mobile */}
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className="hamburger-icon"
              sx={{ display: { md: "none" } }}
            >
              <MenuIcon />
            </IconButton>

            {/* Logo */}
            <img
              src={logo}
              alt="Logo"
              width={100}
              style={{ cursor: "pointer" }}
              onClick={() => handleNavigation("/")}
            />

            {/* Navigation Links for Desktop */}
            <Stack
              direction="row"
              spacing={4}
              alignItems="center"
              className="nav-links"
              sx={{
                display: { xs: "none", md: "flex" },
              }}
            >
              {userType === "Host"
                ? null
                : userRoutes.map((route) => (
                    <Typography
                      key={route.path}
                      className="nav-link"
                      onClick={() => handleNavigation(route.path)}
                      sx={{
                        color: isPathMatch === route.path ? themeRed : "black",
                      }}
                    >
                      {route.name}
                    </Typography>
                  ))}
            </Stack>

            {/* Right Side (Login, Signup, ProfileMenu, Switch Button) */}
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              sx={{
                display: {
                  xl: "inline-flex",
                  lg: "inline-flex",
                  md: "inline-flex",
                  sm: "none",
                  xs: "none",
                },
              }}
            >
              {userAuth ? (
                <ProfileMenu />
              ) : (
                <>
                  <Typography
                    className="nav-link"
                    onClick={() => handleNavigation("/login")}
                  >
                    Log In
                  </Typography>
                  <Typography
                    className="nav-link"
                    onClick={() => handleNavigation("/signup")}
                  >
                    Sign Up
                  </Typography>
                </>
              )}
              {!userAuth && isRouteMatch && (
                <button className="toggle-btn-type" onClick={handleToggle}>
                  {userType === "Host" ? "Switch to User" : "Switch to Host"}
                </button>
              )}
            </Stack>
          </Stack>
        </AppBar>

        {/* Mobile Drawer */}
        <nav>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile
            }}
            sx={{
              display: { xs: "block", md: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </nav>
      </Container>
    </Stack>
  );
};

Navbar.propTypes = {
  window: PropTypes.func,
};

export default Navbar;
