import React, { useEffect, useState } from "react";
import "./AddAccountForm.css";
import { Stack } from "@mui/material";
import CustomButton from "../../component/common/Button/Button";
import { themeRed } from "../../utils/colorTheme";
import { useDispatch, useSelector } from "react-redux";
import { add_bank_account } from "../../services/bank_mange_service";
import { asyncStatus } from "../../utils/asyncStatus";
import { useNavigate } from "react-router-dom";
// import { setAddBankStatus } from "../../store/slices/bank_mange_slice";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import { CircularProgressbar } from "react-circular-progressbar";
import { setAddBankStatus } from "../../store/slices/bank_manage_slice";
import { getProvinceListAsync } from "../../services/authentication";

// Custom styles for the modal
const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "10px",
    padding: "20px",
    width: "500px",
    textAlign: "center",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
  },
}));

const AddAccountForm = () => {
  const [formData, setFormData] = useState({
    accountHolderName: "",
    accountNumber: "",
    firstName: "",
    lastName: "",
    routingNumber: "",
    dob: "",
    address1: "",
    address2: "",
    country: "",
    state: "",
    city: "",
    postalCode: "",
    ssn: "",
    verificationDocs: null,
    termsAccepted: false,
    transit_number: "",
  });
  const { get_all_province_status, get_all_province_data } = useSelector(
    (state) => state.userAuth
  );
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);

  // const handleSubmit = (e) => {
  //     e.preventDefault();
  //     // After form submission logic
  //      // Open the modal after submitting
  // };

  const handleClose = () => {
    setOpen(false);
  };

  const {
    add_bank_account_data,
    add_bank_account_status,
    add_bank_account_error,
  } = useSelector((state) => state.bankManage);
  const { user_login_status, user } = useSelector((state) => state.userAuth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validateForm = () => {
    const newErrors = {};

    if (!formData.accountHolderName) {
      newErrors.accountHolderName = "Account Holder Name is required";
    }

    if (!formData.accountNumber || !/^\d+$/.test(formData.accountNumber)) {
      newErrors.accountNumber =
        "Account Number is required and should be numeric";
    }

    if (!formData.firstName) {
      newErrors.firstName = "First Name is required";
    }

    if (!formData.lastName) {
      newErrors.lastName = "Last Name is required";
    }

    if (!formData.routingNumber || !/^\d+$/.test(formData.routingNumber)) {
      newErrors.routingNumber =
        "Routing Number is required and should be numeric";
    }

    if (!formData.address1) {
      newErrors.address1 = "Address 1 is required";
    }

    if (!formData.country) {
      newErrors.country = "Country is required";
    }

    if (!formData.state) {
      newErrors.state = "State is required";
    }

    if (!formData.city) {
      newErrors.city = "City is required";
    }

    // if (!formData.ssn || !/^\d{4}$/.test(formData.ssn)) {
    //   newErrors.ssn = "Institution number must be exactly 4 digits";
    // }

    // Postal code validation for 5 digits

    if (!formData.postalCode || !/^[A-Za-z0-9]{6}$/.test(formData.postalCode)) {
      newErrors.postalCode =
        "Postal Code must be exactly 6 characters (letters & numbers only)";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const obj = {
      host_id: user?._id,
      account_name: formData.accountHolderName,
      account_number: formData.accountNumber,
      first_name: formData.firstName,
      last_name: formData.lastName,
      routing_number: formData.routingNumber,
      address_1: formData.address1,
      address_2: formData.address2,
      country: formData.country,
      state: formData.state,
      city: formData.city,
      postal_code: formData.postalCode,
      SSN: formData.ssn,
      status: "Pending",
      transit_number: formData.transit_number,
    };

    dispatch(add_bank_account(obj));
    // setOpen(true);
  };

  useEffect(() => {
    if (add_bank_account_status === asyncStatus.SUCCEEDED) {
      // navigate('/my-bank-account');
      setOpen(true);
      dispatch(setAddBankStatus());
    }
  }, [add_bank_account_status]);
  useEffect(() => {
    dispatch(getProvinceListAsync());
  }, []);

  return (
    <form className="account-form" onSubmit={handleSubmit}>
      <h1>
        Add New <span className="highlight">Account</span>
      </h1>

      <div className="form-row">
        <div className="form-group">
          <label>Account Holder Name*</label>
          <input
            type="text"
            name="accountHolderName"
            onChange={handleChange}
            required
          />
          {errors.accountHolderName && (
            <span style={{ color: "red", fontSize: 14 }}>
              {errors.accountHolderName}
            </span>
          )}
        </div>
        <div className="form-group">
          <label>Account Number*</label>
          <input
            type="text"
            name="accountNumber"
            onChange={handleChange}
            required
          />
          {errors.accountNumber && (
            <span style={{ color: "red", fontSize: 14 }}>
              {errors.accountNumber}
            </span>
          )}
        </div>
      </div>

      <div className="form-row">
        <div className="form-group">
          <label>First Name*</label>
          <input
            type="text"
            name="firstName"
            onChange={handleChange}
            required
          />
          {errors.firstName && (
            <span style={{ color: "red", fontSize: 14 }}>
              {errors.firstName}
            </span>
          )}
        </div>
        <div className="form-group">
          <label>Last Name*</label>
          <input type="text" name="lastName" onChange={handleChange} required />
          {errors.lastName && (
            <span style={{ color: "red", fontSize: 14 }}>
              {errors.lastName}
            </span>
          )}
        </div>
      </div>

      <div className="form-row">
        <div className="form-group">
          <label>Routing Number*</label>
          <input
            type="text"
            name="routingNumber"
            onChange={handleChange}
            required
          />
          {errors.routingNumber && (
            <span style={{ color: "red", fontSize: 14 }}>
              {errors.routingNumber}
            </span>
          )}
        </div>
        <div className="form-group">
          <label>Postal Code*</label>
          <input
            type="text"
            name="postalCode"
            onChange={handleChange}
            required
          />
          {errors.postalCode && (
            <span style={{ color: "red", fontSize: 14 }}>
              {errors.postalCode}
            </span>
          )}
        </div>
      </div>

      <div className="form-row">
        <div className="form-group">
          <label>Address 1*</label>
          <input type="text" name="address1" onChange={handleChange} required />
          {errors.address1 && (
            <span style={{ color: "red", fontSize: 14 }}>
              {errors.address1}
            </span>
          )}
        </div>
        <div className="form-group">
          <label>Address 2</label>
          <input type="text" name="address2" onChange={handleChange} />
        </div>
      </div>

      <div className="form-row">
        <div className="form-group">
          <label>Country of Residence*</label>
          <select name="country" onChange={handleChange} required>
            <option value="">Select Country</option>
            <option value="USA">USA</option>
            <option value="Canada">Canada</option>
          </select>
          {errors.country && (
            <span style={{ color: "red", fontSize: 14 }}>{errors.country}</span>
          )}
        </div>
        <div className="form-group">
          <label>Province*</label>
          <input type="text" name="state" onChange={handleChange} required />
          {errors.state && (
            <span style={{ color: "red", fontSize: 14 }}>{errors.state}</span>
          )}
        </div>
      </div>

      <div className="form-row">
        <div className="form-group">
          <label>City*</label>
          <input type="text" name="city" onChange={handleChange} required />
          {errors.city && (
            <span style={{ color: "red", fontSize: 14 }}>{errors.city}</span>
          )}
        </div>
        <div className="form-group">
          <label>
            Last 4 of SSN <span style={{ color: "gray" }}>(Optional)</span>
          </label>
          <input type="text" name="ssn" onChange={handleChange} />
          {/* {errors.ssn && (
            <span style={{ color: "red", fontSize: 14 }}>{errors.ssn}</span>
          )} */}
        </div>
      </div>

      <div className="buttons">
        {add_bank_account_status === asyncStatus.LOADING ? (
          <CircularProgress size={35} color="error" sx={{ marginTop: 1 }} />
        ) : (
          <CustomButton
            type="submit"
            style={{ backgroundColor: themeRed, color: "white" }}
            children={"Submit for Review"}
          />
        )}
        <CustomButton children={"Cancel"} />
      </div>
      <StyledDialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        disableEscapeKeyDown // Disable closing via the escape key
      >
        <DialogTitle>
          <Typography
            variant="h5"
            style={{ fontWeight: "bold", fontSize: "30px" }}
          >
            Bank Details <span style={{ color: "red" }}>Submitted for </span>
            Review
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" style={{ marginBottom: "20px" }}>
            Your bank details have been successfully submitted. Our team will
            review the information to ensure everything is in order. This
            process may take up to 1-2 business days. You will be notified once
            your bank details are approved and ready for use.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ alignItems: "center", justifyContent: "center" }}>
          <CustomButton
            onClick={() => {
              navigate("/my-bank-account");
            }}
            style={{ backgroundColor: themeRed, color: "white" }}
            children={"Ok"}
          />
        </DialogActions>
      </StyledDialog>
    </form>
  );
};

export default AddAccountForm;
