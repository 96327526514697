import React, { useEffect } from "react";
import Slider from "react-slick";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Stack,
  Chip,
} from "@mui/material";
import { FavoriteBorder, Star } from "@mui/icons-material";
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./TestimonialSlider.css";
import pic1 from "../assets/pic1.png";
import pic2 from "../assets/pic2.png";
import pic3 from "../assets/pic3.png";
import pic4 from "../assets/pic4.png";
import { useNavigate } from "react-router-dom";
import { themeRed } from "../utils/colorTheme";
import { HiCheckBadge } from "react-icons/hi2";

// Testimonial data
const testimonials = [
  {
    name: "Car Hauler Trailer",
    rating: "5.0",
    trips: "102 trips",
    img: pic1,
    price: "$66/day",
  },
  {
    name: "Car Hauler Trailer",
    rating: "5.0",
    trips: "41 trips",
    img: pic2,
    price: "$72/day",
  },
  {
    name: "Car Hauler Trailer",
    rating: "5.0",
    trips: "83 trips",
    img: pic3,
    price: "$71/day",
  },
  {
    name: "Car Hauler Trailer",
    rating: "5.0",
    trips: "102 trips",
    img: pic4,
    price: "$70/day",
  },
  {
    name: "Car Hauler Trailer",
    rating: "5.0",
    trips: "102 trips",
    img: pic4,
    price: "$70/day",
  },
];

// Custom arrows for the slider
const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      onClick={onClick}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        right: "50px",
        top: "-13%",
        transform: "translateY(-50%)",
        zIndex: 1,
        cursor: "pointer",
        background: "#fff",
      }}
    >
      <ArrowBackIosNew style={{ fontSize: "24px", color: themeRed }} />
    </div>
  );
};

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      onClick={onClick}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        right: "5px",
        top: "-13%",
        transform: "translateY(-50%)",
        zIndex: 1,
        cursor: "pointer",
        background: "#fff",
      }}
    >
      <ArrowForwardIos style={{ fontSize: "24px", color: themeRed }} />
    </div>
  );
};

const RentATrailerSection = ({ trailerData }) => {
  const navigate = useNavigate();
  // Slider settings
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Number of visible slides at a time
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div
      style={{
        margin: "0px auto 50px auto",
        width: "100%",
        position: "relative",
      }}
    >
      <Typography variant="h4" gutterBottom fontWeight={"bold"}>
        Browse by make
      </Typography>
      <Stack sx={{ padding: "15px 0px" }}>
        <Slider {...settings}>
          {trailerData?.map((trailer, index) => (
            <Stack
              onClick={() => navigate(`/trailer-book-details/${trailer._id}`)}
              // px={1}
              sx={{ cursor: "pointer" }}
            >
              <Card
                key={index}
                className="unique-carousel-item-1"
                sx={{
                  position: "relative",
                  borderRadius: "15px",
                  overflow: "hidden",
                  boxShadow: "0px 40px 100px 100px rgba(0, 0, 0, 0.15)",
                }}
              >
                <img
                  src={trailer?.trailer_photos[0] || ""}
                  alt={trailer?.title}
                  style={{ width: "100%", height: "150px", objectFit: "cover" }}
                />
                {trailer?.host_id?.kyc === "Completed" &&
                  trailer?.host_id?.status === "Approved" && (
                    <Chip
                      label="Verified"
                      icon={
                        <HiCheckBadge
                          style={{
                            color: "green",
                            fontSize: "18px",
                          }}
                        />
                      }
                      sx={{
                        backgroundColor: "greenyellow",
                        color: "green",
                        fontWeight: 500,
                        "& .MuiChip-icon": { ml: 0.5 },
                        position: "absolute",
                        right: "10px",
                        top: "10px",
                      }}
                    />
                  )}

                {/* <IconButton
                sx={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  backgroundColor: "white",
                  borderRadius: "50%",
                  padding: "5px",
                }}
              >
                <FavoriteBorder />
              </IconButton> */}

                <CardContent style={{ textAlign: "left" }}>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: 600, textTransform: "capitalize" }}
                  >
                    {trailer?.title}
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "5px",
                    }}
                  >
                    <Star style={{ color: "#f44336", marginRight: "4px" }} />
                    <Typography variant="body2" sx={{ fontWeight: 500 }}>
                      {/* {trailer?.rating} */}
                      5.0
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ marginLeft: "4px", color: "gray" }}
                    >
                      {/* ({trailer?.trips}) */}
                      (42 Trips)
                    </Typography>
                  </div>
                  <Typography
                    variant="body2"
                    sx={{ color: "gray", marginTop: "5px" }}
                  >
                    All-Star Host
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{ marginTop: "10px", fontWeight: 600 }}
                  >
                    ${trailer?.daily_rate || 0}/day
                  </Typography>
                </CardContent>
              </Card>
            </Stack>
          ))}
        </Slider>
      </Stack>
    </div>
  );
};

export default RentATrailerSection;
