// import React from 'react';
// import { Box, TextField, Button, Grid, MenuItem, Typography, Checkbox, FormControlLabel, Stack } from '@mui/material';
// import Navbar from '../../component/navbar/Navbar';
// import Footer from '../Footer/Footer';
// import CustomButton from '../../component/common/Button/Button';
// import { themeRed } from '../../utils/colorTheme';
// // import { CreditCard, PayPal } from '@mui/icons-material';

// const provinces = [
//     { value: 'Province 1', label: 'Province 1' },
//     { value: 'Province 2', label: 'Province 2' },
//     // Add more provinces as needed
// ];

// const areas = [
//     { value: 'Area 1', label: 'Area 1' },
//     { value: 'Area 2', label: 'Area 2' },
//     // Add more areas as needed
// ];

// const CheckOutPage = () => {
//     const [province, setProvince] = React.useState('');
//     const [area, setArea] = React.useState('');

//     const handleProvinceChange = (event) => {
//         setProvince(event.target.value);
//     };

//     const handleAreaChange = (event) => {
//         setArea(event.target.value);
//     };

//     return (
//         <>
//             <Navbar />
//             <Stack sx={{ maxWidth: '1300px', margin: '0 auto', padding: 4, }}>
//                 <Stack maxWidth={"1100px"}>
//                     <Typography fontWeight={"700"} sx={{ fontSize: { xs: "35px", sm: "35px", md: "40px", lg: "40px", xl: "40px" }, }} variant="h4" gutterBottom>
//                         Check Out
//                     </Typography>
//                     <Typography fontWeight={"400"} sx={{ fontSize: { xs: "20px", sm: "20px", md: "25px", lg: "25px", xl: "25px" }, }} variant="subtitle1" gutterBottom>
//                         Kindly Fill Out The Checkout Process Credentials Below.
//                     </Typography>
//                 </Stack>
//             </Stack>
//             <Box sx={{ maxWidth: '1100px', margin: '0 auto', padding: 4, border: '1px solid #ddd', borderRadius: '8px' }}>
//                 {/* Header */}

//                 {/* User Information */}
//                 <Typography variant="h6" fontWeight={"700"} sx={{ fontSize: { xs: "35px", sm: "35px", md: "40px", lg: "40px", xl: "40px" }, }} gutterBottom>
//                     Your Information <Typography component="span" variant="subtitle2">(Optional)</Typography>
//                 </Typography>
//                 <Grid container spacing={2}>
//                     <Grid item xs={12} sm={6}>
//                         <TextField label="First Name" fullWidth required variant="outlined" />
//                     </Grid>
//                     <Grid item xs={12} sm={6}>
//                         <TextField label="Last Name" fullWidth required variant="outlined" />
//                     </Grid>
//                     <Grid item xs={12} sm={6}>
//                         <TextField label="Mobile Number" fullWidth required variant="outlined" />
//                     </Grid>
//                     <Grid item xs={12} sm={6}>
//                         <TextField label="Email Address" fullWidth required variant="outlined" />
//                     </Grid>
//                     <Grid item xs={12} sm={6}>
//                         <TextField label="Landmark (Optional)" fullWidth variant="outlined" />
//                     </Grid>
//                     <Grid item xs={12} sm={6}>
//                         <TextField
//                             select
//                             label="Province"
//                             fullWidth
//                             value={province}
//                             onChange={handleProvinceChange}
//                             variant="outlined"
//                         >
//                             {provinces.map((option) => (
//                                 <MenuItem key={option.value} value={option.value}>
//                                     {option.label}
//                                 </MenuItem>
//                             ))}
//                         </TextField>
//                     </Grid>
//                     <Grid item xs={12} sm={6}>
//                         <TextField
//                             select
//                             label="City"
//                             fullWidth
//                             variant="outlined"
//                         >
//                             {/* Add city options here */}
//                         </TextField>
//                     </Grid>
//                     <Grid item xs={12} sm={6}>
//                         <TextField
//                             select
//                             label="Area"
//                             fullWidth
//                             value={area}
//                             onChange={handleAreaChange}
//                             variant="outlined"
//                         >
//                             {areas.map((option) => (
//                                 <MenuItem key={option.value} value={option.value}>
//                                     {option.label}
//                                 </MenuItem>
//                             ))}
//                         </TextField>
//                     </Grid>
//                 </Grid>

//                 {/* Payment Method */}
//                 <Typography variant="h6" gutterBottom fontWeight={"700"} sx={{ fontSize: { xs: "35px", sm: "35px", md: "40px", lg: "40px", xl: "40px" }, marginTop: 4 }}>
//                     Payment Method
//                 </Typography>
//                 <Grid container spacing={2}>
//                     <Grid item xs={12}>
//                         <TextField
//                             label="Card Number"
//                             fullWidth
//                             variant="outlined"
//                             InputProps={{
//                                 endAdornment: (
//                                     <>
//                                         {/* <PayPal sx={{ marginLeft: 2 }} />
//                   <CreditCard sx={{ marginLeft: 2 }} /> */}
//                                         {/* Add more payment icons here */}
//                                     </>
//                                 ),
//                             }}
//                         />
//                     </Grid>
//                     <Grid item xs={12} sm={6}>
//                         <TextField label="Card Holder Name" fullWidth variant="outlined" />
//                     </Grid>
//                     <Grid item xs={12} sm={3}>
//                         <TextField label="MM/YY" fullWidth variant="outlined" />
//                     </Grid>
//                     <Grid item xs={12} sm={3}>
//                         <TextField label="CVV" fullWidth variant="outlined" />
//                     </Grid>
//                     <Grid item xs={12}>
//                         <FormControlLabel control={<Checkbox />} label="Save Card" />
//                     </Grid>
//                 </Grid>

//                 {/* Referral Code */}
//                 <Typography variant="h6" gutterBottom sx={{ fontSize: { xs: "35px", sm: "35px", md: "40px", lg: "40px", xl: "40px" }, marginTop: 4 }}>
//                     Referral Code <Typography component="span" variant="subtitle2">(Optional)</Typography>
//                 </Typography>
//                 <TextField label="Enter your referral code" fullWidth variant="outlined" />

//                 {/* Proceed to Payment Button */}
//                 <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 4 }}>
//                     {/* <Button variant="contained" color="error" sx={{ paddingX: 4, paddingY: 1.5 }}>
//                         Proceed to Payment
//                     </Button> */}
//                     <CustomButton
//                         style={{
//                             backgroundColor: themeRed,
//                             color: "white",
//                         }} children={"Proceed to Payment"} />
//                 </Box>
//             </Box>

//             <Footer />
//         </>
//     );
// };

// export default CheckOutPage;

import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  Divider,
  Container,
  Avatar,
  Accordion,
  TextField,
  AccordionSummary,
  AccordionDetails,
  ThemeProvider,
  createTheme,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Chip,
} from "@mui/material";
import BookingAccordion from "../../component/BookingAccordion/BookingAccordion";
import Navbar from "../../component/navbar/Navbar";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CustomPagingSlider from "../../component/CustomPagingSlider/CustomPagingSlider";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { book_Trailer_Async } from "../../services/trailers_details_service";
import { asyncStatus } from "../../utils/asyncStatus";
import { setBookingTrailerStatus } from "../../store/slices/trailers_details_slice";
import { IconButton } from "@mui/material";
import { IoMdArrowRoundBack } from "react-icons/io";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { error_toast_message } from "../../utils/toast_message";
import { HiCheckBadge } from "react-icons/hi2";
import { themeRed } from "../../utils/colorTheme";
import { format } from "date-fns";
import td_marchant from "../../assets/td_marchant_img.png";

const stripePromise = loadStripe(
  "pk_test_51P6wjI07SeeusJqEPLXeW0I3dO9JtrB5Lf2T5CfWQAmwswz5XlvNX0JT80pV2xaAiqNYY4bNLfpRD1RvzVaG6wUL003g7Rt4No"
);

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: "#C3282F",
            },
          },
          "& .MuiInputLabel-root": {
            "&.Mui-focused": {
              color: "#C3282F",
            },
          },
        },
      },
    },
  },
});
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: "16px",
      color: "#333",
      "::placeholder": {
        color: "#aab7c4",
      },
      iconColor: "#666EE8",
      backgroundColor: "#fff",
      borderRadius: "4px",
      padding: "10px",
      border: "1px solid #dfe1e5",
    },
    invalid: {
      iconColor: "#e5424d",
      color: "#e5424d",
    },
    complete: {
      iconColor: "#28a745",
    },
  },
};

const CheckOutPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    trailerData,
    numberOfDays,
    dateRange = [],
    pickupTime,
    address,
    pickupTab,
  } = location.state || {};

  const createdAt = trailerData?.host_id?.createdAt;
  const createdDate = createdAt ? createdAt?.substring(0, 10) : null;
  console.log(">>>>>>>>>>>>>>>>>>>", dateRange);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    card_name: "",
    card_number: "",
    exp_month: "",
    city_card: "",
    province: "",
    exp_year: "",
    cvd: "",
    country: "",
    postal_code: "",
    // saveCard: false,
    certify: false,
    address_1: "",
    // "card_name":"John Doe",
    // "card_number":"4030000010001234",
    // "exp_month":"12",
    // "exp_year":"23",
    // "cvd":"123"
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  // const [BookingFrom, BookingTo] = dateRange || [];
  const [BookingFrom, BookingTo] = dateRange?.length
    ? [dateRange[0], dateRange[dateRange?.length - 1]]
    : ["", ""];

  const { user } = useSelector((state) => state.userAuth);
  const { trailer_booking_status } = useSelector(
    (state) => state.trailersDetailsManage
  );

  let booking_type;
  let total_amount;
  let per_day_price;

  if (numberOfDays <= 6) {
    booking_type = "Daily";
    per_day_price = trailerData?.daily_rate;
    total_amount = numberOfDays * trailerData?.daily_rate;
  } else if (numberOfDays > 6 && numberOfDays <= 29) {
    booking_type = "Weekly";
    per_day_price = trailerData?.weekly_rate / 7;
    total_amount = numberOfDays * per_day_price;
  } else if (numberOfDays > 29 && numberOfDays <= 100) {
    booking_type = "Monthly";
    per_day_price = trailerData?.monthly_rate / 30;
    total_amount = numberOfDays * per_day_price;
  }

  let per_day_round = Math.round(per_day_price);

  const isInsurance = localStorage.getItem("insurance");
  // function calculateTotalWithTax(totalAmount, taxPercentage) {
  //   const insuranceRate = isInsurance === "true" ? user?.insurance || "" : 0;
  //   const totalInsurance = totalAmount + insuranceRate;
  //   const taxAmount = (totalInsurance * taxPercentage) / 100;
  //   const totalAmountRound = totalInsurance + taxAmount;
  //   return totalAmountRound;
  // }
  function calculateTotalWithTax(totalAmount, taxPercentage) {
    const insuranceRate = isInsurance === "true" ? user?.insurance || 0 : 0;
    const totalInsurance = totalAmount + insuranceRate;
    const taxAmount = (totalInsurance * taxPercentage) / 100;
    const totalAmountRound = totalInsurance + taxAmount;

    // Format number professionally with currency (USD as an example)
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(totalAmountRound);
  }

  const _numberFormater = (number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  };
  let total_amount_round = +total_amount;

  // const submitHandle = () => {
  //   const obj = {
  //     host_id: trailerData?.host_id?._id,
  //     trailer_id: trailerData?._id,
  //     start_date: BookingFrom,
  //     end_date: BookingTo,
  //     pickup_or_delivery: pickupTab,
  //     time: pickupTime,
  //     address: address,
  //     booking_type: booking_type,
  //     booking_amount: total_amount_round,
  //     per_day_price:per_day_round,
  //     no_of_days: numberOfDays,
  //     status: "In-Process",
  //     card_name: formData.card_name,
  //     card_number: formData.card_number,
  //     exp_month: formData.exp_month,
  //     exp_year: formData.exp_year,
  //     cvd: formData.cvd,
  //   };

  //   dispatch(book_Trailer_Async(obj));
  //   // console.log("obj", obj);
  // };

  const submitHandle = () => {
    const {
      card_name,
      card_number,
      exp_month,
      exp_year,
      cvd,
      city_card,
      country,
      postal_code,
      province,
    } = formData;

    // // Card information validations
    // if (!card_name || card_name.trim() === "") {
    //   error_toast_message("Cardholder's name is required.");
    //   return;
    // }

    if (!province.trim()) {
      error_toast_message("province is required");
      return;
    }
    if (!postal_code.trim()) {
      error_toast_message("postal code is required");
      return;
    }
    if (!country.trim()) {
      error_toast_message("Country is required");
      return;
    }
    if (!city_card.trim()) {
      error_toast_message("City is required");
      return;
    }

    // Cardholder Name Validation
    if (!card_name.trim()) {
      error_toast_message("Cardholder's name is required");
      return;
    }
    if (!/^[a-zA-Z\s]+$/.test(card_name)) {
      error_toast_message("Card name should only contain letters and spaces");
      return;
    }

    if (!card_number || card_number.length !== 16 || isNaN(card_number)) {
      error_toast_message("Card number must be 16 digits.");
      return;
    }

    // Card Number Validation
    const cleanedCardNumber = card_number.replace(/\s+/g, "");
    if (
      !cleanedCardNumber ||
      cleanedCardNumber.length !== 16 ||
      !/^\d+$/.test(cleanedCardNumber)
    ) {
      error_toast_message("Invalid card number (must be 16 digits)");
      return;
    }

    // Luhn Algorithm Validation
    // const isCardValid = (cardNum) => {
    //   let sum = 0;
    //   let shouldDouble = false;
    //   for (let i = cardNum.length - 1; i >= 0; i--) {
    //     let digit = parseInt(cardNum.charAt(i), 10);
    //     if (shouldDouble) {
    //       if ((digit *= 2) > 9) digit -= 9;
    //     }
    //     sum += digit;
    //     shouldDouble = !shouldDouble;
    //   }
    //   return sum % 10 === 0;
    // };
    // if (!isCardValid(cleanedCardNumber)) {
    //   error_toast_message("Invalid card number");
    //   return;
    // }

    if (!cvd) {
      error_toast_message("CVC is required.");
      return;
    }

    // CVD Validation
    // if (!cvd || !/^\d{3}$/.test(cvd)) {
    //   error_toast_message("CVC must be a 3-digit number");
    //   return;
    // }

    if (
      !exp_month ||
      exp_month.length !== 2 ||
      isNaN(exp_month) ||
      exp_month < 1 ||
      exp_month > 12
    ) {
      error_toast_message(
        "Expiry month must be a valid 2-digit number between 01 and 12."
      );
      return;
    }

    if (!exp_year || exp_year.length !== 2 || isNaN(exp_year)) {
      error_toast_message("Expiry year must be a valid 2-digit number.");
      return;
    }

    // Expiration Date Validation
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1; // Months are 0-based in JS

    if (!exp_month || !/^(0[1-9]|1[0-2])$/.test(exp_month)) {
      error_toast_message("Invalid expiration month (MM format required)");
      return;
    }

    if (!exp_year || !/^\d{2}$/.test(exp_year)) {
      error_toast_message("Invalid expiration year (YY format required)");
      return;
    }

    // Convert 2-digit year to full year
    const fullExpYear = 2000 + parseInt(exp_year, 10);
    const expMonth = parseInt(exp_month, 10);

    // Check if card is expired
    if (
      fullExpYear < currentYear ||
      (fullExpYear === currentYear && expMonth < currentMonth)
    ) {
      error_toast_message("Card has expired");
      return;
    }

    // Certification Check
    // if (!formData.certify) {
    //   error_toast_message(
    //     "You must certify that you meet the age and license requirements"
    //   );
    //   return;
    // }

    // If all validations pass, proceed with booking
    const obj = {
      host_id: trailerData?.host_id?._id,
      trailer_id: trailerData?._id,
      start_date: BookingFrom,
      end_date: BookingTo,
      pickup_or_delivery: pickupTab,
      time: pickupTime,
      // address: address,
      booking_type: booking_type,
      booking_amount: calculateTotalWithTax(total_amount_round, user?.tax),
      // booking_amount: 1,
      per_day_price: per_day_round,
      no_of_days: numberOfDays,
      status: "In-Process",
      card_name,
      card_number,
      exp_month,
      exp_year,
      cvd,
      province: province,
      city: city_card,
      country: country,
      postal_code: postal_code,
      address_1: formData.address_1,
    };
    console.log(obj);
    // dispatch(book_Trailer_Async(obj));
  };

  useEffect(() => {
    if (trailer_booking_status === asyncStatus.SUCCEEDED) {
      navigate("/my-booking-user");
      dispatch(setBookingTrailerStatus());
    }
  }, [trailer_booking_status]);

  const redirectData = JSON.parse(sessionStorage.getItem("trailer_id"));
  const handleBack = () => {
    if (redirectData?.state) {
      navigate(`/trailer-book-details/${redirectData?.state}`);
      sessionStorage.removeItem("trailer_id");
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <Navbar />
      <Container>
        <IconButton onClick={handleBack}>
          <IoMdArrowRoundBack />
        </IconButton>
        <Grid
          container
          spacing={3}
          justifyContent="center"
          sx={{ padding: "20px" }}
        >
          {/* Left Section (Trailer Info + Form) */}
          <Grid item xs={12} md={8}>
            <Card>
              <CardContent>
                {/* Trailer Info */}
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "600", fontSize: "22px" }}
                >
                  Trailer Info
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mt: 2,
                  }}
                >
                  <div>
                    <Typography variant="body1">
                      <b> {trailerData?.title} Trailer</b>
                    </Typography>
                    <Typography variant="body2">
                      Hitch Type: {trailerData?.hitch_type}
                    </Typography>
                    <Typography variant="body2">
                      Payload Capacity: {trailerData?.payload_capacity}
                    </Typography>
                    <Typography variant="body2">
                      Trailer Dimensions:{" "}
                      {trailerData?.rear_door_opening_height} x{" "}
                      {trailerData?.trailer_width}
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="body2">
                      Booking Date: {dateRange}
                    </Typography>
                    <Typography variant="body2">
                      ${per_day_round}/per day
                    </Typography>
                  </div>
                </Box>
                <Divider sx={{ my: 2 }} />

                {/* Owner Info */}

                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  gap={3}
                  my={1}
                >
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "600", fontSize: "22px" }}
                  >
                    Owner Info
                  </Typography>{" "}
                  {trailerData?.host_id?.kyc === "Completed" &&
                    trailerData?.host_id?.status === "Approved" && (
                      <Chip
                        label="Verified"
                        icon={
                          <HiCheckBadge
                            style={{ color: "green", fontSize: "18px" }}
                          />
                        }
                        sx={{
                          backgroundColor: "greenyellow",
                          color: "green",
                          fontWeight: 500,
                          "& .MuiChip-icon": { ml: 0.5 },
                        }}
                      />
                    )}
                </Stack>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mt: 2,
                  }}
                >
                  <div>
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: "500", fontSize: "18px" }}
                    >
                      {trailerData?.host_id?.first_name}{" "}
                      {trailerData?.host_id?.last_name[0]}.
                    </Typography>

                    {/* <Typography
                      sx={{
                        fontWeight: "400",
                        fontSize: "15px",
                        color: "gray",
                      }}
                    >
                      Email:{trailerData?.host_id?.email}
                    </Typography> */}

                    <Typography
                      sx={{
                        fontWeight: "400",
                        fontSize: "15px",
                        color: "gray",
                      }}
                    >
                      Joined on: {format(createdDate, "MMM dd, yyyy")}
                    </Typography>
                  </div>
                  <div>
                    <Avatar
                      sx={{ width: "80px", height: "80px" }}
                      src={trailerData?.host_id?.avatar}
                    >
                      {trailerData?.host_id?.first_name[0]}
                    </Avatar>
                  </div>
                </Box>
                <Divider sx={{ my: 2 }} />
                {/* <Elements stripe={stripePromise}>
                  <BookingAccordion user={user} onClick={submitHandle} />
                </Elements> */}
                <ThemeProvider theme={theme}>
                  <Box sx={{ width: "100%", marginTop: "20px" }}>
                    {/* <form onSubmit={handleSubmit}> */}
                    <div>
                      {/* Begin Your Journey Section */}
                      <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography
                            variant="h6"
                            sx={{ fontWeight: "600", fontSize: "22px" }}
                          >
                            1. Begin Your Journey
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6}>
                              <TextField
                                fullWidth
                                label="First Name"
                                name="first_name"
                                margin="normal"
                                value={user?.first_name}
                                defaultValue={user?.first_name}
                                disabled
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                              <TextField
                                fullWidth
                                label="Last Name"
                                name="lastName"
                                margin="normal"
                                value={user?.last_name}
                                defaultValue={user?.last_name}
                                disabled
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                              <TextField
                                fullWidth
                                label="Email"
                                name="email"
                                margin="normal"
                                value={user?.email}
                                defaultValue={user?.email}
                                disabled
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                              <TextField
                                fullWidth
                                label="Phone Number"
                                name="phoneNumber"
                                margin="normal"
                                value={user?.phonenumber}
                                defaultValue={user?.phonenumber}
                                disabled
                              />
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>

                      {/* Confirm & Pay Section */}
                      <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          {/* <Typography variant="h6">2. Confirm & Pay</Typography> */}
                          <Typography
                            variant="h5"
                            sx={{ fontWeight: "600", fontSize: "22px" }}
                          >
                            2. Confirm & Pay
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Stack my={2}>
                            <img
                              src={td_marchant}
                              style={{
                                height: "90px",
                                width: "140px",
                                objectFit: "none",
                              }}
                            />
                          </Stack>
                          <Grid container spacing={2} mb={2}>
                            <Grid item xs={12} sm={12} md={6}>
                              <TextField
                                fullWidth
                                label="Card Holder Name"
                                name="card_name"
                                margin="normal"
                                value={formData.card_name}
                                onChange={handleInputChange}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                              <TextField
                                fullWidth
                                label="Card Number"
                                name="card_number"
                                margin="normal"
                                type="number"
                                value={formData.card_number}
                                onChange={handleInputChange}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                              <TextField
                                fullWidth
                                label="Card Expiry Month"
                                name="exp_month"
                                margin="normal"
                                value={formData.exp_month}
                                onChange={handleInputChange}
                                placeholder="MM"
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                              <TextField
                                fullWidth
                                label="Card Expiry Year"
                                name="exp_year"
                                margin="normal"
                                value={formData.exp_year}
                                onChange={handleInputChange}
                                placeholder="YY"
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                              <TextField
                                fullWidth
                                label="CVC/CVV"
                                name="cvd"
                                margin="normal"
                                value={formData.cvd}
                                onChange={handleInputChange}
                                placeholder="123"
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                              <TextField
                                fullWidth
                                label="Compelete Address"
                                name="address_1"
                                margin="normal"
                                value={formData.address_1}
                                onChange={handleInputChange}
                                placeholder="Enter complete address"
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                              <TextField
                                fullWidth
                                label="City"
                                name="city_card"
                                margin="normal"
                                value={formData.city_card}
                                onChange={handleInputChange}
                                placeholder="city"
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                              <TextField
                                fullWidth
                                label="Province"
                                name="province"
                                margin="normal"
                                value={formData.province}
                                onChange={handleInputChange}
                                placeholder="province"
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                              <TextField
                                fullWidth
                                label="postal code"
                                name="postal_code"
                                margin="normal"
                                value={formData.postal_code}
                                onChange={handleInputChange}
                                placeholder="postal code"
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                              <TextField
                                fullWidth
                                label="Country"
                                name="country"
                                margin="normal"
                                value={formData.country}
                                onChange={handleInputChange}
                                placeholder="country"
                              />
                            </Grid>
                          </Grid>

                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={formData.certify}
                                onChange={handleInputChange}
                                name="certify"
                                color="error"
                              />
                            }
                            label="I certify that I am at least 19 years old and have a valid driver’s license."
                          />
                          <Button
                            variant="contained"
                            fullWidth
                            color="error"
                            type="submit"
                            onClick={submitHandle}
                            // disabled={!formData.certify}
                          >
                            {trailer_booking_status === asyncStatus.LOADING
                              ? "Loading..."
                              : "Confirm & Pay"}
                          </Button>
                        </AccordionDetails>
                      </Accordion>
                      {/* </form> */}
                    </div>
                  </Box>
                </ThemeProvider>
              </CardContent>
            </Card>
          </Grid>

          {/* Right Section (Order Summary) */}
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <CustomPagingSlider apiImages={trailerData?.trailer_photos} />
                <Divider sx={{ my: 1 }} />
                {/* Booking Description */}
                <Typography
                  variant="h6"
                  align="center"
                  mt={1}
                  sx={{ fontWeight: "600", fontSize: "22px" }}
                >
                  {trailerData?.title} Trailer
                </Typography>

                {/* <Box mt={2}>
                  <Typography variant="body2">
                    <strong>Booking From:</strong> {BookingFrom}, {pickupTime}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Booking To:</strong> {BookingTo}, {pickupTime}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Rent (Day):</strong> ${per_day_round}.00
                  </Typography>
                  <Typography variant="body2">
                    <strong>No of Days:</strong> {numberOfDays}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Booking Type:</strong> {booking_type}
                  </Typography>
                </Box> */}
                <Box
                  mt={2}
                  p={2}
                  border={1}
                  borderColor="grey.300"
                  borderRadius={2}
                  boxShadow={2}
                  bgcolor="background.paper"
                >
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold", mb: 1, color: themeRed }}
                  >
                    Booking Details
                  </Typography>

                  <Box
                    sx={{ display: "flex", flexDirection: "column", gap: 1 }}
                  >
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary" }}
                    >
                      <strong>Booking From:</strong>{" "}
                      <span style={{ color: "#333" }}>
                        {BookingFrom}, {pickupTime}
                      </span>
                    </Typography>

                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary" }}
                    >
                      <strong>Booking To:</strong>{" "}
                      <span style={{ color: "#333" }}>
                        {BookingTo}, {pickupTime}
                      </span>
                    </Typography>

                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary" }}
                    >
                      <strong>Rent (Day):</strong>{" "}
                      <span style={{ color: "#2E7D32", fontWeight: "bold" }}>
                        ${per_day_round}.00
                      </span>
                    </Typography>

                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary" }}
                    >
                      <strong>No of Days:</strong>{" "}
                      <span style={{ fontWeight: "bold" }}>{numberOfDays}</span>
                    </Typography>

                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary" }}
                    >
                      <strong>Booking Type:</strong>{" "}
                      <span
                        style={{
                          fontWeight: "bold",
                          textTransform: "capitalize",
                        }}
                      >
                        {booking_type}
                      </span>
                    </Typography>
                  </Box>
                </Box>

                <Divider sx={{ my: 2 }} />
                {/* Order Summary */}
                <Box
                  // mt={3}
                  p={3}
                  border={1}
                  borderColor="grey.300"
                  borderRadius={2}
                  boxShadow={2}
                  bgcolor="background.paper"
                >
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold", color: themeRed, mb: 2 }}
                  >
                    Order Summary
                  </Typography>

                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary" }}
                    >
                      <strong>Rent Amount:</strong>{" "}
                      <span style={{ color: "#333", fontWeight: "bold" }}>
                        {_numberFormater(total_amount_round)}
                      </span>
                    </Typography>

                    {/* Uncomment these if needed */}
                    {/* <Typography variant="body2" sx={{ color: "text.secondary" }}>
      <strong>Deposit Amount:</strong> <span style={{ color: "#333" }}>$150.00</span>
    </Typography>
    <Typography variant="body2" sx={{ color: "text.secondary" }}>
      <strong>Deposit Processing Fee:</strong> <span style={{ color: "#333" }}>$4.50</span>
    </Typography>
    <Typography variant="body2" sx={{ color: "text.secondary" }}>
      <strong>Platform Charges:</strong> <span style={{ color: "#333" }}>$40.00</span>
    </Typography> */}

                    {isInsurance === "true" && (
                      <Typography
                        variant="body2"
                        sx={{ color: "text.secondary", mt: 1 }}
                      >
                        <strong>Insurance:</strong>{" "}
                        <span style={{ color: "#d32f2f", fontWeight: "bold" }}>
                          ${user?.insurance || ""}
                        </span>
                      </Typography>
                    )}
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary", mt: 1 }}
                    >
                      <strong>Tax:</strong>{" "}
                      <span style={{ color: "#d32f2f", fontWeight: "bold" }}>
                        {user?.tax || ""}%
                      </span>
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        mt: 2,
                        color: "#2E7D32",
                        fontWeight: "bold",
                      }}
                    >
                      <strong>Grand Total:</strong>
                      <span style={{ color: "#2E7D32" }}>
                        {" "}
                        {calculateTotalWithTax(total_amount_round, user?.tax)}
                      </span>
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default CheckOutPage;

{
  /* <Grid item xs={12} sm={12} md={6}>
                  <Typography variant="body1">Payment Card Information</Typography>
                  <Box
                    sx={{
                      padding: '10px',
                      border: '1px solid #dfe1e5',
                      borderRadius: '4px',
                      marginTop: '10px',
                    }}
                  >
                    <CardElement options={CARD_ELEMENT_OPTIONS} />
                  </Box>
                </Grid> */
}
// Roshaan Faisal
// Card Holder Name

// 5526124001846320
// Card Number
// 06
// Card Expiry Month
// 27
// Card Expiry Year
// 346
// CVC/CVV
// Mississauga
// City
// ON
// Province
// L5J4A2
// postal code
// CA
// Country
