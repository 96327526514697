import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { asyncStatus, save_tokens_constant } from "../../utils/asyncStatus";
import {
  error_toast_message,
  success_toast_message,
} from "../../utils/toast_message";
import {
  check_auth,
  contactUsFormAsync,
  dashboard_api_async,
  forgotPasswordChangeAsync,
  get_all_trailer_async,
  get_services,
  get_signup_services,
  getProvinceListAsync,
  kyc_verify_async,
  login_service_auth,
  resend_otp_async,
  resetPasswordAsync,
  resetPasswordOTPAsync,
  signup_service_auth,
  signup_vendor_auth,
  update_profile_verify_async,
  verify_otp_async,
} from "../../services/authentication";
const initialState = {
  // status
  check_auth_status: asyncStatus.IDLE,
  user_register_status: asyncStatus.IDLE,
  vendor_register_status: asyncStatus.IDLE,
  user_login_status: asyncStatus.IDLE,
  user_logout_status: asyncStatus.IDLE,
  user_profile_status: asyncStatus.IDLE,
  get_services_for_signup_status: asyncStatus.IDLE,
  get_all_services_status: asyncStatus.IDLE,
  otp_status: asyncStatus.IDLE,
  resend_otp_status: asyncStatus.IDLE,
  kyc_verify_status: asyncStatus.IDLE,
  update_profile_status: asyncStatus.IDLE,
  dashboard_api_status: asyncStatus.IDLE,
  get_all_trailers_status: asyncStatus.IDLE,

  // data
  userAuth: false,
  user: null,
  get_services_for_signup_data: null,
  get_all_services_data: null,
  authTokens: null,
  user_profile: null,
  otp_data: null,
  resend_otp_data: null,
  kyc_verify_data: null,
  update_profile_data: null,
  dashboard_api_data: null,
  get_all_trailers_data: null,
  profile_status: 0,
  role: "",

  // error
  check_auth_error: null,
  user_register_error: null,
  vendor_register_error: null,
  user_login_error: null,
  user_logout_error: null,
  user_profile_error: null,
  get_services_for_signup_error: null,

  edit_profile_status: asyncStatus.IDLE,
  edit_user_profile: null,
  edit_user_profile_error: null,
  get_all_services_error: null,
  otp_error: null,
  resend_otp_error: null,
  kyc_verify_error: null,
  update_profile_error: null,
  dashboard_api_error: null,
  get_all_trailers_error: null,

  // for only booking value change
  pageValue: "1",
  userRole: null,
  // for only booking value change

  reset_password_status: asyncStatus.IDLE,
  reset_password_data: null,
  reset_password_error: null,

  reset_password_otp_verify_status: asyncStatus.IDLE,
  reset_password_otp_verify_data: null,
  reset_password_otp_verify_error: null,

  forgot_password_changes_status: asyncStatus.IDLE,
  forgot_password_changes_data: null,
  forgot_password_changes_error: null,

  contact_us_form_status: asyncStatus.IDLE,
  contact_us_form_data: null,
  contact_us_form_error: null,

  get_all_province_status: asyncStatus.IDLE,
  get_all_province_data: null,
  get_all_province_error: null,
};

const user_auth_slice = createSlice({
  name: "userAuth",
  initialState,

  reducers: {
    setIdleStatus(state) {
      state.user_logout_status = asyncStatus.IDLE;
    },
    setIdleRegisterStatus(state) {
      state.user_register_status = asyncStatus.IDLE;
    },
    setIdleOtpStatus(state) {
      state.otp_status = asyncStatus.IDLE;
    },
    setIdleUpdateProfileStatus(state) {
      state.update_profile_status = asyncStatus.IDLE;
    },
    setIdleKycStatus(state) {
      state.kyc_verify_status = asyncStatus.IDLE;
    },
    setUserRole(state, { payload }) {
      state.role = payload;
    },
    // for only booking value change
    setpageValue(state, { payload }) {
      state.pageValue = payload;
    },
    setAuthState(state, { payload }) {
      state.userAuth = payload;
      state.check_auth_status = asyncStatus.SUCCEEDED;
    },
    // for only booking value change
  },
  extraReducers: (builder) => {
    builder.addCase(login_service_auth.pending, (state, action) => {
      state.user_login_status = asyncStatus.LOADING;
    });

    builder.addCase(login_service_auth.fulfilled, (state, { payload }) => {
      state.authTokens = payload.tokens?.access_token;
      state.user_login_status = asyncStatus.SUCCEEDED;
      state.user = payload.data;
      state.userAuth = true;
      success_toast_message("Login Successfully");
      state.user_login_error = null;
      localStorage.setItem(
        save_tokens_constant.AUTH,
        payload.tokens?.access_token
      );
    });

    builder.addCase(login_service_auth.rejected, (state, action) => {
      state.user_login_status = asyncStatus.ERROR;
      // state.userAuth = false;
      state.user_login_error = action.error;
      error_toast_message(action.error.message);
    });

    // check auth ========================>

    builder.addCase(check_auth.pending, (state, action) => {
      state.check_auth_status = asyncStatus.LOADING;
    });

    builder.addCase(check_auth.fulfilled, (state, { payload }) => {
      // state.authTokens = payload.data.token;
      const { success, data } = payload;

      state.check_auth_status = asyncStatus.SUCCEEDED;
      if (success) {
        const { role } = data;
        state.user = data;
        state.role = role;
        state.userAuth = true;
      } else {
        state.userAuth = false;
      }
    });

    builder.addCase(check_auth.rejected, (state, action) => {
      state.check_auth_status = asyncStatus.ERROR;
      state.check_auth_error = action.error;
      error_toast_message(action.error.message);
    });

    //  Signup =========>>>>>>>>>>>
    builder.addCase(signup_service_auth.pending, (state, action) => {
      state.user_register_status = asyncStatus.LOADING;
    });

    builder.addCase(signup_service_auth.fulfilled, (state, { payload }) => {
      state.authTokens = payload.tokens?.access_token;
      state.user_register_status = asyncStatus.SUCCEEDED;
      state.user = payload.data;
      state.userAuth = true;
      success_toast_message("Signup Successfully");
      state.user_register_error = null;
      localStorage.setItem(
        save_tokens_constant.AUTH,
        payload.tokens?.access_token
      );
    });

    builder.addCase(signup_service_auth.rejected, (state, action) => {
      state.user_register_status = asyncStatus.ERROR;
      state.user_register_error = action.error;
      error_toast_message(action.error.message);
    });

    //  otp  verification =========>>>>>>>>>>>

    builder.addCase(verify_otp_async.pending, (state, action) => {
      state.otp_status = asyncStatus.LOADING;
    });

    builder.addCase(verify_otp_async.fulfilled, (state, { payload }) => {
      state.otp_status = asyncStatus.SUCCEEDED;
      state.otp_data = payload.data;
      success_toast_message("Verification Successfully");
    });

    builder.addCase(verify_otp_async.rejected, (state, action) => {
      state.otp_status = asyncStatus.ERROR;
      state.otp_error = action.error;
      error_toast_message(action.error.message);
    });

    // Resend otp  verification =========>>>>>>>>>>>

    builder.addCase(resend_otp_async.pending, (state, action) => {
      state.resend_otp_status = asyncStatus.LOADING;
    });

    builder.addCase(resend_otp_async.fulfilled, (state, { payload }) => {
      state.resend_otp_status = asyncStatus.SUCCEEDED;
      state.resend_otp_data = payload.data;
      success_toast_message("Otp Resend Successfully");
    });

    builder.addCase(resend_otp_async.rejected, (state, action) => {
      state.resend_otp_status = asyncStatus.ERROR;
      state.resend_otp_error = action.error;
      error_toast_message(action.error.message);
    });

    // Resend otp  verification =========>>>>>>>>>>>

    builder.addCase(kyc_verify_async.pending, (state, action) => {
      state.kyc_verify_status = asyncStatus.LOADING;
    });

    builder.addCase(kyc_verify_async.fulfilled, (state, { payload }) => {
      state.kyc_verify_status = asyncStatus.SUCCEEDED;
      state.kyc_verify_data = payload.data;
      success_toast_message(payload.message);
    });

    builder.addCase(kyc_verify_async.rejected, (state, action) => {
      state.kyc_verify_status = asyncStatus.ERROR;
      state.kyc_verify_error = action.error;
      error_toast_message(action.error.message);
    });

    // Update Profile =========>>>>>>>>>>>

    builder.addCase(update_profile_verify_async.pending, (state, action) => {
      state.update_profile_status = asyncStatus.LOADING;
    });

    builder.addCase(
      update_profile_verify_async.fulfilled,
      (state, { payload }) => {
        state.update_profile_status = asyncStatus.SUCCEEDED;
        state.update_profile_data = payload.data;
        success_toast_message("Profile Update Successfully");
      }
    );

    builder.addCase(update_profile_verify_async.rejected, (state, action) => {
      state.update_profile_status = asyncStatus.ERROR;
      state.update_profile_error = action.error;
      error_toast_message(action.error.message);
    });

    // Get Dashboard Api =========>>>>>>>>>>>

    builder.addCase(dashboard_api_async.pending, (state, action) => {
      state.dashboard_api_status = asyncStatus.LOADING;
    });

    builder.addCase(dashboard_api_async.fulfilled, (state, { payload }) => {
      state.dashboard_api_status = asyncStatus.SUCCEEDED;
      state.dashboard_api_data = payload;
    });

    builder.addCase(dashboard_api_async.rejected, (state, action) => {
      state.dashboard_api_status = asyncStatus.ERROR;
      state.dashboard_api_error = action.error;
      error_toast_message(action.error.message);
    });

    // Get Dashboard Api =========>>>>>>>>>>>

    builder.addCase(get_all_trailer_async.pending, (state, action) => {
      state.get_all_trailers_status = asyncStatus.LOADING;
    });

    builder.addCase(get_all_trailer_async.fulfilled, (state, { payload }) => {
      state.get_all_trailers_status = asyncStatus.SUCCEEDED;
      state.get_all_trailers_data = payload;
    });

    builder.addCase(get_all_trailer_async.rejected, (state, action) => {
      state.get_all_trailers_status = asyncStatus.ERROR;
      state.get_all_trailers_error = action.error;
      error_toast_message(action.error.message);
    });

    //  Signup Vendor=========>>>>>>>>>>>
    builder.addCase(signup_vendor_auth.pending, (state, action) => {
      state.vendor_register_status = asyncStatus.LOADING;
    });

    builder.addCase(signup_vendor_auth.fulfilled, (state, { payload }) => {
      state.authTokens = payload.tokens?.access_token;
      state.vendor_register_status = asyncStatus.SUCCEEDED;
      state.user = payload.data;
      state.userAuth = true;
      success_toast_message("Vendor Signup Successfully");
      state.vendor_register_error = null;
      localStorage.setItem(
        save_tokens_constant.AUTH,
        payload.tokens?.access_token
      );
    });

    builder.addCase(signup_vendor_auth.rejected, (state, action) => {
      state.vendor_register_status = asyncStatus.ERROR;
      state.vendor_register_error = action.error;
      // console.log("action.error=====>>>>>",action.error);
      error_toast_message(action.error.message);
    });

    //  Signup Services=========>>>>>>>>>>>
    builder.addCase(get_signup_services.pending, (state, action) => {
      state.get_services_for_signup_status = asyncStatus.LOADING;
    });

    builder.addCase(get_signup_services.fulfilled, (state, { payload }) => {
      state.get_services_for_signup_status = asyncStatus.SUCCEEDED;
      state.get_services_for_signup_data = payload.data;
      state.get_services_for_signup_error = null;
    });

    builder.addCase(get_signup_services.rejected, (state, action) => {
      state.get_services_for_signup_status = asyncStatus.ERROR;
      state.get_services_for_signup_error = action.error;
      // console.log("action.error=====>>>>>",action.error);
      error_toast_message(action.error.message);
    });

    //  Signup Services=========>>>>>>>>>>>
    builder.addCase(get_services.pending, (state, action) => {
      state.get_all_services_status = asyncStatus.LOADING;
    });

    builder.addCase(get_services.fulfilled, (state, { payload }) => {
      state.get_all_services_status = asyncStatus.SUCCEEDED;
      state.get_all_services_data = payload.data;
      state.get_all_services_error = null;
    });

    builder.addCase(get_services.rejected, (state, action) => {
      state.get_all_services_status = asyncStatus.ERROR;
      state.get_all_services_error = action.error;
      // console.log("action.error=====>>>>>",action.error);
      error_toast_message(action.error.message);
    });

    //  reset password=========>>>>>>>>>>>
    builder.addCase(resetPasswordAsync.pending, (state, action) => {
      state.reset_password_status = asyncStatus.LOADING;
    });

    builder.addCase(resetPasswordAsync.fulfilled, (state, { payload }) => {
      state.reset_password_status = asyncStatus.SUCCEEDED;
      state.reset_password_data = payload.data;
      success_toast_message(payload.message);
    });

    builder.addCase(resetPasswordAsync.rejected, (state, action) => {
      state.reset_password_status = asyncStatus.ERROR;
      state.reset_password_error = action.error;
      error_toast_message(action.error.message);
    });

    //  reset password OTP VERIFY =========>>>>>>>>>>>
    builder.addCase(resetPasswordOTPAsync.pending, (state, action) => {
      state.reset_password_otp_verify_status = asyncStatus.LOADING;
    });

    builder.addCase(resetPasswordOTPAsync.fulfilled, (state, { payload }) => {
      state.reset_password_otp_verify_status = asyncStatus.SUCCEEDED;
      state.reset_password_otp_verify_data = payload.data;
      success_toast_message(payload.message);
    });

    builder.addCase(resetPasswordOTPAsync.rejected, (state, action) => {
      state.reset_password_otp_verify_status = asyncStatus.ERROR;
      state.reset_password_otp_verify_error = action.error;
      error_toast_message(action.error.message);
    });

    //  forgot password changes =========>>>>>>>>>>>
    builder.addCase(forgotPasswordChangeAsync.pending, (state, action) => {
      state.forgot_password_changes_status = asyncStatus.LOADING;
    });

    builder.addCase(
      forgotPasswordChangeAsync.fulfilled,
      (state, { payload }) => {
        state.forgot_password_changes_status = asyncStatus.SUCCEEDED;
        state.forgot_password_changes_data = payload.data;
        success_toast_message(payload.message);
      }
    );

    builder.addCase(forgotPasswordChangeAsync.rejected, (state, action) => {
      state.forgot_password_changes_status = asyncStatus.ERROR;
      state.forgot_password_changes_error = action.error;
      error_toast_message(action.error.message);
    });

    //  forgot password changes =========>>>>>>>>>>>
    builder.addCase(contactUsFormAsync.pending, (state, action) => {
      state.contact_us_form_status = asyncStatus.LOADING;
    });

    builder.addCase(contactUsFormAsync.fulfilled, (state, { payload }) => {
      state.contact_us_form_status = asyncStatus.SUCCEEDED;
      state.contact_us_form_data = payload.data;
      success_toast_message(payload.message);
    });

    builder.addCase(contactUsFormAsync.rejected, (state, action) => {
      state.contact_us_form_status = asyncStatus.ERROR;
      state.contact_us_form_error = action.error;
      error_toast_message(action.error.message);
    });

    //  get all provinces =========>>>>>>>>>>>
    builder.addCase(getProvinceListAsync.pending, (state, action) => {
      state.get_all_province_status = asyncStatus.LOADING;
    });

    builder.addCase(getProvinceListAsync.fulfilled, (state, { payload }) => {
      state.get_all_province_status = asyncStatus.SUCCEEDED;
      state.get_all_province_data = payload.data;
      success_toast_message(payload.message);
    });

    builder.addCase(getProvinceListAsync.rejected, (state, action) => {
      state.get_all_province_status = asyncStatus.ERROR;
      state.get_all_province_error = action.error;
      error_toast_message(action.error.message);
    });
  },
});

export const {
  setUserRole,
  setIdleStatus,
  setIdleUpdateProfileStatus,
  setIdleOtpStatus,
  setIdleKycStatus,
  setpageValue,
  setIdleRegisterStatus,
  setAuthState,
} = user_auth_slice.actions;

export default user_auth_slice.reducer;
