import React, { useEffect, useState } from "react";
import { Grid, Stack, Typography, Box } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { themeRed } from "../../utils/colorTheme";
import { useNavigate } from "react-router-dom";
import { apiHandle } from "../../config/apiHandle/apiHandle";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { asyncStatus } from "../../utils/asyncStatus";
import { getProvinceListAsync } from "../../services/authentication";

// // Array of states data
// Corrected Array of Canadian Provinces and Territories with ISO 3166-2:CA Codes
export const states = [
  {
    name: "Alberta",
    code: "AB",
  },
  {
    name: "British Columbia",
    code: "BC",
  },
  {
    name: "Manitoba",
    code: "MB",
  },
  {
    name: "New Brunswick",
    code: "NB",
  },
  {
    name: "Newfoundland and Labrador",
    code: "NL",
  },
  {
    name: "Northwest Territories",
    code: "NT",
  },
  {
    name: "Nova Scotia",
    code: "NS",
  },
  {
    name: "Nunavut",
    code: "NU",
  },
  {
    name: "Ontario",
    code: "ON",
  },
  {
    name: "Prince Edward Island",
    code: "PE",
  },
  {
    name: "Quebec",
    code: "QC",
  },
  {
    name: "Saskatchewan",
    code: "SK",
  },
  {
    name: "Yukon",
    code: "YT",
  },
];

// Functional Component
const StateGrid = ({ category }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { get_all_province_status, get_all_province_data } = useSelector(
    (state) => state.userAuth
  );

  const handleStateClick = (stateName) => {
    const matchedState = states.find((state) => state.name === stateName);
    const stateCode = matchedState ? matchedState.code : null;
    console.log("matchedState", stateCode);

    navigate("/trailer-map", {
      state: { name: category, location_state: stateCode },
    });
  };

  const loading = get_all_province_status === asyncStatus.LOADING;

  useEffect(() => {
    dispatch(getProvinceListAsync());
  }, []);

  return (
    <div className="container">
      <Stack
        sx={{
          fontSize: 35,
          fontWeight: "bold",
          color: "#000",
        }}
        mb={3}
      >
        {/* SEARCH BY STATE */}
        Search By Province
      </Stack>
      <Grid container spacing={3}>
        {loading ? (
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress color="error" />
          </Stack>
        ) : (
          get_all_province_data?.map((province, index) => {
            const isActive = province.status === "Active";
            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => handleStateClick(province.name)}
              >
                <LocationOnIcon
                  style={{ color: themeRed, marginRight: "8px" }}
                />
                <Typography
                  variant="body1"
                  style={{ textTransform: "capitalize" }}
                >
                  {province.name}
                </Typography>
              </Grid>
            );
          })
        )}
        {/* {states.map((state, index) => ({ */}
        {/* <Grid
            item
            xs={12}
            sm={6}
            md={3}
            lg={2}
            key={index}
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => handleStateClick(state.code)}
          >
            <LocationOnIcon style={{ color: themeRed, marginRight: "8px" }} />
            <Typography variant="body1" style={{ textTransform: "capitalize" }}>
              {state.name}
            </Typography>
          </Grid>  */}
        {/* }))} */}
      </Grid>
    </div>
  );
};

export default StateGrid;
