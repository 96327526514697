import {
  Stack,
  Typography,
  Checkbox,
  FormControlLabel,
  Link,
  Fade,
  Collapse,
} from "@mui/material";
import { useState, useEffect } from "react";
import { themeRed } from "../../utils/colorTheme";

const TermsConditionCheck = ({ onChange }) => {
  const [checked, setChecked] = useState([false, false, false]);
  const [mounted, setMounted] = useState(false);

  const policyPaths = {
    "Terms & Conditions": "/terms-and-conditions",
    "Privacy Policy": "/privacy-policy",
    "Cancellation Policy": "/cancellation-policy",
  };

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  const handleCheck = (index) => (event) => {
    const newChecked = [...checked];
    newChecked[index] = event.target.checked;
    setChecked(newChecked);
    onChange(newChecked.every((v) => v));
  };

  return (
    <Fade in={mounted} timeout={500}>
      <Stack
        spacing={2}
        sx={{ p: 1, bgcolor: "background.paper", borderRadius: 2 }}
      >
        <Typography variant="h6" color="text.primary">
          Account Agreement
        </Typography>

        {[
          "I have read and agree to the [Terms & Conditions]",
          "I acknowledge that my personal information will be collected, stored, and used as described in the [Privacy Policy]",
          "I have read and agree to the [Cancellation Policy], including refund conditions and fees.",
        ].map((text, index) => (
          <Collapse in={mounted} timeout={800} key={index}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked[index]}
                  onChange={handleCheck(index)}
                  required
                  sx={{
                    color: themeRed,
                    "&.Mui-checked": { color: themeRed },
                    transition: "transform 0.2s",
                    "&:hover": { transform: "scale(1.1)" },
                    padding: "0px 10px 0px 0px",
                  }}
                />
              }
              label={
                <Typography variant="body1" component="span">
                  {text.split(/\[(.*?)\]/).map((part, i) =>
                    i % 2 === 1 ? (
                      <Link
                        key={i}
                        href={policyPaths[part]}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                          color: themeRed,
                          textDecoration: "none",
                          "&:hover": {
                            textDecoration: "underline",
                            textDecorationColor: themeRed,
                          },
                          transition: "text-decoration 0.3s",
                        }}
                      >
                        {part}
                      </Link>
                    ) : (
                      part
                    )
                  )}
                </Typography>
              }
              sx={{
                alignItems: "flex-start",
                "& .MuiFormControlLabel-asterisk": { display: "none" },
                "&:hover": { bgcolor: "action.hover" },
              }}
            />
          </Collapse>
        ))}

        <Typography
          variant="caption"
          sx={{
            mt: 2,
            fontSize: "0.75rem",
            color: "text.secondary",
            lineHeight: 1.5,
            borderLeft: `3px solid ${themeRed}`,
            pl: 1.5,
          }}
        >
          By creating an account, you confirm that you have read, understood,
          and agree to be bound by Trailer4Rent’s Terms & Conditions,
          acknowledge Trailer4Rent’s Privacy Policy regarding the collection and
          use of personal data and agree to the Cancellation Policy without
          question or recourse. If you do not agree, do not use this service.
        </Typography>
      </Stack>
    </Fade>
  );
};

export default TermsConditionCheck;
